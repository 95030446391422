import React, { useState } from "react";
import { URL } from "../constants/url";
import useAuth from "../hooks/useAuth";
import { axios } from "../lib/axios";
import { errorHandler } from "../lib/serverHandler";

export const ChildContext = React.createContext({});

export const ChildSetup = ({ children }) => {
  const {auth} = useAuth();
  const [childDetails, setChildDetails] = useState([]);

  const getChild = async () => {
    try {
      const data = await axios.get(URL.childList, {
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      });
      setChildDetails(data.data);
    } catch (error) {
      console.log(error);
      error.response.data.message.length > 0 && errorHandler(error.response.data.message);
    }
    
  };

  return (
    <ChildContext.Provider value={{ childDetails, getChild }}>
      {children}
    </ChildContext.Provider>
  );
};
