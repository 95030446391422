import BackColored from "./back_arrow(colored).svg";
import Back from "./back_icon.svg";
import BadgeOne from "./badge1.svg";
import BadgeTwo from "./badge2.svg";
import BadgeThree from "./badge3.svg";
import Close from "./close_button.svg";
import CoinIcon from "./Coin.svg";
import RewardCoin from "./coin_icon.svg";
import Correct from "./Correct_icon.png";
import DeleteIcon from "./delete.svg";
import DropdownIcon from "./dropdown.svg";
import EditIcon from "./edit.svg";
import GirlStart from "./girl_icon.svg";
import Incorrect from "./Incorrect_icon.png";
import Info from "./info_icon.svg";
import Mascot from "./mascot.png";
import TryAgain from "./try_again_icon.svg";

export { Back, Info, Incorrect, Correct, Mascot, Close, TryAgain, GirlStart, RewardCoin, EditIcon, DeleteIcon, CoinIcon, BadgeOne, BadgeTwo, BadgeThree, DropdownIcon, BackColored };
