import React, { useState, useEffect } from "react";
import { Drawer, IconButton } from "@mui/material";
import { axios } from "../../lib/axios";
import "./drawercomp.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import "./drawercomp.css";
import useAuth from "../../hooks/useAuth";
import { URL } from "../../constants/url";
import ButtonLoading from "../button/ButtonLoading";
import { errorHandler } from "../../lib/serverHandler";
import MenuComponent from "../MenuComponent";
import { useContext } from "react";
import MenuContext from "../../context/MenuContext";

const DrawerComp = ({}) => {
  let { auth, setAuth } = useAuth();
  const [verifyLoading, setVerifyLoading] = useState(false);

  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = 220;
  const { loading } = useContext(MenuContext);

  const handleLogOut = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response = await axios
      .delete(`account/user/logout/`, config)
      .catch((er) => console.log(er));
    if (response.success === true) {
      setAuth({});
    }
    navigate("/");
  };

  const verifyLink = async () => {
    setVerifyLoading(true);
    try {
      await axios.post(URL.resendOtp, null, {
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      });
      navigate("/otp");
      setVerifyLoading(false);
    } catch (error) {
      console.log(error);
      setVerifyLoading(false);
      errorHandler(error?.response?.data?.message);
    }
  };

  return (
    loading === false && (
      <>
        <Drawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <div className="drawerWrapper">
            <div
              className="menuClose"
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <h3 style={{ marginRight: 20 }}>Menu</h3>
              <CloseIcon />
            </div>
            {Object.keys(auth).length !== 0 && (
              <p
                style={{
                  textAlign: "center",
                  marginBottom: 15,
                  paddingBottom: "1px solid #eee",
                  fontSize: 17,
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "10px 5%",
                  background: "#eee",
                  color: "#000",
                }}
              >
                Hello, {auth?.fullName}
                <br />
                {!auth?.isVerified ? (
                  <ButtonLoading
                    buttonStyles={{
                      marginTop: 10,
                      textTransform: "capitalize",
                      padding: "0 10px",
                    }}
                    varient="contained"
                    clickEvent={verifyLink}
                    initialText="Verify"
                    loadingTruth={verifyLoading}
                  />
                ) : (
                  // <VerifiedIcon fontSize="small" color="success" />
                  ""
                )}
              </p>
            )}
            <MenuComponent />
          </div>
        </Drawer>
        <IconButton
          sx={{ color: "white" }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon />
        </IconButton>
      </>
    )
  );
};

export default DrawerComp;
