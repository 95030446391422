import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Back } from "../../assets/commonicons/CommonIcons";
import ButtonLoading from "../../components/button/ButtonLoading";
import TextBox from "../../components/textBox/TextBox";
import { ERROR_MSG } from "../../constants/ErrorMessage";

const CreateChildForm = ({
  createFormActive,
  formData,
  nonFormData,
  error,
  changeDataHandler,
  setNonFormData,
  setFormData,
  validateForm,
  handleSubmit,
  childCreateLoading,
  returnToChildList,
}) => {
  const navigate = useNavigate();
  if (createFormActive === false) return false;
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="commonTopHeader fixed-flex-height">
        <img onClick={returnToChildList} src={Back} alt="back-icon" />
        <div></div>
      </div>
      <div className="screenWrapper">
        <div className="screenCenter">
          <div className="outerWrapper">
            <div className="commonCenter">
              <form className="commonForm">
                <div className="commonFormTop">
                  <h2 style={{ marginTop: 10, marginBottom: 5 }}>
                    Create Child
                  </h2>
                  <p className="commonFormTopText" style={{ marginBottom: 10 }}>
                    Please fill the form to add child
                  </p>
                  <p className="commonFormTopTextSmall">
                    * items marked with an asterisk are mandatory
                  </p>
                </div>
                <div style={{ marginTop: 20 }}>
                  <TextBox
                    icon="name"
                    placeholder="First Name*"
                    error={error?.firstName}
                    value={nonFormData?.firstName}
                    onChange={(e) => {
                      changeDataHandler(
                        "firstName",
                        e.target.value,
                        "First Name required"
                      );
                      setNonFormData({
                        ...nonFormData,
                        firstName: e.target.value,
                      });
                    }}
                  />
                  <TextBox
                    icon="name"
                    placeholder="Last Name*"
                    error={error?.lastName}
                    value={nonFormData?.lastName}
                    onChange={(e) => {
                      changeDataHandler(
                        "lastName",
                        e.target.value,
                        ERROR_MSG.emptyLastName
                      );
                      setNonFormData({
                        ...nonFormData,
                        lastName: e.target.value,
                      });
                    }}
                  />
                  <TextBox
                    icon="username"
                    placeholder="Username*"
                    error={error?.username}
                    value={formData?.username}
                    onChange={(e) => {
                      changeDataHandler(
                        "username",
                        e.target.value,
                        ERROR_MSG.emptyUser
                      );
                      setFormData({ ...formData, username: e.target.value });
                    }}
                  />
                  <TextBox
                    icon="lock"
                    placeholder="PIN (6 digit no)*"
                    protected
                    error={error?.pin}
                    value={formData?.pin}
                    onChange={(e) => {
                      changeDataHandler(
                        "pin",
                        e.target.value,
                        ERROR_MSG.emptyPin
                      );
                      setFormData({ ...formData, pin: e.target.value });
                    }}
                  />
                  <TextBox
                    icon="lock"
                    placeholder="Confirm PIN*"
                    protected
                    error={error?.confirm_pin}
                    value={formData?.confirm_pin}
                    onChange={(e) => {
                      changeDataHandler(
                        "confirm_pin",
                        e.target.value,
                        ERROR_MSG.emptyConfirmPin
                      );
                      setFormData({
                        ...formData,
                        confirm_pin: e.target.value,
                      });
                    }}
                  />
                </div>

                <div style={{ marginTop: 40 }}>
                  <ButtonLoading
                    buttonStyles={{
                      textTransform: "capitalize",
                    }}
                    varient="contained"
                    clickEvent={() => {
                      if (validateForm()) handleSubmit();
                    }}
                    initialText="Create"
                    loadingTruth={childCreateLoading}
                  />
                  <Button
                    style={{
                      borderColor: "#ccc",
                      marginLeft: 20,
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                    onClick={returnToChildList}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChildForm;
