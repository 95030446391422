import IframeComm from "react-iframe-comm"; /* this library is for sending data into iframe and receive data from iframe*/
import "./epiframe.css";
function Epiframe({ link, onReceiveMessage }) {
  const attributes = {
    src: link,
    width: "100%",
    height: "100%",
    frameBorder: 0,
    id: "fram",
  };
  const postMessageData = "50";
  return (
    <div className="ifram_show">
      <div className="epContainer">
        <div className="iframediv">
          <IframeComm
            className="iframe"
            attributes={attributes}
            postMessageData={postMessageData}
            handleReceiveMessage={onReceiveMessage}
          ></IframeComm>
        </div>
      </div>
    </div>
  );
}
export default Epiframe;
