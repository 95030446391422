import { Button, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Back } from "../../assets/commonicons/CommonIcons";
import TextBox from "../../components/textBox/TextBox";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";

// css
import { Logo } from "../../assets/images";
import ButtonLoading from "../../components/button/ButtonLoading";
import { Modal } from "../../components/modal/Modal";
import PasswordModal from "../../components/modal/PasswordModal";
import { ERROR_MSG } from "../../constants/ErrorMessage";
import { URL } from "../../constants/url";
import {
  addInfoHandler,
  emailValidator,
  passwordValidator,
  removeInfoHandler,
  userValidator,
} from "../../lib/formValidation";
import { errorHandler } from "../../lib/serverHandler";
import "./signuppage.css";

const SignupPage = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [isUserInfo, setUserInfo] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [isPasswordInfo, setPasswordInfo] = useState(false);
  const [validTruth, setValidTruth] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  useEffect(() => {
    document.title = "Sign Up - Seepalaya";
  }, []);

  const [nonFormData, setNonFormData] = useState({
    firstName: "",
    lastName: "",
    termsAccepted: false,
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    confirm_password: "",
    termsAccepted: "",
  });

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    username: "",
    password: "",
    confirm_password: "",
  });

  const handleSubmit = async (e) => {
    if (validTruth === false) return false;
    setRegisterLoading(true);
    try {
      const response = await axios.post(URL.guardianSignup, {
        ...formData,
        full_name: `${nonFormData.firstName} ${nonFormData.lastName}`,
      });

      setRegisterLoading(false);

      navigate("/otp");
      setAuth({
        username: response.data?.username,
        email: response.data?.user_email,
        fullName: response.data?.full_name,
        isVerified: response.data?.is_verified,
        userType: response.data?.user_type,
        accessToken: response.data?.access_token,
      });
    } catch (err) {
      errorHandler(err.response.data.message);
      setRegisterLoading(false);
    }
  };
  const changeDataHandler = (fieldName, value, errorText) => {
    if (value.length === 0) {
      switch (fieldName) {
        case "firstName":
          setError({ ...error, firstName: errorText });
          break;
        case "lastName":
          setError({ ...error, lastName: errorText });
          break;
        case "termsAccepted":
          setError({ ...error, termsAccepted: errorText });
          break;
        case "confirm_password":
          setError({ ...error, confirm_password: errorText });
          break;
        default:
          break;
      }
    } else {
      switch (fieldName) {
        case "firstName":
          setError({ ...error, firstName: "" });
          break;
        case "lastName":
          setError({ ...error, lastName: "" });
          break;
        case "termsAccepted":
          setError({ ...error, termsAccepted: "" });
          break;
        case "confirm_password":
          setError({ ...error, confirm_password: "" });
          break;
        default:
          break;
      }
    }
  };
  const validateForm = () => {
    let valid = true;
    let form = {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirm_password: "",
      termsAccepted: "",
    };
    if (nonFormData.firstName === "") {
      valid = false;
      form.firstName = ERROR_MSG.emptyFirstName;
    }
    if (nonFormData.lastName === "") {
      valid = false;
      form.lastName = ERROR_MSG.emptyLastName;
    }
    if (formData.email.trim() === "") {
      valid = false;
      form.email = ERROR_MSG.emptyEmail;
    }
    if (formData.username.trim() === "") {
      valid = false;
      form.username = ERROR_MSG.emptyUser;
    }
    if (formData.password.trim() === "") {
      valid = false;
      form.password = ERROR_MSG.emptyPassword;
    }
    if (formData.confirm_password === "") {
      valid = false;
      form.confirm_password = ERROR_MSG.emptyConfirmPassword;
    } else {
      if (formData.password.trim() !== formData.confirm_password) {
        valid = false;
        form.confirm_password = ERROR_MSG.passwordMatch;
      }
    }
    if (nonFormData.termsAccepted === false) {
      valid = false;
      form.termsAccepted = ERROR_MSG.termsAccepted;
    }
    validTruth === true && setError(form);
    return valid;
  };

  const passwordValidation = (value) => {
    passwordValidator(value, setError, error);
  };

  const userValidation = (value) => {
    userValidator(value, setError, error);
  };

  const emailValidation = (value) => {
    emailValidator(value, setError, error);
  };

  useEffect(() => {
    let modal = document.querySelector(".overlay");
    window.onclick = function (event) {
      if (event.target === modal) {
        setUserInfo(false);
        setPasswordInfo(false);
        setIsTerms(false);
        document.body.classList.remove("modal-open");
      }
    };
    console.log(error);
    setValidTruth(Object.values(error).every((e) => e.length === 0));
  }, [isUserInfo, isPasswordInfo, error, validTruth, isTerms]);

  const infoUserHandler = () => addInfoHandler(setUserInfo);
  const infoPasswordHandler = () => addInfoHandler(setPasswordInfo);
  const infoterms = () => addInfoHandler(setIsTerms);
  const onPasswordInfoClose = () => removeInfoHandler(setPasswordInfo);
  const onUserInfoClose = () => removeInfoHandler(setUserInfo);
  const onTermfoClose = () => removeInfoHandler(setIsTerms);

  return (
    <>
      <div className="signUpContainer">
        <div className="signUpHeader">
          <img
            onClick={() => navigate(-1)}
            src={Back}
            alt="back-icon"
            className="pointer"
          />
          <div className="signUpHeading" style={{ margin: "-10px 0" }}>
            {" "}
            <h1 onClick={() => navigate("/")} className="pointer">
              <img src={Logo} alt="Sipalya" />
            </h1>
          </div>
          <div></div>
        </div>
        <div className="signUpCenter">
          <form className="signUpForm">
            <div className="signUpFormTop">
              <h2 style={{ marginTop: 10 }}>Let's Do This!</h2>
              <p className="signUpTopText">Sign up to create your account.</p>
              <p className="signUpTopTextSmall">
                * items marked with an asterisk are mandatory
              </p>
            </div>
            <div style={{ marginTop: 20 }}>
              <TextBox
                icon="name"
                placeholder="First Name*"
                error={error.firstName}
                value={nonFormData.firstName}
                onChange={(e) => {
                  changeDataHandler(
                    "firstName",
                    e.target.value,
                    ERROR_MSG.emptyFirstName
                  );
                  setNonFormData({ ...nonFormData, firstName: e.target.value });
                }}
              />
              <TextBox
                icon="name"
                placeholder="Last Name*"
                error={error.lastName}
                value={nonFormData.lastName}
                onChange={(e) => {
                  changeDataHandler(
                    "lastName",
                    e.target.value,
                    ERROR_MSG.emptyLastName
                  );
                  setNonFormData({ ...nonFormData, lastName: e.target.value });
                }}
              />
              <TextBox
                icon="mail"
                placeholder="Email*"
                error={error.email}
                value={formData.email}
                onChange={(e) => {
                  emailValidation(e.target.value);
                  setFormData({ ...formData, email: e.target.value });
                }}
              />
              <TextBox
                icon="username"
                infoIcon={true}
                infoHandler={infoUserHandler}
                placeholder="Username*"
                error={error.username}
                value={formData.username}
                onChange={(e) => {
                  userValidation(e.target.value);
                  setFormData({ ...formData, username: e.target.value });
                }}
              />
              <TextBox
                icon="lock"
                placeholder="Password*"
                infoIcon={true}
                infoHandler={infoPasswordHandler}
                protected
                error={error.password}
                value={formData.password}
                onChange={(e) => {
                  passwordValidation(e.target.value);
                  setFormData({ ...formData, password: e.target.value });
                }}
              />
              <TextBox
                icon="lock"
                placeholder="Confirm Password*"
                protected
                error={error.confirm_password}
                value={formData.confirm_password}
                onChange={(e) => {
                  changeDataHandler(
                    "confirm_password",
                    e.target.value,
                    ERROR_MSG.emptyConfirmPassword
                  );
                  setFormData({
                    ...formData,
                    confirm_password: e.target.value,
                  });
                }}
              />
            </div>

            <div className="confirmationDiv">
              <div>
                <Checkbox
                  checked={nonFormData.termsAccepted}
                  onChange={(e) => {
                    changeDataHandler(
                      "termsAccepted",
                      e.target.value,
                      ERROR_MSG.termsAccepted
                    );
                    setNonFormData({
                      ...nonFormData,
                      termsAccepted: !nonFormData.termsAccepted,
                    });
                  }}
                />
              </div>
              <div style={{ fontSize: 12 }}>
                By creating an account, I accept the{" "}
                <span
                  className="pointer"
                  style={{ fontWeight: "bold" }}
                  onClick={infoterms}
                >
                  Terms of Use.
                </span>
              </div>
            </div>
            {error.termsAccepted.length !== 0 && (
              <div
                style={{
                  fontSize: 12,
                  color: "red",
                  marginTop: 5,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                {error.termsAccepted}
              </div>
            )}
            <div>
              <ButtonLoading
                varient="contained"
                clickEvent={() => {
                  if (validateForm()) handleSubmit();
                }}
                initialText="Sign Up"
                loadingTruth={registerLoading}
              />
            </div>

            <div style={{ marginTop: 20, textAlign: "center" }}>
              Already have an account?{" "}
              <span
                className="pointer"
                style={{ color: "blue" }}
                onClick={() => navigate("/login")}
              >
                Login
              </span>
            </div>
          </form>
        </div>
      </div>

      <Modal isVisible={isUserInfo} className="custom-modal">
        <div className="modal_Holder">
          <strong>Username Criteria</strong>
          <ul className="list-dashed">
            <li>Username should be of length between 3 to 20 characters</li>
            <li>
              Username should have alpha-numeric values (letters and numbers)
            </li>
          </ul>
          <div className="button_Holder">
            <button className="modal_Close" onClick={onUserInfoClose}>
              Ok
            </button>
          </div>
        </div>
      </Modal>

      <Modal isVisible={isTerms} className="custom-modal">
        <div className="modal_Holder">
          <strong>Terms of Use</strong>
          <p>
            Seepalaya is designed and developed by OLE Nepal and distributed
            freely. Redistributing or reposting them for monetization or without
            credits to OLE Nepal is strictly prohibited.
          </p>
          <div className="button_Holder">
            <button
              className="modal_Close"
              style={{
                margin: "20px 0",
              }}
              onClick={onTermfoClose}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>

      <PasswordModal
        isPasswordInfo={isPasswordInfo}
        onPasswordInfoClose={onPasswordInfoClose}
      />
    </>
  );
};

export default SignupPage;
