import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { RewardCoin } from '../../../assets/commonicons/CommonIcons';
import AudioPlay from '../../../components/AudioPlay';
import Confetti from '../../../components/Confettie';
import useAuth from '../../../hooks/useAuth';
import { axios } from '../../../lib/axios';
import Feedback from './Feedback';
import './lastpage.css';

Modal.setAppElement('#root');
const LastPage = ({ score, tryAgain, answers, goBack, questionList }) => {
    const [showFeedback, setShowFeedback] = useState(false);
    const { auth } = useAuth();
    let { chaptername } = useParams();
    const { correct, total } = score;
    const completeCorrect = correct === total;

    const toggleFeedback = () => {
        setShowFeedback(!showFeedback);
    };

    return showFeedback == false ? (
        <div className='lastcontainer'>
            {completeCorrect && <Confetti />}
            <AudioPlay />
            <div className='left'>
                {/* <div className="closecross">X</div> */}
                <div className='points'>
                    <div className='pointDiv'>
                        <img src={RewardCoin} alt='' />
                    </div>
                    <div className='pointDiv'>{score.correct * 20} points</div>
                    {/* <div className="pointDivider" />
          <div className="pointDiv">i</div> */}
                </div>
            </div>
            <div className='center'>
                <div className='centerTop'>
                    <div className='scoreCircle'>
                        <div>Your Score</div>
                        <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: 10 }}>{score.correct * 20}</div>
                    </div>
                </div>
                <div className='centerBottom'>
                    <div className='centerBottomTop'>
                        <div>
                            <p style={{ textAlign: 'center' }}>Good Job! You have completed the Pre-Assessment.</p>
                        </div>
                        {/* <div> green bar thingy</div> */}
                    </div>
                    <div className='centerBottomBottom'>
                        <div className='centerBottomDiv'>
                            <div className='number total'>{score.total}</div>
                            <div>Total</div>
                        </div>
                        <div className='centerBottomDiv'>
                            <div className='number correct'>{score.correct}</div>
                            <div>Correct</div>
                        </div>
                        <div className='centerBottomDiv'>
                            <div className='number wrong'>{score.incorrect}</div>
                            <div>Wrong</div>
                        </div>
                        <div className='centerBottomDiv'>
                            <div className='number skipped'>{score.skipped}</div>
                            <div>Skipped</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' right'>
                <div onClick={tryAgain} className='lastButton red'>
                    Try Again
                </div>
                <div onClick={goBack} className='lastButton green'>
                    Continue
                </div>
                <div onClick={toggleFeedback} className='lastButton blue'>
                    View Feedback
                </div>
            </div>
        </div>
    ) : (
        <Feedback goBack={toggleFeedback} answers={answers} score={score} questionList={questionList} />
    );
};

export default LastPage;
