import React, { useState, useEffect } from "react";
import "./feedback.css";
import Modal from "react-modal";
import FeedbackQ from "../../LessonreviewQuiz/Fractions/Feedback";

export default function Feedback({ goBack, answers, score, questionList }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const getOptionIndex = (index) => {
    switch (index) {
      case 0:
        return "A";
      case 1:
        return "B";
      case 2:
        return "C";
      case 3:
        return "D";
    }
  };
  const renderFeedback = () => {
    return (
      <FeedbackQ
        selectedAnswer={answers[selectedQuestion]?.answer}
        toggleFeedback={() => setModalVisible(!modalVisible)}
        data={questionList[selectedQuestion]}
      />
    );
  };
  let modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    content: {
      margin: "auto",
      borderRadius: 15,
      height: "90%",
      width: "90%",
      justifyContent: "center",
      alignSelf: "center",
      overflow: "none",
      zIndex: 1000,
    },
  };
  const renderReport = () => {
    return (
      <div>
        {answers.map((item, index) => {
          if (item.answer != null) {
            if (item.answer == item.correct_answer) {
              return (
                <div className="iFeedbackContainer">
                  <div className="feedbackQuestion">
                    <p>
                      Question: {item.question + 1}: {item.questionText}
                    </p>
                    <p style={{ marginTop: 10 }}>{item.subQuestion}</p>

                    <p style={{ marginTop: 10, marginBottom: 10 }}>
                      {item.equation}
                    </p>
                  </div>
                  <div className="mainFeedbackContainer feedbackContainerCorrect">
                    <div className="mainFeedbackTop">
                      <div className="answerOption answerOptionCorrect">
                        <div className="answerOptionIndex answerOptionIndexCorrect">
                          {getOptionIndex(item.answerIndex)}
                        </div>
                        <div style={{ width: "85%" }}>{item.answer}</div>
                        <div></div>
                      </div>
                      <div className="feedbackTopRight">
                        <div className="correctInd">Correct</div>
                        <div
                          className="knowWhyDiv pointer"
                          onClick={() => {
                            setSelectedQuestion(index);
                            setModalVisible(!modalVisible);
                          }}
                        >
                          Know Why?
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                </div>
              );
            } else if (item.answer != item.correct_answer) {
              return (
                <div className="iFeedbackContainer">
                  <div className="feedbackQuestion">
                    <p>
                      Question: {item.question + 1}: {item.questionText}
                    </p>
                    <p style={{ marginTop: 10 }}>{item.subQuestion}</p>

                    <p style={{ marginTop: 10, marginBottom: 10 }}>
                      {item.equation}
                    </p>
                  </div>
                  <div className="mainFeedbackContainer feedbackContainerIncorrect">
                    <div className="mainFeedbackTop">
                      <div className="answerOption answerOptionIncorrect">
                        <div className="answerOptionIndex answerOptionIndexIncorrect">
                          {getOptionIndex(item.answerIndex)}
                        </div>
                        <div style={{ width: "85%" }}>{item.answer}</div>
                        <div></div>
                      </div>
                      <div className="feedbackTopRight">
                        <div className="inCorrectInd">Incorrect</div>
                        <div
                          className="knowWhyDiv pointer"
                          onClick={() => {
                            setSelectedQuestion(index);
                            setModalVisible(!modalVisible);
                          }}
                        >
                          Know Why?
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                </div>
              );
            }
          } else {
            return (
              <div className="iFeedbackContainer">
                <div className="feedbackQuestion">
                  <p>
                    Question: {item.question + 1}: {item.questionText}
                  </p>
                  <p style={{ marginTop: 10 }}>{item.subQuestion}</p>
                  <p style={{ marginTop: 10, marginBottom: 10 }}>
                    {item.equation}
                  </p>
                </div>
                <div className="mainFeedbackContainer feedbackContainerSkipped">
                  <div className="mainFeedbackTop">
                    You have skipped this question.
                  </div>
                </div>
                <br />
                <hr />
                <br />
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="feedbackContainer">
      <div className="fixed">
        <div className="feedbackHead">
          <div className="feedbackHeadLeft">
            <h3>Pre-Assessment Results</h3>
            <div style={{ marginTop: 5, marginBottom: 10 }}>
              Score: {score.correct} of {score.total}
            </div>
            <div>
              {((score.correct / score.total) * 100).toFixed()}% Correct
            </div>
          </div>
          <div className="feedbackHeadRight">
            <div onClick={goBack} className="feedbackGoBack pointer">
              Go Back
            </div>
          </div>
        </div>
        <br />
        <p>
          Go through the feedback provided below to view your results. Click on
          "Know Why?" to review as to why your answers are correct or incorrect.
        </p>
        <br />
        <hr />
      </div>
      <div className="feedbackScroll">{renderReport()}</div>
      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(!modalVisible)}
        contentLabel="My dialog"
        style={modalStyles}
      >
        {renderFeedback()}
      </Modal>
    </div>
  );
}
