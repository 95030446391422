import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Back } from "../../../assets/commonicons/CommonIcons";
import { useParams } from "react-router-dom";
import { URL } from "../../../constants/url";
import { axios } from "../../../lib/axios";
import useAuth from "../../../hooks/useAuth";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import "./detailedscore.css";

export default function DetailedScore() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { chaptername } = useParams();
  const [detailedLog, setDetailedLog] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Child Account - Child Assessment Scores";
  }, []);

  useEffect(() => {
    getDetailedLog();
  }, []);

  const getDetailedLog = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response = await axios
      .get(`${URL.getDetailedLogs}?course_name=${chaptername}`, config)
      .catch((er) => console.log(er));
    if (response.success === true) {
      response.data.map((item) => {
        item.created_date = moment(item.created_date);
      });
      console.log(response.data);
      setDetailedLog(response.data);
      setLoading(false);
    }
  };

  return loading === true ? (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress size={30} />
      <h3 style={{ marginTop: 20 }}>Loading Profile...</h3>
    </div>
  ) : (
    <div>
      <div className="loginHeader">
        <img onClick={() => navigate(-1)} src={Back} alt="back-icon" />
        <div className="loginHeading"></div>
        <div></div>
      </div>
      <div className="coursesWrapper">
        <div className="screenCenter">
          <div>
            <h1>{chaptername}</h1>
          </div>
          <div>
            <table>
              <thead>
                <tr
                  style={{ borderBottomWidth: 1, borderBottomColor: "black" }}
                >
                  <th>S.N</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {detailedLog.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.created_date.format("DD/MM")}</td>
                    <td>{item.created_date.format("h:mm")}</td>
                    <td>{item.user_score === null ? 0 : item.user_score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
