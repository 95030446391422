import React, { useEffect } from "react";
import {
  BannerSlogan,
  GirlImage,
  GirlImageNew,
  HomeBanner,
  MobileHomeBanner,
} from "../../assets/images";
import CourseSlider from "../../components/courseSlider/CourseSlider";
import MyCoursesSlider from "../../components/courseSlider/MyCoursesSlider";
import ExploreSlider from "../../components/exploreSlider/ExploreSlider";
import FooterNew from "../../components/footer/FooterNew";
import Header from "../../components/topbar/header/Header";
import useAuth from "../../hooks/useAuth";
import ReactGA from "react-ga"


import { useMediaQuery, useTheme } from "@mui/material";
import "./homepage.css";


const tracking_id = "G-YCDWNJMNJX";
ReactGA.initialize(tracking_id);
console.log(tracking_id)

export default function HomePage() {
  useEffect(() => {
    document.title = "Seepalaya - Learn Something new everyday!";
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname)
  }, []);

  const { auth } = useAuth();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div>
          <Header active="Home" />
        </div>
        <div
          style={{
            backgroundColor: "white",
            backgroundImage: isMatch
              ? `url(${MobileHomeBanner})`
              : `url(${HomeBanner})`,
          }}
          className="screenWrapper"
        >
          <div className="screenCenter">
            <div className="bannerContent">
              <img src={BannerSlogan} width="100%" alt="" />
              <figure className="bannerImage">
                <img src={GirlImageNew} alt="" />
              </figure>
            </div>
            <div style={{ marginTop: "18vh", paddingBottom: 50 }}>
              {auth.accessToken && auth.userType === "child" && (
                <MyCoursesSlider />
              )}
              <CourseSlider />
            </div>
          </div>
        </div>
        <div>{auth.accessToken ? <></> : <ExploreSlider />}</div>
      </div>
      <div>
        <FooterNew />
      </div>
    </div>
  );
}
