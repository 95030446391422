import React, { useContext, useEffect, useState, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";

//css
import "./child.css";
import Header from "../../components/topbar/header/Header";
import FooterNew from "../../components/footer/FooterNew";
import { ERROR_MSG } from "../../constants/ErrorMessage";
import { errorHandler } from "../../lib/serverHandler";
import { axios } from "../../lib/axios";
import { URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";
import ChildList from "./ChildList";
import { ChildContext } from "../../context/ChildContext";
import CreateChildForm from "./CreateChildForm";

const CreateChild = () => {
  // const [validTruth, setValidTruth] = useState(false);
  const validTruth = useRef(false);
  const [childOne, setChildOne] = useState(false);
  const [childTwo, setChildTwo] = useState(false);
  const [createFormActive, setCreateFormActive] = useState(false);
  const [listShow, setListShow] = useState(false);
  const { auth, setAuth } = useAuth();
  const { childDetails, getChild } = useContext(ChildContext);
  const [childCreateLoading, setChildCreateLoading] = useState(false);

  useEffect(() => {
    document.title = "Create Child Accounts - Seepalaya";
  }, []);

  const [nonFormData, setNonFormData] = useState({
    firstName: "",
    lastName: "",
    guardian_email: "",
  });
  const loadFieldOptions = {
    firstName: "",
    lastName: "",
    username: "",
    pin: "",
    confirm_pin: "",
  };
  const loadDataOptions = {
    guardian_email: "",
    full_name: "",
    username: "",
    pin: "",
    confirm_pin: "",
  };
  const [error, setError] = useState(loadFieldOptions);

  const [formData, setFormData] = useState(loadDataOptions);

  const validateForm = () => {
    let valid = true;
    let form = {
      loadFieldOptions,
    };
    if (nonFormData.firstName === "") {
      valid = false;
      form.firstName = ERROR_MSG.emptyFirstName;
    }
    if (nonFormData.lastName === "") {
      valid = false;
      form.lastName = ERROR_MSG.emptyLastName;
    }
    if (formData.username.trim() === "") {
      valid = false;
      form.username = ERROR_MSG.emptyUser;
    }
    if (formData.pin === "") {
      valid = false;
      form.pin = ERROR_MSG.emptyPin;
    }
    if (formData.confirm_pin === "") {
      valid = false;
      form.confirm_pin = ERROR_MSG.emptyConfirmPin;
    } else {
      if (formData.pin != formData.confirm_pin) {
        valid = false;
        form.confirm_pin = ERROR_MSG.pinMatch;
      } else {
        valid = true;
        form.confirm_pin = "";
      }
    }
    valid === false && setError(form);
    validTruth.current = valid;
    return valid;
  };

  useEffect(() => {
    getChild();
  }, [listShow]);

  const deleteChild = async (index) => {
    let accessToken = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${accessToken}` } };
    let data = { child_username: childDetails[index].username };
    let response = await axios.post(URL.deleteChild, data, config);
    console.log(response);
    if (response.success === true) {
      getChild();
      window.location.reload();
    }
  };

  useEffect(() => {
    const childDetailsLen = childDetails.length;
    console.log(childDetailsLen, childDetails, listShow, childOne, childTwo);
    switch (true) {
      case childDetailsLen > 2:
        console.log("rajesh");
        setListShow(true);
        setChildOne(true);
        setChildTwo(true);
        break;
      case childDetailsLen > 1:
        console.log("rajesh2");
        setListShow(true);
        setChildOne(true);
        setChildTwo(true);
        break;
      case childDetailsLen > 0:
        console.log("rajesh3");
        setListShow(true);
        setChildOne(true);
        setChildTwo(false);
        break;
      default:
        break;
    }
  }, [childDetails, listShow, childOne, childTwo]);

  // useEffect(() => {
  //   setValidTruth(Object.values(error).every((e) => e.length === 0));
  // }, [error, validTruth]);

  const handleSubmit = async (e) => {
    if (validTruth === false) return false;
    setChildCreateLoading(true);
    try {
      const response = await axios.post(
        URL.childSignup,
        {
          ...formData,
          full_name: `${nonFormData.firstName} ${nonFormData.lastName}`,
          guardian_email: auth?.email,
        },
        {
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      );
      setCreateFormActive(false);
      setListShow(true);
      setChildCreateLoading(false);
      window.location.reload();
    } catch (err) {
      errorHandler(err?.response?.data?.message);
      setChildCreateLoading(false);
      setError(loadFieldOptions);
    }
  };

  const changeDataHandler = (fieldName, value, errorText) => {
    if (value.length === 0) {
      switch (fieldName) {
        case "firstName":
          setError({ ...error, firstName: errorText });
          break;
        case "lastName":
          setError({ ...error, lastName: errorText });
          break;
        case "username":
          setError({ ...error, username: errorText });
          break;
        case "pin":
          setError({ ...error, pin: errorText });
          break;
        case "confirm_pin":
          setError({ ...error, confirm_pin: errorText });
          break;
        default:
          break;
      }
    } else if (value.length > 6) {
      switch (fieldName) {
        case "pin":
          setError({ ...error, pin: "Length can't exceed 6" });
          break;
        default:
          break;
      }
    } else {
      switch (fieldName) {
        case "firstName":
          setError({ ...error, firstName: "" });
          break;
        case "lastName":
          setError({ ...error, lastName: "" });
          break;
        case "username":
          setError({ ...error, username: "" });
          break;
        case "pin":
          setError({ ...error, pin: "" });
          break;
        case "confirm_pin":
          setError({ ...error, confirm_pin: "" });
          break;
        default:
          break;
      }
    }
  };

  const childOneHandler = () => {
    setFormData(loadDataOptions);
    setError(loadFieldOptions);
    setCreateFormActive(true);
    setChildOne(true);
    setListShow(false);
  };
  const childTwoHandler = () => {
    setFormData(loadDataOptions);
    setError(loadFieldOptions);
    setCreateFormActive(true);
    setChildTwo(true);
    setListShow(false);
  };
  const returnToChildList = () => {
    setCreateFormActive(false);
    setListShow(true);
    window.location.reload();
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        headerStyles={
          createFormActive ? { display: "none" } : { display: "flex" }
        }
      />
      <div
        className="screenWrapper"
        style={createFormActive ? { padding: 0 } : { padding: "20px" }}
      >
        <div className="screenCenter">
          {createFormActive === false && (
            <div style={{ padding: 20 }}>
              <h2
                style={{
                  marginBottom: "40px",
                }}
              >
                My Child
              </h2>
              <ChildList
                deleteChild={deleteChild}
                listShow={listShow}
                childDetails={childDetails}
                error={error}
                formData={formData}
                nonFormData={nonFormData}
                setNonFormData={setNonFormData}
                setFormData={setFormData}
                changeDataHandler={changeDataHandler}
                validateForm={validateForm}
              />
              <div className="create_block__wrapper">
                <div
                  className="create_block pointer"
                  onClick={childOneHandler}
                  style={childOne ? { display: "none" } : { display: "flex" }}
                >
                  <AddIcon fontSize="small" style={{ marginRight: 5 }} />
                  Create Child 1
                </div>
                <div
                  className="create_block pointer"
                  onClick={childTwoHandler}
                  style={childTwo ? { display: "none" } : { display: "flex" }}
                >
                  <AddIcon fontSize="small" style={{ marginRight: 5 }} />
                  Create Child 2
                </div>
              </div>
            </div>
          )}

          <CreateChildForm
            createFormActive={createFormActive}
            formData={formData}
            nonFormData={nonFormData}
            error={error}
            changeDataHandler={changeDataHandler}
            setNonFormData={setNonFormData}
            setFormData={setFormData}
            validateForm={validateForm}
            handleSubmit={handleSubmit}
            returnToChildList={returnToChildList}
            childCreateLoading={childCreateLoading}
          />
        </div>
      </div>
      <FooterNew createFormActive={createFormActive} />
    </div>
  );
};

export default CreateChild;
