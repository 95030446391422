import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import LastPage from "./LastPage";
import "./reviewQuiz.css";
// import FirstPage from "Components/CommonScreens/FirstPage";
import { useNavigate, useParams } from "react-router-dom";
import { Close } from "../../../assets/commonicons/CommonIcons";
import FirstPage from "../../../components/commonScreens/FirstPage";
import { dataConverter } from "../../../components/helpers/dataConverter";
import useAuth from "../../../hooks/useAuth";
import { axios } from "../../../lib/axios";
import { URL } from "../../../constants/url";
import ButtonLoading from "../../../components/button/ButtonLoading";

Modal.setAppElement("#root");

export default function ReviewQuiz() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [quizStarted, setquizStarted] = useState(false);
  const [startTime, setStartTime] = useState();
  const [quizEnded, setQuizEnded] = useState(false);
  const [score, setScore] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  let { chaptername } = useParams();
  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    let response = await axios
      .get(`assessment?course_name=${chaptername}&assessment_type=post`)
      .catch((er) => console.log(er));

    createAlgebraQuestions(response.data);
  };

  const confirmationModal = () => {
    return (
      <div className="modalInnerStyle">
        <div onClick={toggleModal} className="modalCross">
          x
        </div>
        <div className="modalText">
          <div className="modalText1">
            {countNotAnswered() == 0
              ? "You have completed the quiz."
              : countNotAnswered() == 1
              ? " You have skipped a question"
              : `You have skipped ${countNotAnswered()} questions.`}
          </div>
          <div className="modalText2">
            {countNotAnswered() == 0
              ? "Do you want to go back and review?"
              : "Do you want to submit without solving?"}
          </div>
        </div>
        <div className="modalButtons">
          <div onClick={toggleModal} className="modalButton1">
            Go Back
          </div>
          <ButtonLoading
            submit
            varient="contained"
            clickEvent={handleSubmit}
            initialText="Submit"
            loadingTruth={submitLoading}
          />
        </div>
      </div>
    );
  };

  const tryAgain = () => {
    setScore({});
    setCurrentIndex(0);
    setQuizEnded(false);
    setquizStarted(true);
    fetchQuestions();
    setModalVisible(false);
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  const goBack = () => {
    navigate(-1);
  };
  const randomizeAlphabet = () => {
    let alphabet = "abcdefghijklmnopqrstuvwxyz";
    return alphabet[Math.floor(Math.random() * alphabet.length)];
  };
  const algebraCQuestion = (d, v) => {
    let alphabet = v;
    let a = `With ${alphabet} = ${d.answer.correct_answer}`;
    let b = `  ${
      d.equation.correct_option.replace("?", alphabet).split("=")[0]
    } = ${d.equation.correct_equation}`;
    let c = ` So, ${d.answer.correct_answer} is the correct answer`;
    let arr = [];
    arr.push(a, b, c);
    console.log(arr);
    return "";
  };
  const createAlgebraQuestions = (data) => {
    let newWordProblems = [];
    data.map((item) => {
      let variable = randomizeAlphabet();
      let cArray = algebraCQuestion(item, variable);
      let a = {
        question_type: "WP",
        level: item.level,
        question: item.question,
        sub_question: "a) Click on the right equation for the given problem.",
        equation: item.simplified_question,
        options: shuffleArray(item.equation.options),
        correct_answer: item.equation.correct_option,
        variable: variable,
      };
      let b = {
        question_type: "WP",
        level: item.level,
        question: item.question,
        equation: item.equation.correct_option.replace("?", variable),
        sub_question: `b) Click on the right value of ${variable}.`,
        options: shuffleArray(item.answer.options),
        correct_answer: item.answer.correct_answer,
        variable: variable,
      };
      let c = {
        question_type: "WP",
        level: item.level,
        question: item.question,
        sub_question: "c) Click on the correct word problem.",
        equation: item.equation.correct_option.replace("?", variable),
        options: shuffleArray(item.word_answer.options),
        correct_answer: item.word_answer.correct_answer,
        variable: variable,
      };
      newWordProblems.push(a, b, c);
    });
    setQuestionList(newWordProblems);
    loadAnswerTemplate(newWordProblems);
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const getOptionIndex = (index) => {
    switch (index) {
      case 0:
        return "A";
      case 1:
        return "B";
      case 2:
        return "C";
      case 3:
        return "D";
    }
  };
  const loadAnswerTemplate = (questionList) => {
    let temp = [];
    questionList.map((item, index) => {
      temp = [
        ...temp,
        {
          question_type: item.question_type,
          question: index,
          questionText: item.question,
          subQuestion: item.sub_question,
          equation: item.equation,
          answer: null,
          level: item.level,
          attempts: 0,
          correct_answer: item.correct_answer,
          correct_answer_index: item.options.indexOf(item.correct_answer),
          answer_index: null,
          operator: item.operator,
          lesson: item.lesson,
        },
      ];
    });
    setAnswers([...temp]);
    setLoading(false);
  };

  const selectOption = (optionIndex, index, data) => {
    let temp = [...answers];
    temp[index] = {
      ...answers[index],
      question: index,
      answer: data,
      answerIndex: optionIndex,
      attempts:
        data != temp[index].answer
          ? temp[index].attempts + 1
          : temp[index].attempts,
    };
    setAnswers([...temp]);
  };

  const countNotAnswered = () => {
    let notAnsweredCount = 0;
    answers.map((item) => {
      if (item.answer == null) {
        notAnsweredCount++;
      }
    });
    return notAnsweredCount;
  };
  const handleIndex = (action) => {
    if (currentIndex == 0) {
      if (action === "prev") {
        return;
      }
    }
    if (currentIndex == questionList.length - 1) {
      if (action === "next") {
        return;
      }
    }
    if (action === "next") {
      setCurrentIndex(currentIndex + 1);
    }
    if (action === "prev") {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const msToTime = (duration) => {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = parseInt((duration / 1000) % 60),
      minutes = parseInt((duration / (1000 * 60)) % 60),
      hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };
  const handleSubmit = async () => {
    let correct = 0;
    let total = questionList.length;
    let skipped = countNotAnswered();
    let incorrect = 0;
    let endTime = new Date();
    let duration = msToTime(endTime - startTime);

    answers.map((item) => {
      if (item.answer != null) {
        item.skipped = 0;
        if (item.answer == item.correct_answer) {
          correct++;
          item.correct = 1;
          item.incorrect = 0;
        } else {
          incorrect++;
          item.correct = 0;
          item.incorrect = 1;
        }
      } else {
        item.skipped = 1;
        item.correct = 0;
        item.incorrect = 0;
      }
    });

    setScore({
      total: total,
      correct: correct,
      incorrect: incorrect,
      skipped: skipped,
    });

    let answersData = [];
    answers.map((item) => {
      let a = {
        attempts: item.attempts,
        correct: item.correct,
        incorrect: item.incorrect,
        skipped: item.skipped,
        level: item.level,
        question_type: item.question_type,
        lesson: item.lesson,
      };
      answersData.push(a);
    });

    let data = {
      course_name: chaptername,
      data: answersData,
      duration: duration,
      start_quiz: startTime,
      end_quiz: endTime,
      score: correct * 20,
      assessment_type: "POST",
    };
    let newData = dataConverter(data);
    if (auth.accessToken) {
      if (auth.userType === "child") {
        console.log(auth);
        let token = auth.accessToken;
        let config = { headers: { Authorization: `Bearer ${token}` } };
        console.log(config);
        await axios.post(
          URL.reviewComplete,
          {
            review_type: "Course Assessment",
            course_name: chaptername,
          },
          config
        );
        let response = await axios
          .post("assessment/logs/create/", newData, config)
          .catch((err) => console.log(err));
        console.log("res", response);
        console.log(newData);
        if (response.success == true) {
          setQuizEnded(!quizEnded);
        }
      } else {
        setQuizEnded(!quizEnded);
      }
    } else {
      setQuizEnded(!quizEnded);
    }
  };

  let modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      margin: "auto",
      borderRadius: 15,
      height: "60%",
      width: "50%",
      justifyContent: "center",
      maxWidth: 700,
      alignSelf: "center",
      overflow: "none",
    },
  };
  const renderQuiz = () => {
    let item = questionList[currentIndex];
    return (
      <div>
        <div className="quizContainerB">
          <div className="questiondiv">
            <p onClick={goBack} className="crossStyle pointer">
              <img src={Close} alt="" />
            </p>
            <p className="question_" style={{ fontWeight: "bold" }}>
              {currentIndex + 1}) {item.question}
            </p>
            <p></p>
          </div>
          <div className="bodyQuiz">
            <div className="subQuestion">{item?.sub_question}</div>
            <div style={{ position: "relative", marginTop: 20 }}>
              {Array.isArray(item.equation) ? (
                item.equation.map((data, index) => {
                  return (
                    <div className="equation" key={index}>
                      {data}
                    </div>
                  );
                })
              ) : (
                <div className="equation">{item.equation}</div>
              )}
            </div>
          </div>
          <div className="options">
            {item.options.map((data, optionIndex) => {
              return (
                <div
                  key={optionIndex}
                  className={`${
                    data == answers[currentIndex].answer
                      ? "optionn optionnSelected"
                      : "optionn"
                  }`}
                  onClick={() => selectOption(optionIndex, currentIndex, data)}
                >
                  <div
                    className={`${
                      data == answers[currentIndex].answer
                        ? "optionIndex optionIndexSelected"
                        : "optionIndex"
                    }`}
                  >
                    {getOptionIndex(optionIndex)}
                  </div>
                  <div style={{ width: "85%" }}>{data}</div>
                  <div></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    loading == false && (
      <>
        {!quizStarted ? (
          <div className="last_page">
            <FirstPage
              type="post"
              startQuiz={() => {
                setquizStarted(!quizStarted);
                setStartTime(new Date());
              }}
            />
          </div>
        ) : !quizEnded ? (
          <div className="container">
            <div>{renderQuiz()}</div>
            <div className="btnContainer">
              {currentIndex == 0 ? (
                <div></div>
              ) : (
                <div
                  className="modalButton1"
                  onClick={() => handleIndex("prev")}
                >
                  Previous
                </div>
              )}
              {currentIndex != questionList.length - 1 ? (
                <div
                  className="modalButton2"
                  onClick={() => handleIndex("next")}
                >
                  Next
                </div>
              ) : (
                <div className="modalButton2" onClick={toggleModal}>
                  Submit
                </div>
              )}
            </div>

            <Modal
              isOpen={modalVisible}
              onRequestClose={toggleModal}
              contentLabel="My dialog"
              style={modalStyles}
            >
              {confirmationModal()}
            </Modal>
          </div>
        ) : (
          <LastPage
            score={score}
            tryAgain={tryAgain}
            answers={answers}
            goBack={goBack}
            questionList={questionList}
          />
        )}
      </>
    )
  );
}
