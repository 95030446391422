const ProgressBar = ({ percentage }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "10px",
        borderRadius: "10px",
        backgroundColor: "#bebec1",
        marginTop: 5,
      }}
    >
      <div
        style={{
          width: `${percentage}%`,
          height: "100%",
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          backgroundColor: "#7abdf5",
        }}
      ></div>
    </div>
  );
};
export default ProgressBar;
