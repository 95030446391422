import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import OLElogo from "../../assets/images/OLELOGO.png";

export default function FooterNew({ createFormActive }) {
  if (createFormActive === true) return false;
  return (
    <div
      style={{
        backgroundColor: "#0577d9",
        height: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CopyrightIcon style={{ color: "white", marginRight: 20 }} />
      <p style={{ color: "#fff" }}>OLE Nepal</p>
    </div>
  );
}
