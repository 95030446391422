import CompleteIcon from "./complete_icon.svg";
import DocumentIcon from "./document_icon.svg";
import DocumentInProgress from "./document_inprogress_icon.svg";
import GameIcon from "./game_icon.svg";
import GameInProgress from "./game_inprogress_icon.svg";
import InteractiveIcon from "./interactive_icon.svg";
import InteractiveInProgress from "./interactive_inprogress_icon.svg";
import OngoingIcon from "./ongoing_icon.svg";
import QuizIcon from "./quiz_icon.svg";
import OngoingInProgress from "./ongoing_inprogress_icon.svg";
import QuizInProgress from "./quiz_inprogress_icon.svg";
import VideoIcon from "./video_icon.svg";
import VideoInProgress from "./video_inprogress_icon.svg";

export {
  CompleteIcon,
  DocumentIcon,
  DocumentInProgress,
  GameIcon,
  GameInProgress,
  InteractiveIcon,
  InteractiveInProgress,
  OngoingIcon,
  QuizIcon,
  OngoingInProgress,
  QuizInProgress,
  VideoIcon,
  VideoInProgress,
};
