import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import PersistAuth from "./components/auth/PresistAuth";
import RequireAuth from "./components/auth/RequireAuth";
import BadgePointPage from "./pages/badges/BadgesPoints";
import ChapterIntro from "./pages/chapterIntro/ChapterIntro";
import CourseDetails from "./pages/coursedetails/CourseDetails";
import CoursePage from "./pages/coursepage/CoursePage";
import ChildDetailedScore from "./pages/dashboard/child/ChildDetailedScore";
import ChildProfile from "./pages/dashboard/child/ChildProfile";
import DetailedScore from "./pages/dashboard/child/DetailedScore";
import MyCourses from "./pages/dashboard/child/MyCourses";
import HomePage from "./pages/homepage/HomePage";
import ForgotPass from "./pages/loginStack/forgotPassword/ForgotPass";
import LoginPage from "./pages/loginStack/login/LoginPage";
import ForgotOtp from "./pages/loginStack/otp/Otp";
import ResetPassword from "./pages/loginStack/reset/ResetPassword";
import ChangePassword from "./pages/parentPage/ChangePassword";
import ChildList from "./pages/parentPage/ChildList";
import CreateChild from "./pages/parentPage/CreateChild";
import CreateChildForm from "./pages/parentPage/CreateChildForm";
import ParentInfo from "./pages/parentPage/ParentInfo";
import ViewChild from "./pages/parentPage/ViewChild";
import PreviewQuiz from "./pages/previewQuiz/PreviewQuiz";
import ReviewQuiz from "./pages/reviewQuiz/ReviewQuiz";
import SignupPage from "./pages/signup/SignUpPage";
import WhatsNext from "./pages/whatsnext/WhatsNext";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PersistAuth />}>
          <Route path="/" exact element={<HomePage />}></Route>
          <Route path="/badges-points" exact element={<BadgePointPage />}></Route>

          {/* Login/Signup */}
          <Route path="/login" exact element={<LoginPage />}></Route>

          <Route path="/otp" exact element={<ForgotOtp />}></Route>
          <Route
            exact
            path="/reset-password/:userID/:tokenKey"
            element={<ResetPassword />}
          />
          <Route
            path="/login/forgot-password"
            exact
            element={<ForgotPass />}
          ></Route>
          <Route path="/register" exact element={<SignupPage />}></Route>

          {/* Course */}
          <Route
            path="/coursedetails/:chaptername"
            exact
            element={<CourseDetails />}
          ></Route>

          {/* Activities */}
          <Route
            path="/coursedetails/:chaptername/:unitUid/"
            exact
            element={<CoursePage />}
          />

          {/* Review */}
          <Route
            path="/coursedetails/:chaptername/review/post-assessment"
            exact
            element={<ReviewQuiz />}
          />
          <Route
            path="/coursedetails/:chaptername/review/whats-next"
            exact
            element={<WhatsNext />}
          />
          <Route
            path="/coursedetails/:chaptername/preview/pre-assessment"
            exact
            element={<PreviewQuiz />}
          />
          <Route
            path="/coursedetails/:chaptername/chapter-intro"
            exact
            element={<ChapterIntro />}
          />
          <Route element={<RequireAuth />}>
            <Route path="/create-child" exact element={<CreateChild />}></Route>
            <Route
              path="/create-child/form"
              exact
              element={<CreateChildForm />}
            ></Route>
            <Route path="/child-list" exact element={<ChildList />}></Route>
            <Route
              path="/dashboard/child/:childId"
              exact
              element={<ViewChild />}
            ></Route>
            <Route path="/profile" exact element={<ParentInfo />}></Route>
            <Route
              path="/dashboard/child/my-courses"
              exact
              element={<MyCourses />}
            />
            <Route
              path="/dashboard/child/my-profile"
              exact
              element={<ChildProfile />}
            />
            <Route
              path="/dashboard/child/:chaptername/log"
              exact
              element={<DetailedScore />}
            />
            <Route
              path="/dashboard/:childId/:chaptername/log"
              exact
              element={<ChildDetailedScore />}
            />
            <Route
              path="/dashboard/change-password"
              exact
              element={<ChangePassword />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
