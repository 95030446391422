const dataConverter = (data) => {
  const result = {
    course_name: data.course_name,
    data: {},
    duration: data.duration,
    start_quiz: data.start_quiz,
    end_quiz: data.end_quiz,
    score: data.score,
    assessment_type: data.assessment_type,
  };

  data.data.forEach((item) => {
    if (!result.data[`lesson_${item.lesson}`]) {
      result.data[`lesson_${item.lesson}`] = {};
    }

    if (!result.data[`lesson_${item.lesson}`][`level_${item.level}`]) {
      result.data[`lesson_${item.lesson}`][`level_${item.level}`] = {
        attempts: 0,
        correct: 0,
        incorrect: 0,
        skipped: 0,
      };
    }

    result.data[`lesson_${item.lesson}`][`level_${item.level}`].attempts +=
      item.attempts;
    result.data[`lesson_${item.lesson}`][`level_${item.level}`].correct +=
      item.correct;
    result.data[`lesson_${item.lesson}`][`level_${item.level}`].incorrect +=
      item.incorrect;
    result.data[`lesson_${item.lesson}`][`level_${item.level}`].skipped +=
      item.skipped;
  });

  console.log(result);
  return result;
};
export { dataConverter };
