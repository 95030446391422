import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BackColored,
  DropdownIcon,
} from "../../assets/commonicons/CommonIcons";
import {
  CompleteIcon,
  DocumentIcon,
  DocumentInProgress,
  GameIcon,
  GameInProgress,
  InteractiveIcon,
  InteractiveInProgress,
  OngoingIcon,
  OngoingInProgress,
  QuizIcon,
  QuizInProgress,
  VideoIcon,
  VideoInProgress,
} from "../../assets/coursedetails/CourseDetailsIcons";
import { CompletedProgress } from "../../assets/progressicons";
import Progress from "../../assets/progressicons/Progress";
import { Modal as CustomModal } from "../../components/modal/Modal";
import Header from "../../components/topbar/header/Header";
import { URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";
import { addInfoHandler, removeInfoHandler } from "../../lib/formValidation";
import "./coursedetails.css";
import { CircularProgress } from "@mui/material";

export default function CourseDetails() {
  const { chaptername } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = chaptername;
  }, []);

  // states
  const [lessonData, setLessonData] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState();
  const [loading, setLoading] = useState(true);
  const [mainAccordionIndex, setMainAccordionIndex] = useState();
  const [lessonAccordionIndex, setLessonAccordionIndex] = useState();
  const [chapterDetails, setChapterDetails] = useState([]);
  const [reviewToMap, setReviewToMap] = useState([]);

  const [isAlgebra, setIsAlgebra] = useState(false);
  const [isAlgebraTwo, setIsAlgebraTwo] = useState(false);
  const [isFraction, setIsFraction] = useState(false);
  const [isFractionTwo, setIsFractionTwo] = useState(false);
  const [isCircuit, setIsCircuit] = useState(false);
  const [isCircuitTwo, setIsCircuitTwo] = useState(false);

  const { auth } = useAuth();
  useEffect(() => {
    getLessonData();
    setMainAccordionIndex(0);
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    let modal = document.querySelector(".overlay");
    window.onclick = function (event) {
      if (event.target === modal) {
        setIsAlgebra(false);
        setIsAlgebraTwo(false);
        setIsFractionTwo(false);
        setIsFraction(false);
        setIsCircuitTwo(false);
        setIsCircuit(false);
        document.body.classList.remove("modal-open");
      }
    };
  }, [
    isAlgebra,
    isFraction,
    isCircuit,
    isAlgebraTwo,
    isCircuitTwo,
    isFractionTwo,
  ]);

  const guideOpen = () => addInfoHandler(setIsAlgebra);
  const onGuideClose = () => removeInfoHandler(setIsAlgebra);

  const guideOpenTwo = () => addInfoHandler(setIsAlgebraTwo);
  const onGuideCloseTwo = () => removeInfoHandler(setIsAlgebraTwo);

  const guideOpenThree = () => addInfoHandler(setIsFraction);
  const onGuideCloseThree = () => removeInfoHandler(setIsFraction);

  const guideOpenFour = () => addInfoHandler(setIsFractionTwo);
  const onGuideCloseFour = () => removeInfoHandler(setIsFractionTwo);

  const guideOpenFive = () => addInfoHandler(setIsCircuit);
  const onGuideCloseFive = () => removeInfoHandler(setIsCircuit);

  const guideOpenSix = () => addInfoHandler(setIsCircuitTwo);
  const onGuideCloseSix = () => removeInfoHandler(setIsCircuitTwo);

  const renderProgressDiv = (lesson) => {
    let width = lessonCompletePercentage(lesson);
    return (
      <div
        style={{
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: `${width}%`,
          backgroundColor: "rgba(4,119,216,255)",
          opacity: 0.2,
        }}
      />
    );
  };
  const lessonCompletePercentage = (lesson) => {
    let total = lesson.units.length;
    let completed = 0;

    lesson.units.map((sub) => {
      if (sub.completed == true) {
        completed++;
      }
    });
    if (total == 0) {
      return 0;
    } else {
      return (completed / total) * 100;
    }
  };

  const getLessonData = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response;
    if (auth.accessToken) {
      response = await axios
        .get(`${URL.getCourseLessons}?course_name=${chaptername}`, config)
        .catch((er) => console.log(er));
      setLessonData(response.data);
    } else {
      response = await axios
        .get(`${URL.getCourseLessons}?course_name=${chaptername}`)
        .catch((er) => console.log(er));
      setLessonData(response.data);
    }
    console.log(response);
    if (auth) {
      if (auth.userType === "child") {
        const overallRes = await axios
          .get(`${URL.courseProgress}?course_name=${chaptername}`, config)
          .catch((i) => console.log(i));
        setProgressPercentage(overallRes.data.progress_percentage);
      }
    }
    let res1 = await axios.get(
      `${URL.getCourseIntro}?course_name=${chaptername}`
    );
    setChapterDetails(res1.data);
    console.log(res1);
    setLoading(false);

    if (auth.accessToken) {
      setReviewToMap(response.data.review);
    } else {
      setReviewToMap(response.data.review.slice(0, -1));
    }
  };

  const getActivityIcon = (unit) => {
    if (unit.type == "EP") {
      if (unit.completed == true) {
        return CompleteIcon;
      } else {
        return InteractiveIcon;
      }
    }
    if (unit.type == "GA") {
      if (unit.completed == true) {
        return CompleteIcon;
      } else {
        return GameIcon;
      }
    }
    if (unit.type == "VD") {
      if (unit.completed == true) {
        return CompleteIcon;
      } else {
        return VideoIcon;
      }
    }
    if (unit.type == "QZ") {
      if (unit.completed == true) {
        return CompleteIcon;
      } else {
        return QuizIcon;
      }
    }
  };
  const getReviewIcons = (review) => {
    if (review.type == "Quiz") {
      if (review.completed == true) {
        return CompleteIcon;
      } else {
        return QuizIcon;
      }
    } else {
      if (review.completed == true) {
        return CompleteIcon;
      } else {
        return DocumentIcon;
      }
    }
  };
  const getPreviewIcons = (preview) => {
    if (preview.type == "Quiz") {
      if (preview.completed == true) {
        return CompleteIcon;
      } else {
        return QuizIcon;
      }
    } else {
      if (preview.completed == true) {
        return CompleteIcon;
      } else {
        return DocumentIcon;
      }
    }
  };

  const handleReviewClicked = (review) => {
    if (review.type == "Quiz") {
      navigate(`/coursedetails/${chaptername}/review/post-assessment`);
    } else {
      navigate(`/coursedetails/${chaptername}/review/whats-next`);
    }
  };
  const handlePreviewClicked = (preview) => {
    if (preview.type == "Quiz") {
      navigate(`/coursedetails/${chaptername}/preview/pre-assessment`);
    } else {
      navigate(`/coursedetails/${chaptername}/chapter-intro`);
    }
  };
  const handleUnitClicked = (unit) => {
    navigate(`/coursedetails/${chaptername}/${unit.uid}`);
  };
  const renderPreviewProgress = () => {
    let width = 0;
    let total = lessonData.preview.length;
    let completed = 0;
    lessonData.preview.map((preview) => {
      if (preview.completed == true) {
        completed++;
      }
    });
    if (total == 0) {
      width = 0;
    } else {
      width = (completed / total) * 100;
    }
    return (
      <div
        style={{
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: `${width}%`,
          backgroundColor: "rgba(4,119,216,255)",
          opacity: 0.2,
        }}
      />
    );
  };
  const renderReviewProgress = () => {
    let width = 0;
    let total = lessonData.review.length;
    let completed = 0;
    lessonData.review.map((review) => {
      if (review.completed == true) {
        completed++;
      }
    });
    if (total == 0) {
      width = 0;
    } else {
      width = (completed / total) * 100;
    }
    return (
      <div
        style={{
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: `${width}%`,
          backgroundColor: "rgba(4,119,216,255)",
          opacity: 0.2,
        }}
      />
    );
  };
  const renderLessonProgress = () => {
    let totalUnits = 0;
    let completedUnits = 0;
    for (let lesson of lessonData.lesson) {
      for (let unit of lesson.units) {
        totalUnits++;
        if (unit.completed) {
          completedUnits++;
        }
      }
    }
    let percentComplete = (completedUnits / totalUnits) * 100;
    return (
      <div
        style={{
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: `${percentComplete}%`,
          backgroundColor: "rgba(4,119,216,255)",
          opacity: 0.2,
        }}
      />
    );
  };
  const conditionalModalOpen = () => {
    switch (lessonData.course.name) {
      case "Solve problems like an inspector":
        return guideOpen();
      case "Have fun with algebra":
        return guideOpenTwo();
      case "Think in fractions":
        return guideOpenThree();
      case "Have fun with fractions":
        return guideOpenFour();
      case "Have fun with circuits":
        return guideOpenFive();
      case "Make circuits":
        return guideOpenSix();
      default:
        return false;
    }
  };
  return loading == false ? (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <div className="coursesWrapper">
        <div className="screenCenter">
          <div>
            <img
              className="pointer"
              onClick={goBack}
              src={BackColored}
              alt="back"
            />
            <div className="web-wrapper">
              <h1 style={{ marginTop: 20 }}>{lessonData.course.name}</h1>
              <p style={{ marginTop: 10 }}>{chapterDetails.description}</p>
              <p style={{ marginTop: 10 }}>{chapterDetails.duration} minutes</p>
              <div
                className="course-guide pointer"
                onClick={conditionalModalOpen}
              >
                Course Guide
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="coursesListWrapper">
        <div className="courseListConatiner">
          <div>
            {auth.accessToken &&
              auth.userType === "child" &&
              (progressPercentage !== 100 ? (
                <Progress progress={progressPercentage} />
              ) : (
                <CompletedProgress />
              ))}
          </div>
          <div style={{ marginTop: 20 }}>
            {/*
                Accordion for Preview (Check Your Understanding)
              */}
            <div
              className={`accordionMainContainer ${
                mainAccordionIndex == 0 ? "open" : ""
              }`}
            >
              <div
                onClick={() => {
                  if (mainAccordionIndex != 0) {
                    setMainAccordionIndex(0);
                  } else {
                    setMainAccordionIndex();
                  }
                }}
                className={`accordionHead ${
                  mainAccordionIndex == 0 ? "open" : ""
                }`}
              >
                {auth.accessToken && renderPreviewProgress()}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginTop: 2, marginRight: 10 }}>
                    <img src={OngoingIcon} alt="" />
                  </div>
                  <div>
                    <h3>Check Your Understanding</h3>
                  </div>
                </div>
                <div></div>
                <img src={DropdownIcon} alt="" />
              </div>
              <div
                className={`accordionBody ${
                  mainAccordionIndex == 0 ? "open" : ""
                }`}
              >
                {lessonData.preview.map((preview) => {
                  return (
                    <div
                      className="accordionSingleBody"
                      onClick={() => handlePreviewClicked(preview)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginTop: 2, marginRight: 10 }}>
                          <img src={getPreviewIcons(preview)} alt="" />
                        </div>
                        <div>
                          <p>{preview.name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/*
                Accordion for Lessons (Build Your Understanding)
              */}
            <div
              className={`accordionMainContainer ${
                mainAccordionIndex == 1 ? "open" : ""
              }`}
            >
              <div
                onClick={() => {
                  if (mainAccordionIndex != 1) {
                    setMainAccordionIndex(1);
                  } else {
                    setMainAccordionIndex();
                  }
                }}
                className={`accordionHead ${
                  mainAccordionIndex == 1 ? "open" : ""
                }`}
              >
                {auth.accessToken && renderLessonProgress()}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginTop: 2, marginRight: 10 }}>
                    <img src={OngoingIcon} alt="" />
                  </div>
                  <div>
                    <h3>Build Your Understanding</h3>
                    <c>Total: {lessonData.lesson.length} lessons</c>
                  </div>
                </div>
                <div></div>
                <img src={DropdownIcon} alt="" />
              </div>
              <div
                className={`accordionBody ${
                  mainAccordionIndex == 1 ? "open" : ""
                }`}
              >
                {lessonData.lesson.map((lesson, i) => {
                  return (
                    <div
                      className={`accordionMainContainer ${
                        lessonAccordionIndex == i ? "open" : ""
                      }`}
                    >
                      <div
                        onClick={() => {
                          if (lessonAccordionIndex != i) {
                            setLessonAccordionIndex(i);
                          } else {
                            setLessonAccordionIndex();
                          }
                        }}
                        className={`accordionHead ${
                          lessonAccordionIndex == i ? "open" : ""
                        }`}
                      >
                        {auth.accessToken && renderProgressDiv(lesson)}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginTop: 2, marginRight: 10 }}>
                            <img
                              src={
                                lessonCompletePercentage(lesson) == 0
                                  ? OngoingIcon
                                  : lessonCompletePercentage(lesson) == 100
                                  ? CompleteIcon
                                  : OngoingInProgress
                              }
                              alt=""
                            />
                          </div>
                          <div>
                            <h3>{lesson.name}</h3>
                          </div>
                        </div>
                        <div></div>
                        <img src={DropdownIcon} alt="" />
                      </div>
                      <div
                        className={`accordionBody ${
                          lessonAccordionIndex == i ? "open" : ""
                        }`}
                      >
                        {lesson.units.map((unit) => {
                          return (
                            <div
                              className="accordionSingleBody"
                              onClick={() => handleUnitClicked(unit)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ marginTop: 2, marginRight: 10 }}>
                                  <img src={getActivityIcon(unit)} alt="" />
                                </div>
                                <div>
                                  <p>{unit.name}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/*
                Accordion for Review (Tesst Your Understanding)
              */}
            <div
              className={`accordionMainContainer ${
                mainAccordionIndex == 2 ? "open" : ""
              }`}
            >
              <div
                onClick={() => {
                  if (mainAccordionIndex != 2) {
                    setMainAccordionIndex(2);
                  } else {
                    setMainAccordionIndex();
                  }
                }}
                className={`accordionHead ${
                  mainAccordionIndex == 2 ? "open" : ""
                }`}
              >
                {auth.accessToken && renderReviewProgress()}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginTop: 2, marginRight: 10 }}>
                    <img src={OngoingIcon} alt="" />
                  </div>
                  <div>
                    <h3>Test Your Understanding</h3>
                  </div>
                </div>
                <div></div>
                <img src={DropdownIcon} alt="" />
              </div>
              <div
                className={`accordionBody ${
                  mainAccordionIndex == 2 ? "open" : ""
                }`}
              >
                {reviewToMap.map((review) => {
                  return (
                    <div
                      className="accordionSingleBody"
                      onClick={() => handleReviewClicked(review)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginTop: 2, marginRight: 10 }}>
                          <img src={getReviewIcons(review)} alt="" />
                        </div>
                        <div>
                          <p>{review.name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal isVisible={isAlgebra} className="custom-modal">
        <div className="modal_Holder">
          <ul>
            <li>
              <strong>Introduction</strong>
              <p>
                Hi there! Welcome to "Algebra I: Solve problems like an
                inspector". In this course, we're going to learn about basic
                algebra concepts and how to solve problems using the inspection
                and hit and trial methods.
              </p>
            </li>
            <li>
              <strong>Pre-requisites</strong>
              <p>
                To get the most out of this course, it's important that you
                already know how to do basic arithmetic with numbers. You don't
                need to know any other math concepts before starting this
                course.
              </p>
            </li>
            <li>
              <strong>Pre-assessment test</strong>
              <p>
                Before you get started, taking a pre-assessment test lets you
                see how much you already know about algebra. Take the
                pre-assessment test to find out your current understanding and
                identify any areas you need to focus on.
              </p>
            </li>
            <li>
              <strong> Build your understanding</strong>
              <p>
                In the "Build your understanding" section, you'll find fun
                lessons, games, and reviews to help you learn about algebra.
                Make sure to pay attention and practice what you've learned to
                get the most out of the course.
              </p>
            </li>
            <li>
              <strong>Test your understanding</strong>
              <p>
                Once you've finished the "Build your understanding" section,
                it's time to test your understanding with the course assessment
                quiz. You'll earn points and badges for completing the course,
                based on how well you do on the quiz.
              </p>
            </li>
            <li>
              <strong>Classroom or home activities</strong>
              <p>
                To keep learning about algebra, try some of these activities:
                <ul>
                  <li>
                    {" "}
                    Practice solving word problems using the inspection and hit
                    and trial methods
                  </li>
                  <li>
                    Play math games that involve algebraic concepts (e.g. BINGO
                    with algebraic expressions)
                  </li>
                  <li>
                    Draw pictures or diagrams to represent algebraic equations
                    or expressions
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Next steps</strong>
              <p>
                After finishing the "Algebra I: Solve problems like an
                inspector" course, If you're ready for more challenges, check
                out the next course in the series: "Algebra II: Have fun with
                algebra". In this course, you'll learn about algebraic
                expressions and solve real life problems. Keep practicing and
                you'll become an expert in no time!
              </p>
            </li>
          </ul>
          <div className="button_Holder">
            <button
              className="modal_Close pointer"
              style={{
                margin: "20px 0",
              }}
              onClick={onGuideClose}
            >
              Ok
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isVisible={isAlgebraTwo} className="custom-modal">
        <div className="modal_Holder">
          <ul>
            <li>
              <strong>Introduction</strong>
              <p>
                Welcome to "Algebra 2: Have fun with algebra"! In this course,
                you'll compare the inspection and hit and trial methods you
                might have learned in “Algebra I: Solve problems like an
                inspector”. You'll also learn how to make simple algebraic
                expressions and solve real-life word problems.
              </p>
            </li>
            <li>
              <strong>Pre-requisites</strong>
              <p>
                It is recommended that students have completed the "Algebra I:
                Solve problems like an inspector" course or have a similar level
                of understanding before starting this course.
              </p>
            </li>
            <li>
              <strong>Pre-assessment test</strong>
              <p>
                Before you start the course, take the pre-assessment test to see
                what you already know. This will help you see what you need to
                focus on as you go through the course.
              </p>
            </li>
            <li>
              <strong> Build your understanding</strong>
              <p>
                In the "Build your understanding" section, you'll find games and
                interactive lessons to help you learn about algebra. Make sure
                to pay attention and practice what you've learned to get the
                most out of the course.
              </p>
            </li>
            <li>
              <strong>Test your understanding</strong>
              <p>
                After you've finished the "Build your understanding" section,
                it's time to test your understanding with the course assessment
                quiz. This will help you earn points and badges for completing
                the course.
              </p>
            </li>
            <li>
              <strong>Classroom or home activities</strong>
              <p>
                To keep learning about algebra, try some of these activities:
                <ul>
                  <li>
                    {" "}
                    Practice solving word problems using simple algebraic
                    expressions
                  </li>
                  <li>
                    Play math games that involve algebraic concepts (e.g.
                    tic-tac-toe with algebraic expressions)
                  </li>
                  <li>
                    Draw pictures or diagrams to represent algebraic equations
                    or expressions
                  </li>
                  <li>
                    Create your own algebraic riddles (puzzles) for your
                    classmates or family members to solve
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Next steps</strong>
              <p>
                After completing the "Algebra 2: Have fun with algebra" course,
                if you're ready for more challenges, consider taking one of
                these courses:
                <ul>
                  <li>Circuits I: Have fun with circuits</li>
                  <li>Circuits II: Make circuits</li>
                  <li>Fractions I: Think in fractions</li>
                  <li>Fractions II: Have fun with fractions</li>
                </ul>
                Remember to keep practicing and you'll become an expert in no
                time!
              </p>
            </li>
          </ul>
          <div className="button_Holder">
            <button
              className="modal_Close pointer"
              style={{
                margin: "20px 0",
              }}
              onClick={onGuideCloseTwo}
            >
              Ok
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isVisible={isFraction} className="custom-modal">
        <div className="modal_Holder">
          <ul>
            <li>
              <strong>Introduction</strong>
              <p>
                Hi there! Welcome to the "Fractions I: Think in Fractions"
                course. In this course, we're going to learn all about
                fractions. You'll find out what they are and how to do addition
                and subtraction with them.
              </p>
            </li>
            <li>
              <strong>Pre-requisites</strong>
              <p>
                Before you start this course, it's helpful if you already know
                how to do basic addition and subtraction with whole numbers
                (also called "integers"). But if you're new to fractions, don't
                worry! This course can also be your first introduction to them.
              </p>
            </li>
            <li>
              <strong>Pre-assessment test</strong>
              <p>
                Before we get started with the course, see how much you already
                know about fractions. Take the pre-assessment test to find out.
              </p>
            </li>
            <li>
              <strong> Build your understanding</strong>
              <p>
                In the "Build your understanding" section, you'll find fun
                lessons and videos that will help you learn about fractions.
                After each lesson, there's a review section where you can
                practice what you've learned and get feedback.
              </p>
            </li>
            <li>
              <strong>Test your understanding</strong>
              <p>
                Once you've finished the "Build your understanding" section,
                it's time to test your understanding with the course assessment
                quiz. Based on how well you do on the quiz, you'll earn points
                and badges for completing the course.
              </p>
            </li>
            <li>
              <strong>Classroom or home activities</strong>
              <p>
                To keep learning about fractions, try some of these activities:
                <ul>
                  <li>
                    Make a scavenger hunt for fractions around your home or
                    neighborhood, looking for examples of fractions in everyday
                    life
                  </li>
                  <li>
                    Find examples of fractions in everyday life (e.g. half a
                    momo, a quarter cup of tea) and create a collection
                  </li>
                  <li>
                    Practice simplifying fractions using a calculator or by
                    writing them down, using traditional measurements
                  </li>
                  <li>
                    Play a game where you have to identify fractions on a number
                    line, using everyday examples (e.g. 1/4 of a roti)
                  </li>
                  <li>
                    Create a fraction memory game using cards with different
                    fractions of traditional measurements (e.g. 1/2 tola, 1/4
                    tola, 3/4 tola)
                  </li>
                  <li>
                    Practice comparing fractions using real-life examples (e.g.
                    which is larger, 1/2 of a roti or 1/4 of a roti?)
                  </li>
                  <li>
                    Practice adding and subtracting fractions using real-life
                    examples (e.g. if you have 1/2 of a liter of milk and you
                    add 1/4 of a liter, how much milk do you have in total?)
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Next steps</strong>
              <p>
                After finishing the "Fractions I: Think in Fractions" course, if
                you're ready for more challenges, check out the next course in
                the series: "Fractions II: Have fun with fractions". In this
                course, you'll learn about more advanced concepts and practices
                with fractions.
              </p>
            </li>
          </ul>
          <div className="button_Holder">
            <button
              className="modal_Close pointer"
              style={{
                margin: "20px 0",
              }}
              onClick={onGuideCloseThree}
            >
              Ok
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isVisible={isFractionTwo} className="custom-modal">
        <div className="modal_Holder">
          <ul>
            <li>
              <strong>Introduction</strong>
              <p>
                Welcome to "Fractions 2: Have fun with fractions"! In this
                course, you'll continue your learning about fractions and build
                on the concepts you learned in "Fractions I: Think in
                Fractions". In this course, you'll learn about equivalent
                fractions and unlike fractions.
              </p>
            </li>
            <li>
              <strong>Pre-requisites</strong>
              <p>
                It is recommended that students have completed the "Fractions I:
                Think in Fractions" course or have a similar level of
                understanding before starting this course.
              </p>
            </li>
            <li>
              <strong>Pre-assessment test</strong>
              <p>
                Before you start the course, take the pre-assessment test to see
                what you already know. This will help you see what you need to
                focus on as you go through the course.
              </p>
            </li>
            <li>
              <strong> Build your understanding</strong>
              <p>
                In the "Build your understanding" section, you'll find an
                interactive story, a game, and lesson reviews to help you learn
                about fractions. Make sure to pay attention and practice what
                you've learned to get the most out of the course.
              </p>
            </li>
            <li>
              <strong>Test your understanding</strong>
              <p>
                After you've finished the "Build your understanding" section,
                it's time to test your understanding with the course assessment
                quiz. This will help you earn points and badges for completing
                the course.
              </p>
            </li>
            <li>
              <strong>Classroom or home activities</strong>
              <p>
                To keep learning about fractions, try some of these activities:
                <ul>
                  <li>
                    Practice comparing fractions using a fraction calculator or
                    by writing them down
                  </li>
                  <li>
                    Find real-life examples of equivalent fractions (e.g. 1/2
                    and 2/4) and practice simplifying them
                  </li>
                  <li>
                    Find real-life examples of unlike fractions (e.g. 1/2 and
                    3/4) and practice adding and subtracting them
                  </li>
                  <li>
                    Practice multiplying and dividing fractions using real-life
                    examples (e.g. if you have 1/2 of a momo and you divide it
                    into 3 equal parts, what fraction of a momo do you have?)
                  </li>
                  <li>
                    Play a game where you have to match equivalent fractions on
                    a number line
                  </li>
                  <li>
                    Create a fraction bingo game using cards with different
                    fractions on them
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Next steps</strong>
              <p>
                After completing the "Fractions 2: Have fun with fractions"
                course, if you're ready for more challenges, consider taking one
                of these courses:
              </p>
              <p>
                <ul>
                  <li>Algebra I: Solve problems like an inspector</li>
                  <li>Algebra II: Have fun with algebra</li>
                  <li>Circuits I: Have fun with circuits</li>
                  <li>Circuits II: Make circuits</li>
                </ul>
                Remember to keep practicing and you'll become an expert in no
                time!
              </p>
            </li>
          </ul>
          <div className="button_Holder">
            <button
              className="modal_Close pointer"
              style={{
                margin: "20px 0",
              }}
              onClick={onGuideCloseFour}
            >
              Ok
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isVisible={isCircuit} className="custom-modal">
        <div className="modal_Holder">
          <ul>
            <li>
              <strong>Introduction</strong>
              <p>
                Hey there! Welcome to "Circuits I: Have fun with circuits". In
                this course, we're going to learn all about electrical circuits.
                We'll find out what they are and how they work, using everyday
                examples and simple experiments that you can try at home or in
                the classroom.
              </p>
            </li>
            <li>
              <strong>Pre-requisites</strong>
              <p>
                To get the most out of this course, it's important that you
                already have a basic understanding of math and science concepts,
                including electricity. You don't need to know anything specific
                about circuits before starting this course, but it will be
                helpful if you're familiar with electricity in some way.
              </p>
            </li>
            <li>
              <strong>Pre-assessment test</strong>
              <p>
                Before we get started with the course, let's see how much you
                already know about circuits. Take the pre-assessment test to
                find out.
              </p>
            </li>
            <li>
              <strong> Build your understanding</strong>
              <p>
                In the "Build your understanding" section, you'll find
                interactive lessons, videos, and simple experiments to help you
                learn about circuits. After each lesson, there's a review
                section where you can practice what you've learned and get
                feedback.
              </p>
            </li>
            <li>
              <strong>Test your understanding</strong>
              <p>
                Once you've finished the "Build your understanding" section,
                it's time to test your understanding with the course assessment
                quiz. Based on how well you do on the quiz, you'll earn points
                and badges for completing the course.
              </p>
            </li>
            <li>
              <strong>Classroom or home activities</strong>
              <p>
                To keep learning about circuits, try some of these activities:
                <ul>
                  <li>
                    Build a simple circuit using a battery, wire, and bulb
                  </li>
                  <li>
                    Experiment with different types of wires and see how they
                    affect the circuit
                  </li>
                  <li>
                    Build a battery out of lemons, potatoes, or dung and use it
                    to power a bulb or other electrical device
                  </li>
                  <li>
                    Create a circuit using a switch to turn the circuit on and
                    off
                  </li>
                  <li>
                    Investigate how the resistance of different materials
                    affects the circuit
                  </li>
                  <li>
                    Create a series circuit and a parallel circuit and compare
                    how they work
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Next steps</strong>
              <p>
                After finishing the "Circuits I: Have fun with circuits" course,
                you can now continue with the next course in the series
                “Circuits II: Make circuits”. There you will learn about circuit
                components and symbols, and how to draw circuits.
              </p>
            </li>
          </ul>
          <div className="button_Holder">
            <button
              className="modal_Close pointer"
              style={{
                margin: "20px 0",
              }}
              onClick={onGuideCloseFive}
            >
              Ok
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isVisible={isCircuitTwo} className="custom-modal">
        <div className="modal_Holder">
          <ul>
            <li>
              <strong>Introduction</strong>
              <p>
                Welcome to "Circuits II: Make circuits"! In this course, you'll
                continue your learning about circuits and build on the concepts
                you learned in "Circuits I: Have fun with circuits". In this
                course, you'll learn about circuit symbols, how to draw
                circuits, and how to identify the components of circuits.
              </p>
            </li>
            <li>
              <strong>Pre-requisites</strong>
              <p>
                It is recommended that students have completed the "Circuits I:
                Have fun with circuits" course or have a similar level of
                understanding before starting this course.
              </p>
            </li>
            <li>
              <strong>Pre-assessment test</strong>
              <p>
                Before you start the course, take the pre-assessment test to see
                what you already know. This will help you see what you need to
                focus on as you go through the course.
              </p>
            </li>
            <li>
              <strong> Build your understanding</strong>
              <p>
                In the "Build your understanding" section, you'll find
                interactive lessons and videos to help you learn about circuits.
                Make sure to pay attention and practice what you've learned to
                get the most out of the course.
              </p>
            </li>
            <li>
              <strong>Test your understanding</strong>
              <p>
                After you've finished the "Build your understanding" section,
                it's time to test your understanding with the course assessment
                quiz. Based on how well you do on the quiz, you'll earn points
                and badges for completing the course.
              </p>
            </li>
            <li>
              <strong>Classroom or home activities</strong>
              <p>
                To keep learning about circuits, try some of these activities:
                <ul>
                  <li>Use circuit symbols to draw a circuit diagram</li>
                  <li>
                    Build a circuit using different components (e.g. switches,
                    resistors, capacitors)
                  </li>
                  <li>
                    Experiment with different types of switches and see how they
                    affect the circuit
                  </li>
                  <li>Create a circuit with a timer or timer switch</li>
                  <li>
                    Investigate how the resistance of different materials
                    affects the circuit
                  </li>
                  <li>
                    Create a series circuit and a parallel circuit and compare
                    how they work
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Next steps</strong>
              <p>
                After completing the "Circuits II: Make circuits" course, if
                you're ready for more challenges, consider taking one of these
                courses:
                <ul>
                  <li>Fractions I: Think in Fractions</li>
                  <li>Fractions II: Have fun with fractions</li>
                  <li>Algebra I: Solve problems like an inspector</li>
                  <li>Algebra II: Have fun with algebra</li>
                </ul>
                Remember to keep practicing and you'll become an expert in no
                time!
              </p>
            </li>
          </ul>
          <div className="button_Holder">
            <button
              className="modal_Close pointer"
              style={{
                margin: "20px 0",
              }}
              onClick={onGuideCloseSix}
            >
              Ok
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress size={30} />
      <h3 style={{ marginTop: 20 }}>Loading Courses...</h3>
    </div>
  );
}
