import React, { useState, useEffect } from "react";
import { Close } from "../../../assets/commonicons/CommonIcons";
import TapeGenerator from "../../../components/fractionsHelper/Tape";
import "../feedback.css";

export default function Feedback({ data, selectedAnswer, toggleFeedback }) {
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState({
    fb1: {
      head: "",
      body1: "",
    },
    fb2: {
      head: "",
      dia: null,
      dia1: null,
      body: "",
      body1: "",
      body2: "",
    },
    fb3: {
      head: "",
      body1: "",
      body2: "",
    },
  });
  useEffect(() => {
    prepareFeedback();
  }, []);
  const getComparator = (item) => {
    if (item === ">") {
      return "is greater than";
    }
    if (item === "<") {
      return "is less than";
    }
    if (item === "=") {
      return "is equal to";
    }
    if (item === "+") {
      return "plus";
    }
  };
  const getLeftOrRight = (item) => {
    if (item === ">") {
      return "first";
    } else {
      return "second";
    }
  };
  const getSymbol = (item) => {
    if (item.includes(">")) {
      return ">";
    }
    if (item.includes("<")) {
      return "<";
    }
    if (item.includes("=")) {
      return "=";
    }
  };
  const getComparatorName = (item) => {
    if (item.includes(">")) {
      return "is greater than";
    }
    if (item.includes("<")) {
      return "is smaller than";
    }
    if (item.includes("=")) {
      return "is equal to";
    }
  };
  const getWord = (number, word) => {
    if (number == 1) {
      return word;
    } else {
      return word + "s";
    }
  };
  const getIsAre = (number) => {
    if (number == 1) {
      return "is";
    } else {
      return "are";
    }
  };
  const prepareFeedback = () => {
    console.log(data);
    let feedbacks = { ...feedback };
    let correct;
    if (data.correct_answer === selectedAnswer) correct = true;
    else correct = false;
    //feedback for lesson 1

    if (data.lesson === 1) {
      //lesson 1 feedback
      if (data.level === 1) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `Both diagrams are the same size but are cut into different number of parts.`;
          feedbacks.fb2.body2 = `So, we can compare the fractions visually.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `The ${getLeftOrRight(
            data.correct_answer
          )} diagram has shades covering more area than the first diagram.`;
          feedbacks.fb3.body2 = `So, ${data.numerator[0]}/${data.denominator[0]} ${data.correct_answer} ${data.numerator[1]}/${data.denominator[1]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";

          //second box

          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `Both diagrams are the same size but are cut into different number of parts.`;
          feedbacks.fb2.body2 = `So, we can compare the fractions visually.`;

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Which diagram has shades covering the most area?`;
        }
      }
      if (data.level === 2) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `Both diagrams are the same size but are cut into different number of parts.`;
          feedbacks.fb2.body2 = `So, we can compare the fractions visually.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Both diagrams have shaded parts that cover the same amount of area.`;
          feedbacks.fb3.body2 = `So, ${data.numerator[0]}/${data.denominator[0]} = ${data.numerator[1]}/${data.denominator[1]}`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `Both diagrams are the same size but are cut into different number of parts.`;
          feedbacks.fb2.body2 = `So, we can compare the fractions visually.`;

          //third box
          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Can you check visually if the two diagrams have shaded parts that cover the same amount of area or more area or less area?`;
        }
      }
      if (data.level === 3) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} + ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) + parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } + ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) + parseInt(data.numerator[1])
          }/${data.denominator[0]}.`;
        } else {
          //first box
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Add the numerators correctly.`;
          feedbacks.fb3.body2 = `Do not add the denominators.`;
        }
      }
      if (data.level === 4) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just subtract the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} - ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) - parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } - ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) - parseInt(data.numerator[1])
          }/${data.denominator[0]}.`;
        } else {
          //first box
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Subtract the numerators correctly.`;
          feedbacks.fb3.body2 = `Do not subtract the denominators.`;
        }
      }
    }
    if (data.lesson === 2) {
      //lesson 1 feedback
      if (data.level === 1) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body2 = `Comparing the diagrams of th two fractions visually, you will find ${data.numerator[0]}/${data.denominator[0]} ${data.correct_answer} ${data.numerator[1]}/${data.denominator[1]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";

          //second box

          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Which diagram has shades covering the least area?`;
          feedbacks.fb3.body2 = `Compare the fractions visually and try again.`;
        }
      }
      if (data.level === 2) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body2 = `Comparing the diagrams of the two fractions visually, you will find ${data.numerator[0]}/${data.denominator[0]} = ${data.numerator[1]}/${data.denominator[1]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box
          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Can you check visually if the two diagrams have shaded parts that cover the same amount of area or more area or less area?`;
        }
      }
      if (data.level === 3) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} + ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) + parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } + ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) + parseInt(data.numerator[1])
          }/${data.denominator[0]}`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box
          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Add the numerators correctly.`;
          feedbacks.fb3.body2 = `Do not add the denominators.`;
        }
      }
      if (data.level === 4) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just subtract the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} - ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) - parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } - ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) - parseInt(data.numerator[1])
          }/${data.denominator[0]}`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Subtract the numerators correctly.`;
          feedbacks.fb3.body2 = `Do not subtract the denominators.`;
        }
      }
    }
    if (data.lesson === 3) {
      if (data.level === 1) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} ${getSymbol(
            data.correct_answer
          )} ${data.numerator[1]}, ${data.numerator[0]}/${
            data.denominator[0]
          } ${getComparatorName(data.correct_answer)} ${data.numerator[1]}/${
            data.denominator[0]
          }`;
          feedbacks.fb3.body2 = `Or, ${data.numerator[0]}/${
            data.denominator[0]
          } ${getSymbol(data.correct_answer)} ${data.numerator[1]}/${
            data.denominator[0]
          }.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Which numerator is smaller?`;
        }
      }
      if (data.level === 2) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body2 = `Comparing the diagrams of the two fractions visually, you will find ${data.correct_answer}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box
          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Which diagram has shades covering the least area?`;
          feedbacks.fb3.body2 = `Compare the fractions visually and try again.`;
        }
      }
      if (data.level === 3) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body2 = `Comparing the diagrams of the two fractions visually, you will find ${data.correct_answer}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = "The fractions have different denominators.";
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body1 = `You can make diagrams to represent the two fractions.`;
          feedbacks.fb2.body2 = `Then shade the fractions according to the numerator.`;

          //third box
          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Can you check visually if the two diagrams have shaded parts that cover the same amount of area or more area or less area?`;
        }
      }
      if (data.level === 4) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} + ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) + parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } + ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) + parseInt(data.numerator[1])
          }/${data.denominator[0]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Add the numerators correctly.`;
          feedbacks.fb3.body2 = `Do not add the denominators.`;
        }
      }
      if (data.level === 5) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just subtract the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} - ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) - parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } - ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) - parseInt(data.numerator[1])
          }/${data.denominator[0]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Subtract the numerators correctly.`;
          feedbacks.fb3.body2 = `Do not subtract the denominators.`;
        }
      }
    }
    setFeedback(feedbacks);
    setLoading(false);
  };

  return (
    loading == false && (
      <>
        <div onClick={toggleFeedback} className="quizModalCross">
          <img src={Close} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3
            style={{
              padding: 10,
              backgroundColor: "#69b8e2",
              color: "white",
              borderRadius: 10,
            }}
          >
            Know Why?
          </h3>
        </div>
        <div className="feedbackWrapper">
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb1.head}</div>
            <div className="fbbody">{feedback.fb1.body1}</div>
            <div className="fbbody">{feedback.fb1.body2}</div>
          </div>
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb2.head}</div>
            <div className="fbbody">{feedback.fb2.body}</div>
            <div className="fbBody">{feedback.fb2.dia}</div>
            <div className="fbbody">{feedback.fb2.body1}</div>
            <div className="fbBody">{feedback.fb2.dia1}</div>
            <div className="fbbody">{feedback.fb2.body2}</div>
          </div>
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb3.head}</div>
            <div className="fbbody">{feedback.fb3.body1}</div>
            <div className="fbbody">{feedback.fb3.body2}</div>
            <div className="fbbody">{feedback.fb3.body3}</div>
          </div>
        </div>
      </>
    )
  );
}
