import React, { useState, useEffect } from "react";
import { CircularProgress, Button } from "@mui/material";
import Header from "../../../components/topbar/header/Header";
import { Modal } from "../../../components/modal/Modal";
import "./childprofile.css";
import { URL } from "../../../constants/url";
import { RewardCoin } from "../../../assets/commonicons/CommonIcons";
import { axios } from "../../../lib/axios";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function ChildProfile() {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [childDetails, setChildDetails] = useState([]);
  const [allProfilePictures, setAllProfilePictures] = useState([]);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const [scoreList, setScoreList] = useState([]);
  const [badgeList, setBadgeList] = useState([]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    document.title = `My Profile - ${auth.username}`;
  }, []);

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response = await axios
      .get(`${URL.getChildDetails}`, config)
      .catch((er) => console.log(er));
    console.log(response);
    if (response.success === true) {
      setChildDetails(response.data);
    }
    let res1 = await axios
      .get(`${URL.getAllProfilePictures}`, config)
      .catch((er) => console.log(er));
    console.log(res1);
    if (res1.success === true) {
      setAllProfilePictures(res1.data);
    }
    let res2 = await axios
      .get(`${URL.getAssessmentLogs}`, config)
      .catch((er) => console.log(er));
    console.log(res2);
    if (res2.success === true) {
      setScoreList(res2.data);
    }
    let res3 = await axios
      .get(`${URL.getBadges}`, config)
      .catch((er) => console.log(er));
    console.log(res3);
    if (res3.success === true) {
      setBadgeList(res3.data);
    }
    setLoading(false);
  };
  const handleChangeProfilePicture = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let data = {
      uid: selectedProfilePicture.uid,
    };
    let response = await axios
      .post(`${URL.changeProfilePicture}`, data, config)
      .catch((er) => console.log(er));
    console.log(response);
    if (response.success === true) {
      console.log(selectedProfilePicture.link);
      setAuth({ ...auth, profile_picture: selectedProfilePicture.link });
      toggleModal();
    }
    let res1 = await axios
      .get(`${URL.getAssessmentLogs}`, config)
      .catch((er) => console.log(er));
    console.log(res1);
    if (res1.success === true) {
      setScoreList(res1.data);
    }
  };
  let modalStyles = {
    overlay: {
      position: "fixed",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      height: "80%",
      width: "100%",
      borderRadius: 15,
      top: "22%",
      justifyContent: "center",
      backgroundColor: "#f5f7fa",
      overflow: "none",
      zIndex: 1000,
    },
  };

  return loading === false ? (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <div className="coursesWrapper">
        <div className="screenCenter">
          <div className="profileHead web-wrapper">
            <div>
              {/* <img src="" alt="" /> */}
              <div className="profilePicture">
                <img
                  className="profilePictureImage"
                  src={auth.profile_picture}
                  alt=""
                />
              </div>
            </div>
            <div style={{ marginLeft: 50 }}>
              <div>
                <h2>{childDetails.full_name}</h2>
              </div>
              <div>
                <p>Username: {childDetails.username}</p>
              </div>
              <div
                className="pointer"
                style={{ color: "blue", textDecoration: "underline" }}
                onClick={toggleModal}
              >
                Change profile picture
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="coursesListWrapper">
        <div className="courseListConatiner">
          <div className="section">
            <div>
              <h2>Rewards</h2>
            </div>
            <div className="element">
              <div>
                <p>Total Points Earned</p>
              </div>
              <div className="pointBox">
                <img style={{ marginRight: 10 }} src={RewardCoin} alt="" />
                <div>{childDetails.total_points}</div>
              </div>
            </div>
          </div>
          {scoreList.length !== 0 && (
            <div className="section">
              <div>
                <h2>Scores</h2>
              </div>
              <div className="scoreList">
                {scoreList.map((course) => {
                  return (
                    <div
                      className="scoreListElement pointer"
                      onClick={() =>
                        navigate(`/dashboard/child/${course.course_name}/log`)
                      }
                    >
                      <div>
                        <p>{course.course_name}</p>
                      </div>
                      <div className="pointBox">
                        <p>
                          {course.user_score === null ? 0 : course.user_score}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {badgeList.length !== 0 && (
            <div className="section">
              <div>
                <h2>Badges</h2>
              </div>
              <div
                style={{
                  display: "flex",
                  overflowY: "hidden",
                  overflowX: "scroll",
                  paddingBottom: 20,
                  paddingTop: 20,
                }}
                className="noScroll"
              >
                {badgeList.map((badge) => {
                  return (
                    <div className="badge-element">
                      <div
                        style={{
                          display: "flex",
                          height: 150,
                          width: 150,
                          paddingLeft: 20,
                          paddingRight: 20,
                          justifyContent: "cener",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={badge.badge_image_url}
                          height="100%"
                          width="100%"
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: 2,
                          paddingRight: 2,
                          paddingBottom: 5,
                        }}
                      >
                        <h3>{badge.course_name}</h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isVisible={modalVisible}
        onClose={toggleModal}
        contentLabel="Courses Select Modal"
        className={"custom-modal"}
      >
        <div className="modalContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div style={{ textAlign: "center", marginBottom: 20 }}>
              <h2>Select Avatar</h2>
            </div>
            <div style={{ marginBottom: 20 }}></div>
          </div>
          <div className="scrollableThingy noScroll">
            <div className="profilePictureList">
              {allProfilePictures.map((item) => {
                return (
                  <div
                    onClick={() => setSelectedProfilePicture(item)}
                    className={
                      selectedProfilePicture === item
                        ? "profilePictureSelected pointer"
                        : "profilePicture pointer"
                    }
                  >
                    <img
                      className="profilePictureImage"
                      src={item.link}
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ position: "relative" }} className="head">
            <Button variant="outlined" onClick={toggleModal}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleChangeProfilePicture}>
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress size={30} />
      <h3 style={{ marginTop: 20 }}>Loading Profile...</h3>
    </div>
  );
}

export default ChildProfile;
