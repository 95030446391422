import { axios } from "../lib/axios";

import useAuth from "./useAuth";

const USER_DETAILS_URL = "/account/user-details/";

const useUserDetails = () => {
  const { auth, setAuth } = useAuth();

  const updateUserDetails = async () => {
    const response = await axios.get(USER_DETAILS_URL, {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    });
    setAuth(prevAuth => ({
      ...prevAuth,
      username: response.data.username,
      fullName: response.data.full_name,
      isVerified: response.data.is_verified,
      email: response.data.user_email,
      userType: response.data.user_type,
      profile_picture: response.data.profile,
    }));
    return auth;
  };
  return updateUserDetails;
};
export default useUserDetails;
