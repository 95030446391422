import React, { useEffect } from "react";
import { Button } from "@mui/material";
import "./lastpage.css";

const LastPage = ({ goBack, onComplete }) => {
  useEffect(() => {
    onComplete();
  }, []);

  return (
    <>
      <div>
        <h2>
          Awesome ! <br></br> You did it
        </h2>
      </div>
      <div className="lastPageClose" onClick={goBack}>
        <Button sx={{ color: "white" }}>Continue</Button>
      </div>
    </>
  );
};

export default LastPage;
