import { Button, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../assets/images";
import MenuContext from "../../../context/MenuContext";
import useAuth from "../../../hooks/useAuth";
import { axios } from "../../../lib/axios";
import DrawerComp from "../../drawerComp/DrawerComp";
import "./header.css";

const Header = ({ headerStyles }) => {
  const [value, setValue] = useState();

  const { menuOptions, defineOptions, setOpenDrawer } = useContext(MenuContext);

  const navigate = useNavigate();

  const { auth, setAuth } = useAuth();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogOut = async () => {
    let token = auth?.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response = await axios
      .delete(`account/user/logout/`, config)
      .catch((er) => console.log(er));
    if (response?.success === true) {
      setAuth({});
    }
    navigate("/");
  };
  const handleNavigation = (n) => {
    if (n === "logout") {
      handleLogOut();
      defineOptions();
    } else {
      navigate(n);
      setOpenDrawer(false);
    }
  };

  const conditionalLogin = () => {
    if (Object.keys(auth)?.length === 0) {
      navigate("/login");
    } else {
      handleLogOut();
    }
  };

  return (
    <div className="navigationWrapper" style={headerStyles}>
      {isMatch ? (
        <div className="smolNav">
          <DrawerComp />
          <h1
            className="pointer"
            style={{ color: "white", marginRight: 30 }}
            onClick={() => navigate("/")}
          >
            <img src={Logo} alt="Sipalya" />
          </h1>
          <div></div>
        </div>
      ) : (
        <div className="screenCenter">
          <div className="hugeNav">
            <h1 onClick={() => navigate("/")} className="pointer">
              <img src={Logo} alt="Sipalya" />
            </h1>
            <Tabs
              textColor="inherit"
              value={value}
              onChange={(e, value) => setValue(value)}
              indicatorColor="secondary"
            >
              {menuOptions.map((page, index) => {
                if (
                  page.text === "Login" ||
                  page.text === "Sign Up" ||
                  page.text === "Log Out"
                )
                  return false;
                return (
                  <Tab
                    key={index}
                    label={page.text}
                    onClick={() => handleNavigation(page.navigate)}
                  />
                );
              })}
            </Tabs>
            <div>
              <Button
                sx={{ marginLeft: "auto", backgroundColor: "#048bff" }}
                variant="contained"
                onClick={conditionalLogin}
              >
                {auth?.accessToken ? "Logout" : "Login"}
              </Button>
              {!auth?.accessToken ? (
                <Button
                  sx={{ marginLeft: "14px", backgroundColor: "#048bff" }}
                  variant="contained"
                  onClick={() => navigate("/register")}
                >
                  Sign Up
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
