import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import React, { useEffect, useState } from "react";
import { DeleteIcon, EditIcon } from "../../assets/commonicons/CommonIcons";
import { Modal } from "../../components/modal/Modal";
import ChildAccountEdit from "./ChildAccountEdit";

//css
// import "./child.css";
import { createStyles } from "@mui/material";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";

const ChildList = ({
  listShow,
  childDetails,
  error,
  formData,
  nonFormData,
  setNonFormData,
  setFormData,
  changeDataHandler,
  validateForm,
  deleteChild,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isChildEdit, setIsChildEdit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);

  useEffect(() => {
    let modal = document.querySelector(".overlay");
    window.onclick = function (event) {
      if (event.target === modal) {
        setIsChildEdit(false);
      }
    };
  }, [isChildEdit]);
  const handleEditSuccess = () => {
    setIsChildEdit(false);

    window.location.reload();
  };

  const actionButtonStyles = createStyles({
    button: {
      padding: "0",
      minWidth: "0",
      "&:focus": {
        color: "red",
      },
    },
  });

  const handleClick = (event, index) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setSelectedChild(index);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  //   const { name } = childDetail;
  if (listShow === false) return false;

  let modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      margin: "auto",
      borderRadius: 15,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      maxHeight: "30%",
      width: "90%",
      justifyContent: "center",
      alignSelf: "center",
      overflow: "none",
    },
  };
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleViewChild = (child) => {
    navigate(`/dashboard/child/${child.username}`);
  };

  return childDetails?.map((child, index) => {
    const { full_name } = child;
    if (index < 2) {
      return (
        <>
          <div className="childList d-flex justify-space-between" key={index}>
            <span className="childName">{full_name}</span>
            <div className="alignRight">
              <span
                onClick={() => handleViewChild(child)}
                style={{ marginRight: 10 }}
                className="viewChild pointer"
              >
                View
              </span>
              <span className="toggleInto"></span>
              <Button
                aria-describedby={id}
                onClick={(event) => {
                  handleClick(event, index);
                }}
                style={actionButtonStyles}
              >
                <MoreVertIcon
                  sx={{
                    color: "#ccc",
                  }}
                  size="small"
                />
              </Button>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
              >
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: "10px 10px 0",
                    bgcolor: "#f5f6fb",
                    borderRadius: "5px",
                  }}
                >
                  <ul className="unstyled childActions">
                    <li
                      className="d-flex align-center pointer"
                      onClick={() => {
                        setIsChildEdit(true);
                        setAnchorEl(null);
                      }}
                    >
                      <img src={EditIcon} alt="edit-icon" />
                      Edit
                    </li>
                    <li
                      onClick={() => {
                        setAnchorEl(!anchorEl);
                        toggleModal();
                      }}
                      className="d-flex align-center pointer"
                    >
                      <img src={DeleteIcon} alt="delete-icon" />
                      Delete
                    </li>
                  </ul>
                </Box>
              </Popper>
            </div>
          </div>
          <ReactModal
            isOpen={modalVisible}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            style={modalStyles}
          >
            {" "}
            <div className="modalInnerStyle">
              <div onClick={toggleModal} className="modalCross">
                x
              </div>
              <div className="modalText">
                <h2>Are you sure?</h2>
                <div className="modalText2">
                  <p>This action cannot be undone.</p>
                </div>
              </div>
              <div className="modalButtons">
                <div onClick={toggleModal} className="modalButton1">
                  Go Back
                </div>
                <div
                  onClick={() => deleteChild(selectedChild)}
                  className="modalButton2"
                >
                  Submit
                </div>
              </div>
            </div>
          </ReactModal>
          <Modal
            onClose={() => setIsChildEdit(false)}
            isVisible={isChildEdit}
            className="custom-modal"
          >
            <div className="modal_Holder">
              <ChildAccountEdit
                error={error}
                formData={formData}
                nonFormData={nonFormData}
                setNonFormData={setNonFormData}
                setFormData={setFormData}
                changeDataHandler={changeDataHandler}
                validateForm={validateForm}
                child={childDetails[selectedChild]}
                handleEditSuccess={handleEditSuccess}
              />
            </div>
          </Modal>
        </>
      );
    } else {
      return false;
    }
  });
};

export default ChildList;
