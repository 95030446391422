import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";
import "./WhatsNext.css";
import { Close } from "../../assets/commonicons/CommonIcons";
import { useNavigate, useParams } from "react-router-dom";
import { axios } from "../../lib/axios";
import { Mascot } from "../../assets/commonicons/CommonIcons";
import { URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";
import { GirlImageNew } from "../../assets/images";

function WhatsNext() {
  const { chaptername } = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();

  useEffect(() => {
    document.title = `What's Next - ${chaptername}`;
  }, []);

  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiCalled = useRef(false);

  useEffect(() => {
    getCourses();
    completeCourse();
    changeFlutterOrientation("landScape");
  }, []);
  function changeFlutterOrientation(orientationName) {
    try {
      Print.postMessage(orientationName);
    } catch {
      if (orientationName == "portrait") {
        window.screen.orientation.lock("portrait");
      } else {
        window.screen.orientation.lock("landscape");
      }
    }
  }
  const goBack = () => {
    changeFlutterOrientation("portrait");
    navigate(-1);
  };
  useEffect(() => {
    window.onpopstate = () => {
      changeFlutterOrientation("portrait");
    };
  }, []);

  const completeCourse = async () => {
    if (apiCalled.current) return;
    apiCalled.current = true;
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let data = {
      review_type: "What's Next?",
      course_name: chaptername,
    };
    console.log(auth);
    if (auth.accessToken) {
      if (auth.userType === "child") {
        let response = await axios.post(`${URL.reviewComplete}`, data, config);
        console.log(response);
      }
    }
  };

  const getCourses = async () => {
    let res = await axios.get(`course/all`).catch(console.log);
    setCourseList(res.data);
    console.log(res.data);
    setLoading(false);
  };
  const getCurrentCourse = () => {
    let currentCourse = courseList.find(
      (course) => course.name === chaptername
    );
    return currentCourse;
  };

  return (
    loading === false && (
      <div style={{ backgroundColor: "#eef2fe" }}>
        <div
          onClick={goBack}
          style={{ position: "absolute", top: 18, left: 18 }}
        >
          <img src={Close} alt="" />
        </div>
        <div className="boxWrapper">
          <div className="box">
            <img src={getCurrentCourse().thumbnail_link} alt="hehe" />
            <h2>Restart "{chaptername}"</h2>
            <p>Restart the course and earn additional points.</p>
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/coursedetails/${chaptername}`, { replace: true })
              }
            >
              Restart
            </Button>
          </div>
          <div className="box">
            <img
              src={GirlImageNew}
              alt="hehe"
              width="60%"
              style={{ alignSelf: "center" }}
            />
            <h2>Explore</h2>
            <p>Explore other courses and earn additional points.</p>
            <Button
              onClick={() => {
                changeFlutterOrientation("portrait");
                navigate("/");
              }}
              variant="contained"
            >
              <div style={{ display: "flex" }}>
                <div>Learn Next</div>
                <div style={{ marginLeft: 10 }}>➜</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    )
  );
}

export default WhatsNext;
