import { axios } from "../lib/axios";

import useAuth from "./useAuth";

const REFRESH_URL = "/account/token/refresh/";

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get(REFRESH_URL);
    setAuth({
      ...auth,
      accessToken: response.data.access_token
    });
   
    return response.data.access_token;
  };
  return refresh;
};

export default useRefreshToken;
