import { Button, Checkbox, Radio } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLoading from '../../../components/button/ButtonLoading';
import TextBox from '../../../components/textBox/TextBox';
import { URL } from '../../../constants/url';
import useAuth from '../../../hooks/useAuth';
import { axios } from '../../../lib/axios';
import { errorHandler } from '../../../lib/serverHandler';
export default function ChildLogin({ toggle }) {
    const navigate = useNavigate();
    const { setAuth } = useAuth();
    const [childLoginLoading, setChildLoginLoading] = useState(false);

    const [error, setError] = useState({
        username_or_email: '',
        username: '',
        pin: '',
        remember: '',
    });

    const [formData, setFormData] = useState({
        username_or_email: '',
        username: '',
        pin: '',
        remember: false,
    });

    const handleSubmit = async (e) => {
        setChildLoginLoading(true);
        try {
            const response = await axios.post(URL.childLogin, formData);
            if (response.success === true) {
                setAuth({
                    username_or_email: response?.data?.username_or_email,
                    username: response?.data?.username,
                    fullName: response?.data?.full_name,
                    isVerified: response?.data?.is_verified,
                    userType: response?.data?.user_type,
                    accessToken: response?.data?.access_token,
                    profile_picture: response?.data?.profile,
                });
                navigate('/');
            } else {
            }
            setChildLoginLoading(false);
        } catch (err) {
            setChildLoginLoading(false);
            errorHandler(err.response.data.message);
        }
    };
    const validateForm = () => {
        let valid = true;
        let form = {
            username_or_email: '',
            username: '',
            pin: '',
            remember: '',
        };
        if (formData.username_or_email.trim() === '') {
            valid = false;
            form.email = "Guardian's Email or Username Required";
        } else {
            form.email = '';
        }
        if (formData.username.trim() === '') {
            valid = false;
            form.username = 'Username Required';
        } else {
            form.username = '';
        }
        if (formData.pin.trim() === '') {
            valid = false;
            form.pin = 'Pin Cannot be Empty';
        } else {
            setError({ ...error, pin: '' });
        }
        setError(form);
        return valid;
    };

    return (
        <div className='loginForm'>
            <div className='loginFormTop'>
                <h2 style={{ marginTop: 10 }}>Hi There!</h2>
                <p className='loginTopText'>Welcome to Seepalaya. Login to continue.</p>
                <p className='loginTopTextSmall'>* items marked with an asterisk are mandatory</p>
            </div>
            <div className='selection'>
                <div className='select pointer' onClick={toggle}>
                    <div>
                        <Radio />
                    </div>
                    <div>Guardian</div>
                </div>
                <div className='selected pointer'>
                    <div>
                        <Radio
                            sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                    color: 'white',
                                },
                            }}
                            checked={true}
                        />
                    </div>
                    <div>Child</div>
                </div>
            </div>
            <div style={{ marginTop: 20 }}>
                <TextBox
                    icon='mail'
                    placeholder="Guardian's email or username*"
                    error={error.username_or_email}
                    value={formData.username_or_email}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            username_or_email: e.target.value,
                        })
                    }
                />
                <TextBox
                    icon='username'
                    placeholder='Username*'
                    error={error.username}
                    value={formData.username}
                    onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                />
                <TextBox
                    icon='lock'
                    placeholder='Pin*'
                    protected
                    error={error.pin}
                    value={formData.pin}
                    onChange={(e) => setFormData({ ...formData, pin: e.target.value })}
                />
            </div>

            {/* <div className="confirmationDiv">
        <div>
          <Checkbox
            checked={formData.remember}
            onChange={() =>
              setFormData({
                ...formData,
                remember: !formData.remember,
              })
            }
          />
        </div>
        <div style={{ marginBottom: 2 }}>
          <p>Remember me</p>
        </div>
      </div>
      {error.remember.length !== 0 && (
        <div
          style={{
            fontSize: 12,
            color: "red",
            marginTop: 5,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {error.remember}
        </div>
      )} */}
            <div>
                <ButtonLoading
                    buttonStyles={{ width: '100%', marginTop: 20 }}
                    varient='contained'
                    clickEvent={() => {
                        if (validateForm()) handleSubmit();
                    }}
                    initialText='Login'
                    loadingTruth={childLoginLoading}
                />
            </div>
        </div>
    );
}
