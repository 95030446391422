import React from "react";
import {
  BackColored,
  BadgeOne,
  BadgeThree,
  BadgeTwo,
  CoinIcon,
} from "../../assets/commonicons/CommonIcons";

import "./badges.css";

export default function BadgePointPage({ clickHandleFun }) {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="commonTopHeader fixed-flex-height"
        style={{
          background: "white",
        }}
      >
        <img onClick={clickHandleFun} src={BackColored} alt="back-icon" />
        <h1 className="badges-intro">Badges and Points</h1>
        <div></div>
      </div>
      <div className="d-flex flex-column filled-flex-height">
        <div
          className="screenCenter filled-flex-height flex-column d-flex badges-height"
          style={{
            margin: "0 auto",
          }}
        >
          <section className="d-flex badges-sec">
            <div className="content">
              <h2>What are badges and points?</h2>
              <p>
                For each course, badges are awarded to the learners for
                achieving a specific level of score in the Course Assessment
                section. You can view the badges earned by the Child user in the
                "Badges'' section within the Child's profile page.
              </p>
              <p>
                These badges are a great way to motivate yourself to keep
                learning and engaging with the app. Celebrate your achievements
                with the badges you have earned and show them off to your
                friends, teachers and family. Keep learning and having fun, and
                you will earn different types of badges in no time!
              </p>
            </div>
            <figure className="badge-groups">
              <div className="flex-holder">
                <img src={CoinIcon} alt="" className="first-col" />
              </div>
              <div className="flex-holder">
                <img src={BadgeOne} alt="" />
              </div>
              <div className="flex-holder">
                <img src={BadgeTwo} alt="" />
              </div>
              <div className="flex-holder">
                <img src={BadgeThree} alt="" />
              </div>
            </figure>
          </section>
          {/* Next */}
          <section className="d-flex badges-full">
            <div className="content">
              <h2>Type of badges and their meanings</h2>
              <p>
                There are many different badges to earn, each with its own
                specific requirements. So, there's always something to work
                towards as you progress through the app.
              </p>
            </div>
            <div className="badges-group">
              <figure>
                <img src={BadgeOne} alt="" />
                <figcaption>Snow Leopard badge</figcaption>
              </figure>
              <figure>
                <img src={BadgeTwo} alt="" />
                <figcaption>Red Panda badge</figcaption>
              </figure>
              <figure>
                <img src={BadgeThree} alt="" />
                <figcaption>One-horned Rhino badge</figcaption>
              </figure>
            </div>
          </section>
          {/* Next */}
          <section className="d-flex badges-sec">
            <div className="content">
              <h2>How can you earn Snow Leopard badge?</h2>
              <p>
                Score at least 80% in the Course Assessment section to earn this
                highest level badge.
              </p>
            </div>
            <figure>
              <img src={BadgeOne} alt="" />
            </figure>
          </section>
          {/* Next */}
          <section className="d-flex badges-sec">
            <figure>
              <img src={BadgeTwo} alt="" />
            </figure>
            <div className="content">
              <h2>How can you earn Red Panda badge?</h2>
              <p>
                If your score is more than or equal to 60% and less than 80%,
                you will earn a Red Panda badge.
              </p>
            </div>
          </section>
          {/* Next */}
          <section className="d-flex badges-sec">
            <div className="content">
              <h2>How can you earn One-horned Rhino badge?</h2>
              <p>
                If your score is more than or equal to 30% and less than 60%,
                you will earn a One-horned Rhino badge. If you score less than
                30% any time, you won't earn any badge, but you should keep
                working hard and you might earn one in the future!
              </p>
            </div>
            <figure>
              <img src={BadgeThree} alt="" />
            </figure>
          </section>
          {/* Next */}
          <section className="d-flex badges-sec">
            <div className="content">
              <h2>How can you earn Points?</h2>
              <p>
                Points will be awarded for completing certain tasks or
                activities within the app, such as watching a video lesson,
                completing a game, E-Paath, lesson review, pre-assessment,
                course assessment or any other types of unit. The amount of
                points you collect from each unit may differ in each course. You
                can collect the points in the course, track your progress and
                share your progress with your peers and guardians. Collect the
                points and continue using the app and working towards new goals!
              </p>
            </div>
            <figure>
              <img src={CoinIcon} alt="" />
            </figure>
          </section>
        </div>
      </div>
      {/* <FooterNew /> */}
    </div>
  );
}
