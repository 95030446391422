import SoundPlayer from ".././tring.ogg";
import GirlImage from "../images/girl_image.png";
import HomeBanner from "../images/home_banner.svg";
import BannerSlogan from "./banner_image.png";
import GirlImageNew from "./girl_image_new.png";
import Logo from "./logo.png";
import MobileHomeBanner from "./mobile_banner.svg";
import IllustrationImage from "./progress_phone_illustration.png";

export { HomeBanner, GirlImage, MobileHomeBanner, Logo, GirlImageNew, IllustrationImage, BannerSlogan, SoundPlayer };
