import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./coursesslider.css";

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";

function CourseSlider() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = async () => {
    let res = await axios.get(`course/all`).catch(console.log);
    setCourseList(res.data);
    setLoading(false);
  };
  const handleCourseClick = async (name) => {
    if (auth.accessToken) {
      if (auth.userType === "child") {
        let names = [];
        names.push(name);
        let token = auth.accessToken;
        let config = { headers: { Authorization: `Bearer ${token}` } };
        let data = { course_names: names };
        let response = await axios
          .post(URL.addBookmark, data, config)
          .catch(console.log);
      }
    }
    navigate(`/coursedetails/${name}`);
  };

  return (
    <div>
      <h1 style={{ marginBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        All Courses
      </h1>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        {loading == false ? (
          <Slider {...settings}>
            {courseList.map((item, i) => (
              <>
                <div className="courseCard" key={i}>
                  <div className="pointer">
                    <img
                      onClick={() => handleCourseClick(item.name)}
                      src={item.thumbnail_link}
                      alt={item.name}
                      width="100%"
                    />
                  </div>
                  <div className="courseBottom">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <h2 style={{ marginBottom: 10 }}>{item.name}</h2>
                      <p>{item.subject}</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => handleCourseClick(item.name)}
                        variant="contained"
                      >
                        Let's Go
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </Slider>
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress size={30} />
            <h3 style={{ marginTop: 20 }}>Loading Courses...</h3>
          </div>
        )}
      </div>
    </div>
  );
}

export default CourseSlider;
