import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axios } from "../../../lib/axios";
import { Back } from "../../../assets/commonicons/CommonIcons";
import TextBox from "../../../components/textBox/TextBox";
import { Logo } from "../../../assets/images";
// css
import "./resetpassword.css";
import { errorHandler, successHandler } from "../../../lib/serverHandler";
import ButtonLoading from "../../../components/button/ButtonLoading";
import {
  addInfoHandler,
  passwordValidator,
  removeInfoHandler,
} from "../../../lib/formValidation";
import PasswordModal from "../../../components/modal/PasswordModal";

const ResetPassword = () => {
  useEffect(() => {
    document.title = "Create new password - Seepalaya";
  }, []);
  const navigate = useNavigate();
  const [validTruth, setValidTruth] = useState(false);
  const [error, setError] = useState({
    username: "",
    token: "",
    password: "",
    confirmPassword: "",
  });
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [isPasswordInfo, setPasswordInfo] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    token: "",
    password: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  let { userID, tokenKey } = useParams();

  useEffect(() => {
    let modal = document.querySelector(".overlay");
    window.onclick = function (event) {
      if (event.target === modal) {
        setPasswordInfo(false);
      }
    };
  }, [isPasswordInfo]);

  useEffect(() => {
    setFormData({ ...formData, username: userID, token: tokenKey });
    setValidTruth(Object.values(error).every((e) => e.length === 0));
  }, [formData, validTruth, error]);

  const infoPasswordHandler = () => addInfoHandler(setPasswordInfo);
  const onPasswordInfoClose = () => removeInfoHandler(setPasswordInfo);

  const passwordValidation = (value) => {
    passwordValidator(value, setError, error);
  };

  const handleSubmit = async (e) => {
    if (validTruth === false) return false;
    setResetPasswordLoading(true);
    try {
      const response = await axios.post("/account/reset-password/", formData);
      if (response.success === true)
        successHandler("Password has been successfully reset");
      setResetPasswordLoading(false);
      navigate("/login");
    } catch (error) {
      setResetPasswordLoading(false);
      errorHandler(error.response.data.message);
    }
  };
  const validateForm = () => {
    let valid = true;
    let form = {
      password: "",
      confirmPassword: "",
    };
    if (formData.password.trim() === "") {
      valid = false;
      form.password = "Password Cannot be Empty";
    }
    if (formData.confirmPassword === "") {
      valid = false;
      form.confirmPassword = "Confirm Password cannot be Empty";
    } else {
      if (formData.password.trim() !== confirmPassword) {
        valid = false;
        form.confirmPassword = "Passwords don't match";
      }
    }

    validTruth === true && setError(form);
    return valid;
  };

  return (
    <>
      <div className="resetContainer">
        <div className="resetHeader">
          <img onClick={() => navigate(-1)} src={Back} alt="back-icon" />
          <h1 onClick={() => navigate("/")} className="pointer">
            <img src={Logo} alt="Sipalya" />
          </h1>
          <div></div>
        </div>
        <div className="resetCenter">
          <div className="resetForm">
            <div className="resetFormTop">
              <h2 style={{ marginTop: 10 }}>Create new Password</h2>
              <p className="resetTopText">
                Your used password must be different from previous used
                password.
              </p>
              <p className="signUpTopTextSmall">
                * items marked with an asterisk are mandatory
              </p>
            </div>
            <div style={{ marginTop: 50 }}>
              <TextBox
                icon="lock"
                placeholder="Password"
                infoHandler={infoPasswordHandler}
                protected
                error={error.password}
                value={formData.password}
                onChange={(e) => {
                  passwordValidation(e.target.value);
                  setFormData({ ...formData, password: e.target.value });
                }}
              />
              <TextBox
                icon="lock"
                placeholder="Confirm Password"
                protected
                error={error.confirmPassword}
                value={formData.confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <div>
              <ButtonLoading
                buttonStyles={{ width: "100%", marginTop: 20 }}
                varient="contained"
                clickEvent={() => {
                  if (validateForm()) handleSubmit();
                }}
                initialText="Reset"
                loadingTruth={resetPasswordLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <PasswordModal
        isPasswordInfo={isPasswordInfo}
        onPasswordInfoClose={onPasswordInfoClose}
      />
    </>
  );
};

export default ResetPassword;
