import React, { useState, useEffect } from "react";
import TapeGenerator from "../../../../components/fractionsHelper/Tape";
export default function FractionLesson1Question({ currentQuestion }) {
  const renderQuestion = () => {
    if (currentQuestion.level == 1) {
      return (
        <div className="singleBox">
          <div>{currentQuestion.question}</div>
          <TapeGenerator
            small
            numerator={currentQuestion.numerator[0]}
            denominator={currentQuestion.denominator}
          />
        </div>
      );
    }
    if (currentQuestion.level == 2) {
      return (
        <div className="singleBox">
          <div>{currentQuestion.question}</div>
          <TapeGenerator
            small
            numerator={currentQuestion.numerator[0]}
            denominator={currentQuestion.denominator}
          />
        </div>
      );
    }
    // if (currentQuestion.level == 3) {
    //   return (
    //     <div className="singleBox">
    //       <TapeGenerator
    //         small
    //         numerator={currentQuestion.numerator[0]}
    //         denominator={currentQuestion.denominator}
    //       />
    //     </div>
    //   );
    // }
    if (currentQuestion.level == 3) {
      return (
        <div className="multiBox">
          <div className="singleBox">
            <div>
              {currentQuestion.numerator[0]}/{currentQuestion.denominator}
            </div>
            <TapeGenerator
              numerator={currentQuestion.numerator[0]}
              denominator={currentQuestion.denominator}
            />
          </div>
          <div className="comparisionBox">?</div>
          <div className="singleBox">
            <div>
              {currentQuestion.numerator[1]}/{currentQuestion.denominator}
            </div>
            <TapeGenerator
              numerator={currentQuestion.numerator[1]}
              denominator={currentQuestion.denominator}
            />
          </div>
        </div>
      );
    }
    // if (currentQuestion.level == 5) {
    //   return (
    //     <div className="multiBox">
    //       <div className="singleBox">
    //         <div>
    //           {currentQuestion.numerator[0]}/{currentQuestion.denominator}
    //         </div>
    //       </div>
    //       <div className="comparisionBox">?</div>
    //       <div className="singleBox">
    //         <div>
    //           {currentQuestion.numerator[1]}/{currentQuestion.denominator}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    if (currentQuestion.level == 4) {
      return (
        <div className="multiBox">
          <div className="singleBox">
            <div>
              {currentQuestion.numerator[0]}/{currentQuestion.denominator}
            </div>
            <TapeGenerator
              numerator={currentQuestion.numerator[0]}
              denominator={currentQuestion.denominator}
            />
          </div>
          <div className="comparisionBox">+</div>
          <div className="singleBox">
            <div>
              {currentQuestion.numerator[1]}/{currentQuestion.denominator}
            </div>
            <TapeGenerator
              numerator={currentQuestion.numerator[1]}
              denominator={currentQuestion.denominator}
            />
          </div>
        </div>
      );
    }
    // if (currentQuestion.level == 7) {
    //   return (
    //     <div className="multiBox">
    //       <div className="singleBox">
    //         <div>
    //           {currentQuestion.numerator[0]}/{currentQuestion.denominator}
    //         </div>
    //       </div>
    //       <div className="comparisionBox">+</div>
    //       <div className="singleBox">
    //         <div>
    //           {currentQuestion.numerator[1]}/{currentQuestion.denominator}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    if (currentQuestion.level == 5) {
      return (
        <div className="multiBox">
          <div className="singleBox">
            <div>
              {currentQuestion.numerator[0]}/{currentQuestion.denominator}
            </div>
            <TapeGenerator
              numerator={currentQuestion.numerator[0]}
              denominator={currentQuestion.denominator}
            />
          </div>
          <div className="comparisionBox">-</div>
          <div className="singleBox">
            <div>
              {currentQuestion.numerator[1]}/{currentQuestion.denominator}
            </div>
            <TapeGenerator
              numerator={currentQuestion.numerator[1]}
              denominator={currentQuestion.denominator}
            />
          </div>
        </div>
      );
    }
    // if (currentQuestion.level == 9) {
    //   return (
    //     <div className="multiBox">
    //       <div className="singleBox">
    //         <div>
    //           {currentQuestion.numerator[0]}/{currentQuestion.denominator}
    //         </div>
    //       </div>
    //       <div className="comparisionBox">-</div>
    //       <div className="singleBox">
    //         <div>
    //           {currentQuestion.numerator[1]}/{currentQuestion.denominator}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
  };
  return renderQuestion();
}
