import React from "react";
import "./tape.css";

const TapeGenerator = ({ numerator, denominator, small, fb }) => {
  let loopNum = (number) => {
    let arr = [];
    for (let i = 1; i <= number; i++) {
      arr.push(<div className="numerator" key={i}></div>);
    }
    return arr;
  };
  let loopDen = (number) => {
    let arr = [];
    for (let i = 1; i <= number; i++) {
      arr.push(<div className="denominator" key={i}></div>);
    }
    return arr;
  };

  return fb ? (
    <div className={"fbTape"}>
      {loopNum(numerator)}
      {loopDen(parseInt(denominator) - parseInt(numerator))}
    </div>
  ) : (
    <div className={small ? "tape" : "bigTape"}>
      {loopNum(numerator)}
      {loopDen(parseInt(denominator) - parseInt(numerator))}
    </div>
  );
};
export default TapeGenerator;
