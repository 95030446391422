import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Mascot } from "../../assets/commonicons/CommonIcons";
import "./coursesslider.css";

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";

function MyCoursesSlider() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = async () => {
    let { accessToken } = auth;
    let config = { headers: { Authorization: `Bearer ${accessToken}` } };
    if (auth.accessToken) {
      if (auth.userType === "child") {
        let res = await axios.get(URL.getBookmarks, config).catch(console.log);
        setCourseList(res.data);
        console.log(res.data);
        setLoading(false);
      }
    }
  };
  const handleCourseClick = async (name) => {
    navigate(`/coursedetails/${name}`);
  };

  return (
    courseList.length !== 0 && (
      <div style={{ marginBottom: 50 }}>
        <h1 style={{ marginBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
          My Courses
        </h1>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          {loading == false ? (
            courseList.length === 0 ? (
              <div className="emptyListWrapper">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <img src={Mascot} height={40} width={40} alt="" />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <h3>No Courses</h3>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <p>No course found {":<"}</p>
                    {/* <p>tap on "Add Courses" button</p> */}
                  </div>
                </div>
              </div>
            ) : (
              <Slider {...settings}>
                {courseList.map((item, i) => (
                  <>
                    <div className="courseCard" key={i}>
                      <div>
                        <img
                          src={item.thumbnail_link}
                          alt={item.name}
                          width="100%"
                        />
                      </div>
                      <div className="courseBottom">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <h2 style={{ marginBottom: 10 }}>
                            {item.course_name}
                          </h2>
                        </div>
                        <div>
                          <Button
                            onClick={() => handleCourseClick(item.course_name)}
                            variant="contained"
                          >
                            Let's Go
                          </Button>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <c>{item.subject}</c>
                        <c>
                          Progress:{" "}
                          {item?.progress === null ? 0 : item?.progress}%
                        </c>
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
            )
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress size={30} />
              <h3 style={{ marginTop: 20 }}>Loading Courses...</h3>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default MyCoursesSlider;
