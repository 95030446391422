import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { axios } from "../../../lib/axios";
import { alpha, Button } from "@mui/material";
import { Back } from "../../../assets/commonicons/CommonIcons";
import OtpInput from "react18-input-otp";
import "./otp.css";
import { useEffect } from "react";
import { URL } from "../../../constants/url";
import { errorHandler, successHandler } from "../../../lib/serverHandler";
import ButtonLoading from "../../../components/button/ButtonLoading";

export default function ForgotOtp({ toggle }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [errorMsg, setErrorMsg] = useState("");
  const [otpVerifyLoading, setOtpVerifyLoading] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  const [otp, setOtp] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const handleSubmit = async () => {
    if (otp === "") {
      setErrorMsg("Please enter the OTP code");
      return false;
    } else {
      setErrorMsg("");
    }
    setOtpVerifyLoading(true);
    try {
      const response = await axios.post(
        URL.verify,
        { otp: +otp },
        { headers: { Authorization: `Bearer ${auth.accessToken}` } }
      );
      successHandler("Signed up successfully!");
      setOtpVerifyLoading(false);
      if (response.success === true) navigate("/");
    } catch (error) {
      setOtpVerifyLoading(false);
      setOtp("");
      errorHandler(error.response.data.message);
      setMinutes(0);
      setSeconds(0);
    }
  };

  const validateForm = () => {
    let valid = true;

    return valid;
  };

  const handleResendToken = async () => {
    try {
      axios.post(URL.resendOtp, null, {
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      });
    } catch (error) {
      console.log(error);
    }
    setErrorMsg("");
    setMinutes(5);
    setSeconds(0);
  };

  const handleOtpChange = (value) => {
    setOtp(value);
    console.log(value, otp);
  };

  return (
    <div className="forgotOtpContainer">
      <div className="forgotOtpHeader">
        <img onClick={() => navigate(-1)} src={Back} alt="back-icon" />
        <div className="forgotOtpHeading">Course Design</div>
        <div></div>
      </div>
      <div className="forgotOtpCenter">
        <div className="forgotOtpForm">
          <div className="forgotOtpFormTop">
            <h2 style={{ marginTop: 10 }}>Verify Email</h2>
            <p className="forgotOtpTopText">
              An OTP has been sent to your email address. Please enter the code
              and click on verify button below.
            </p>
          </div>
          <div class="otpInputHolder">
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              isInputNum
              numInputs={6}
              separator={<span></span>}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              textAlign: "center",
              color: "#ccc",
              fontSize: "14px",
            }}
          >
            <p style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}>
              {errorMsg}
            </p>
            <div className="countdown-text">
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                ""
              )}
            </div>
            Didn't receive the code?{" "}
            <span
              className="pointer"
              style={{ color: "#0477BF" }}
              onClick={handleResendToken}
            >
              Resend
            </span>
          </div>
          <div style={{ textAlign: "center" }}>
            <ButtonLoading
              buttonStyles={{
                display: "inline-block",
                padding: "5px 25px",
                borderRadius: "10px",
                textTransform: "capitalize",
                width: "auto",
                marginTop: 20,
              }}
              varient="contained"
              clickEvent={() => {
                if (validateForm()) handleSubmit();
              }}
              initialText="Verify"
              loadingTruth={otpVerifyLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
