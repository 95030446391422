import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Back } from "../../assets/commonicons/CommonIcons";
import ButtonLoading from "../../components/button/ButtonLoading";
import TextBox from "../../components/textBox/TextBox";
import { ERROR_MSG } from "../../constants/ErrorMessage";
import { URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";
import { errorHandler } from "../../lib/serverHandler";

//css
import "./parent.css";

const ParentInfo = () => {
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
  });

  const [validTruth, setValidTruth] = useState(false);
  const [parentUpdateLoading, setParentUpdateLoading] = useState(false);
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    document.title = `My Profile - ${auth.username}`;
  }, []);

  const [nonFormData, setNonFormData] = useState({
    firstName: "",
    lastName: "",
  });

  const { fullName, username } = auth;
  const firstLetterName = fullName?.charAt(0);
  const [firstName, lastName] = fullName?.split(" ");

  const changeDataHandler = (fieldName, value, errorText) => {
    if (value.length === 0) {
      switch (fieldName) {
        case "firstName":
          setError({ ...error, firstName: errorText });
          break;
        case "lastName":
          setError({ ...error, lastName: errorText });
          break;
        default:
          break;
      }
    } else {
      switch (fieldName) {
        case "firstName":
          setError({ ...error, firstName: "" });
          break;
        case "lastName":
          setError({ ...error, lastName: "" });
          break;
        default:
          break;
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    let form = {
      firstName: "",
      lastName: "",
    };
    if (nonFormData.firstName === "") {
      valid = false;
      form.firstName = ERROR_MSG.emptyFirstName;
    }
    if (nonFormData.lastName === "") {
      valid = false;
      form.lastName = ERROR_MSG.emptyLastName;
    }

    validTruth === true && setError(form);
    return valid;
  };

  useEffect(() => {
    setValidTruth(Object.values(error).every((e) => e.length === 0));
  }, [error, validTruth]);

  useEffect(() => {
    setNonFormData({
      firstName,
      lastName,
    });
  }, []);

  const handleSubmit = async (e) => {
    if (validTruth === false) return false;

    setParentUpdateLoading(true);
    try {
      const response = await axios.post(
        URL.updateUser,
        {
          ...nonFormData,
          first_name: nonFormData.firstName,
          last_name: nonFormData.lastName,
        },
        {
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      );

      console.log(response);

      setParentUpdateLoading(false);

      navigate("/");
      setAuth({
        ...auth,
        fullName: response.data?.full_name,
      });
    } catch (err) {
      errorHandler(err?.response?.data?.message);
      setParentUpdateLoading(false);
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div className="commonTopHeader fixed-flex-height">
        <img onClick={() => navigate("/")} src={Back} alt="back-icon" />
        <div></div>
      </div>
      <div className="d-flex flex-column filled-flex-height">
        <div
          className="coursesListWrapper"
          style={{
            background: "#fff",
            paddingTop: 20,
          }}
        >
          <div className="courseListConatiner">
            <div
              style={{
                padding: "30px 35px",
              }}
              className="topHolder d-flex align-center fixed-flex-height"
            >
              <figure class="firstLetterParent alignLeft">
                <figcaption>{firstLetterName}</figcaption>
              </figure>
              <div className="profileInfo">
                <strong>{fullName}</strong>
                <p>Username: {username}</p>
              </div>
            </div>
            <form className="commonForm parentInfoForm filled-flex-height">
              <div className="commonFormTop">
                <h2 style={{ marginTop: 10 }}>Account Information</h2>
              </div>
              <div style={{ marginTop: 20 }}>
                <TextBox
                  icon="name"
                  placeholder="First Name*"
                  error={error.firstName}
                  value={nonFormData?.firstName}
                  onChange={(e) => {
                    changeDataHandler(
                      "firstName",
                      e.target.value,
                      "First Name required"
                    );
                    setNonFormData({
                      ...nonFormData,
                      firstName: e.target.value,
                    });
                  }}
                />
                <TextBox
                  icon="name"
                  placeholder="Last Name*"
                  error={error.lastName}
                  value={nonFormData?.lastName}
                  onChange={(e) => {
                    changeDataHandler(
                      "lastName",
                      e.target.value,
                      ERROR_MSG.emptyLastName
                    );
                    setNonFormData({
                      ...nonFormData,
                      lastName: e.target.value,
                    });
                  }}
                />
                <TextBox
                  icon="username"
                  placeholder="Username*"
                  value={auth.username}
                  disabled
                />

                <TextBox
                  icon="mail"
                  placeholder="Email"
                  value={auth.email}
                  disabled
                />
              </div>

              <div
                style={{
                  marginTop: 60,
                }}
              >
                <ButtonLoading
                  varient="contained"
                  clickEvent={() => {
                    if (validateForm()) handleSubmit();
                  }}
                  initialText="Save Changes"
                  loadingTruth={parentUpdateLoading}
                />
                <Button
                  style={{
                    borderColor: "#ccc",
                    marginLeft: 20,
                  }}
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <ButtonLoading
                  varient="contained"
                  clickEvent={() => navigate("/dashboard/change-password")}
                  initialText="Change Password"
                  loadingTruth={parentUpdateLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentInfo;
