import { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import { isExpired } from "react-jwt";
import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useRefreshToken from "../../hooks/useRefreshToken";
import useUserDetails from "../../hooks/useUserDetails";

const PersistAuth = () => {
  const [loadingRefresh, setLoadingRefresh] = useState(true);
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);

  const refresh = useRefreshToken();
  const updateUserDetails = useUserDetails();

  const { auth } = useAuth();
  const tokenExpired = isExpired(auth.accessToken);

  useEffect(() => {
    let isMounted = true;
    console.log("running the frist use effect");
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.log(err);
      } finally {
        isMounted && setLoadingRefresh(false);
        console.log(auth);
      }
    };

    !auth.accessToken
      ? tokenExpired && verifyRefreshToken()
      : setLoadingRefresh(false);

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    console.log("Running the second useEffect");
    console.log(JSON.stringify(auth));
    if (!loadingRefresh) {
      const getUserData = async () => {
        try {
          await updateUserDetails();
        } catch (err) {
          console.log(err);
        } finally {
          isMounted && setLoadingUserDetails(false);
        }
      };

      !auth.username ? getUserData() : setLoadingUserDetails(false);
    }

    return () => (isMounted = false);
  }, [loadingRefresh]);

  useEffect(() => {
    console.log(auth?.username);
  }, [loadingUserDetails]);

  return (
    <>
      {loadingUserDetails ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
          <h3 style={{ marginTop: 20 }}>Loading...</h3>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistAuth;
