import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Back } from "../../../assets/commonicons/CommonIcons";
import ChildLogin from "./ChildLogin";
import ParentLogin from "./ParentLogin";

// css
import { Logo } from "../../../assets/images";
import "./loginpage.css";

const LoginPage = () => {
  const navigate = useNavigate();
  const [select, setSelect] = useState(false);
  const toggle = () => {
    setSelect(!select);
  };
  useEffect(() => {
    document.title = "Login - Seepalaya!";
  }, []);
  return (
    <>
      <div className="loginContainer">
        <div className="loginHeader">
          <img onClick={() => navigate(-1)} src={Back} alt="back-icon" />
          <div className="loginHeading" style={{ margin: "-10px 0" }}>
            <h1 className="pointer" onClick={() => navigate("/")}>
              <img src={Logo} alt="Sipalya" />
            </h1>
          </div>
          <div></div>
        </div>
        <div className="loginCenter">
          <>
            {select === false ? (
              <ParentLogin toggle={toggle} />
            ) : (
              <ChildLogin toggle={toggle} />
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
