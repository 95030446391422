import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MenuContext from "../context/MenuContext";
import useAuth from "../hooks/useAuth";
import { axios } from "../lib/axios";

const MenuComponent = () => {
  const { auth, setAuth } = useAuth();
  const { menuOptions, defineOptions, setOpenDrawer } = useContext(MenuContext);
  const navigate = useNavigate();

  const handleLogOut = async () => {
    let token = auth?.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response = await axios
      .delete(`account/user/logout/`, config)
      .catch((er) => console.log(er));
    if (response.success === true) {
      setAuth({});
    }
    navigate("/");
  };
  const handleNavigation = (n) => {
    if (n === "logout") {
      handleLogOut();
      defineOptions();
    } else {
      navigate(n);
      setOpenDrawer(false);
    }
  };

  return (
    <div>
      {menuOptions.map((item, index) => {
        return (
          <div
            key={index}
            className="itemWrapper"
            onClick={() => handleNavigation(item.navigate)}
          >
            <div className="item">
              {/* <div
                        style={{
                          marginRight: 20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.icon}
                      </div> */}
              <div>{item.text}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MenuComponent;
