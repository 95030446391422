
export const addInfoHandler = ( updateFn ) => {
  document.body.classList.add( "modal-open" );
  updateFn( true );
};

export const removeInfoHandler = ( updateFn ) => {
  document.body.classList.remove( "modal-open" );
  updateFn( false );
};

export const passwordValidator = ( value, updateFn, updateVal ) => {
  let characterCase = "(?=.*?[a-z])";
  let digitCase = "(?=.*?[0-9])";
  let specialCase = "(?=.*[@#$%!^&+=])";

  let passw = `^${ characterCase }${ digitCase }${ specialCase }.{8,}$`;
  if ( value.match( passw ) ) {
    updateFn( { ...updateVal, password: "" } );
  } else if ( value?.length === 0 ) {
    updateFn( { ...updateVal, password: "" } );
  } else if ( !value.match( characterCase ) ) {
    updateFn( { ...updateVal, password: "Enter at least a character" } );
  } else if ( !value.match( digitCase ) ) {
    updateFn( { ...updateVal, password: "Enter at least a digit" } );
  } else if ( !value.match( specialCase ) ) {
    updateFn( { ...updateVal, password: "Enter at least a special character" } );
  } else {
    updateFn( { ...updateVal, password: "Enter min 8 characters" } );
  }
  return;
};

export const userValidator = ( value, updateFn, updateVal ) => {
  let alphaNumeric = "(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]";
  let lengthUser = `^.{4,19}$`;

  let passw = `^${ alphaNumeric }{4,19}$`;
  if ( value.match( passw ) ) {
    updateFn( { ...updateVal, username: "" } );
  } else if ( value?.length === 0 ) {
    updateFn( { ...updateVal, username: "" } );
  } else if ( !value.match( alphaNumeric ) ) {
    updateFn( {
      ...updateVal,
      username: "Username should have alpha-numeric values.",
    } );
  } else if ( !value.match( lengthUser ) ) {
    updateFn( {
      ...updateVal,
      username: "Username should be of length between 3 and 20",
    } );
  } else {
    updateFn( {
      ...updateVal,
      username: "Username should have alpha-numeric values.",
    } );
  }
  return;
};

export const emailValidator = ( value, updateFn, updateVal ) => {
  if ( value.match( /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ ) ) {
    updateFn( { ...updateVal, email: "" } );
  } else if ( value?.length === 0 ) {
    updateFn( { ...updateVal, email: "" } );
  } else {
    updateFn( { ...updateVal, email: "Enter a valid email address" } );
  }
  return;
};
