import { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

const MenuContext = createContext( {} );

export const MenuProvider = ( { children } ) => {
  const { auth } = useAuth();
  const [ openDrawer, setOpenDrawer ] = useState( false );
  const [ menuOptions, setMenuOptions ] = useState( [] );
  const [ loading, setLoading ] = useState( true );

  useEffect( () => {
    defineOptions();
    console.log( auth );
  }, [ auth ] );

  const defineOptions = () => {
    if ( Object.keys( auth )?.length !== 0 ) {
      if ( auth.userType === "guardian" ) {
        let arr = [
          // { icon: <HomeOutlinedIcon />, text: "Home", navigate: "/" },
          {
            icon: <HomeOutlinedIcon />,
            text: "My Child",
            navigate: "/create-child",
          },
          {
            icon: <HomeOutlinedIcon />,
            text: "My Profile",
            navigate: "/profile",
          },
          // { icon: <DescriptionOutlinedIcon />, text: "About Us", navigate: "" },
          // {
          //   icon: <LocalPhoneOutlinedIcon />,
          //   text: "Contact Us",
          //   navigate: "",
          // },
          // { icon: <ShareOutlinedIcon />, text: "Share App", navigate: "" },
          // {
          //   icon: <PrivacyTipOutlinedIcon />,
          //   text: "Privacy Policy",
          //   navigate: "",
          // },
          { icon: <LogoutIcon />, text: "Log Out", navigate: "logout" },
        ];
        setMenuOptions( arr );
      } else {
        let arr = [
          // { icon: <HomeOutlinedIcon />, text: "Home", navigate: "/" },
          {
            icon: <DescriptionOutlinedIcon />,
            text: "My Courses",
            navigate: "/dashboard/child/my-courses",
          },
          {
            icon: <LocalPhoneOutlinedIcon />,
            text: "My Profile",
            navigate: "/dashboard/child/my-profile",
          },
          // { icon: <ShareOutlinedIcon />, text: "About Us", navigate: "" },
          // { icon: <ShareOutlinedIcon />, text: "Contact Us", navigate: "" },
          // { icon: <ShareOutlinedIcon />, text: "Share App", navigate: "" },
          // {
          //   icon: <PrivacyTipOutlinedIcon />,
          //   text: "Privacy Policy",
          //   navigate: "",
          // },
          { icon: <LogoutIcon />, text: "Log Out", navigate: "logout" },
        ];
        setMenuOptions( arr );
      }
    } else {
      let arr = [
        // { icon: <HomeOutlinedIcon />, text: "Home", navigate: "/" },

        // { icon: <DescriptionOutlinedIcon />, text: "About Us", navigate: "" },
        // { icon: <LocalPhoneOutlinedIcon />, text: "Contact Us", navigate: "" },
        // { icon: <ShareOutlinedIcon />, text: "Share App", navigate: "" },
        // {
        //   icon: <PrivacyTipOutlinedIcon />,
        //   text: "Privacy Policy",
        //   navigate: "",
        // },
        {
          icon: <PersonOutlineOutlinedIcon />,
          text: "Login",
          navigate: "/login",
        },
        {
          icon: <PersonAddAltOutlinedIcon />,
          text: "Sign Up",
          navigate: "/register",
        },
      ];
      setMenuOptions( arr );
    }

    setLoading( false );
  };

  return (
    <MenuContext.Provider
      value={{
        menuOptions,
        setMenuOptions,
        openDrawer,
        setOpenDrawer,
        loading,
        setLoading,
        defineOptions,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
