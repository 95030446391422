import React, { useEffect, useState } from "react";
import Header from "../../components/topbar/header/Header";
import { Button, CircularProgress, Popper } from "@mui/material";
// import Modal from "react-modal";
import { Modal } from "../../components/modal/Modal";
import { RewardCoin } from "../../assets/commonicons/CommonIcons";
import { useParams } from "react-router";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";
import { URL } from "../../constants/url";
import { Mascot } from "../../assets/commonicons/CommonIcons";
import { useNavigate } from "react-router";
import ProgressBar from "../../components/progressbar/ProgressBar";
import { Checkbox } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { createStyles } from "@mui/material";
import Box from "@mui/material/Box";
import { Back } from "../../assets/commonicons/CommonIcons";
import { Eye, EyeClose } from "../../assets/textboxicons/TextBoxIcons";

import { DeleteIcon } from "../../assets/commonicons/CommonIcons";
import ReactModal from "react-modal";

const ViewChild = (props) => {
  const { childId } = useParams();
  const { auth } = useAuth();
  const [child, setChild] = useState([]);
  const [childCourses, setChildCourses] = useState([]);
  const [childBadges, setChildBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [scores, setScores] = useState([]);
  const [remainingCourses, setRemainingCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `View Child - ${childId}`;
  }, []);

  useEffect(() => {
    getChildDetails();
  }, []);

  const toggleCourseModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleCheckboxChange = (index) => {
    let temp = remainingCourses;
    temp[index].selected = !temp[index].selected;
    setRemainingCourses([...temp]);
  };
  const addButtonHandler = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let temp = [];
    remainingCourses.map((item) => {
      if (item.selected === true) {
        temp.push(item.name);
      }
    });
    let data = { course_names: temp, child_username: childId };
    if (temp.length !== 0) {
      let response = await axios
        .post(`${URL.createChildBookmark}`, data, config)
        .catch((er) => console.log(er));
      if (response.success === true) {
        setLoading(true);
        getChildDetails();
        toggleModal();
      }
    }
  };
  const getChildDetails = async () => {
    let { accessToken } = auth;
    let config = { headers: { Authorization: `Bearer ${accessToken}` } };
    let response = await axios.get(
      `${URL.viewChild}?child_username=${childId}`,
      config
    );
    if (response.success === true) {
      console.log(response.data);
      setChild(response.data);
    }
    let res1 = await axios.get(
      `${URL.childBookmarks}?child_username=${childId}`,
      config
    );
    if (res1.success === true) {
      console.log(res1.data);
      setChildCourses(res1.data);
    }
    let res2 = await axios.get(
      `${URL.childBadges}?child_username=${childId}`,
      config
    );
    if (res2.success === true) {
      console.log(res2.data);
      setChildBadges(res2.data);
    }
    let res3 = await axios.get(
      `${URL.childAssessmentLogs}?child_username=${childId}`,
      config
    );
    if (res3.success === true) {
      console.log(res3.data);
      setScores(res3.data);
      setLoading(false);
    }
    let res4 = await axios.get(
      `${URL.childRemainingBookmarks}?child_username=${childId}`,
      config
    );
    if (res4.success === true) {
      console.log(res4.data);
      setRemainingCourses(res4.data);
    }
  };
  const [pinVisible, setPinVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  let modalStyles = {
    overlay: {
      position: "fixed",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      height: "80%",
      width: "100%",
      borderRadius: 15,
      top: "22%",
      justifyContent: "center",
      backgroundColor: "#f5f7fa",
      overflow: "none",
      zIndex: 1000,
    },
  };

  return loading === false ? (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
      }}
    >
      <Header />
      <div className="coursesWrapper">
        <div className="screenCenter">
          <img
            src={Back}
            className="pointer"
            alt=""
            style={{ marginBottom: 20 }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="profileHead web-wrapper">
            <div>
              {/* <img src="" alt="" /> */}
              <div className="profilePicture">
                <img
                  className="profilePictureImage"
                  src={child.profile_picture}
                  alt=""
                />
              </div>
            </div>
            <div style={{ marginLeft: 50 }}>
              <div>
                <h2>{child.full_name}</h2>
              </div>
              <div>
                <p>Username: {child.username}</p>
              </div>{" "}
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>PIN number: {pinVisible === false ? "XXXXXX" : child.pin}</p>
                <div
                  onClick={() => setPinVisible(!pinVisible)}
                  style={{ marginLeft: 15, marginTop: 5, cursor: "pointer" }}
                >
                  {pinVisible === false ? (
                    <img src={EyeClose} alt="" />
                  ) : (
                    <img src={Eye} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isVisible={modalVisible}
          onClose={toggleModal}
          className={"custom-modal"}
        >
          <div className="modalContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div style={{ textAlign: "center", marginBottom: 20 }}>
                <h2>Add Courses</h2>
              </div>
              <div style={{ marginBottom: 20 }}>
                <div className="head">
                  <div>
                    <h3>Courses</h3>
                  </div>
                  <div className="addText" onClick={toggleModal}>
                    <span>Select All</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ maxHeight: 400 }}
              className="scrollableThingy noScroll"
            >
              <div className="listWrapper">
                {remainingCourses.map((item, index) => {
                  return (
                    <div className="listItemSmall">
                      <div>
                        <h3>{item.name}</h3>
                      </div>
                      <div>
                        <Checkbox
                          value={item.selected}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ position: "relative" }} className="head">
              <Button variant="outlined" onClick={toggleModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={addButtonHandler}>
                Add
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="coursesListWrapper">
        <div className="courseListConatiner">
          <div className="section">
            <div>
              <h2>{child.full_name.split(" ")[0]}'s Rewards</h2>
            </div>
            <div className="element">
              <div>
                <p>Total Points Earned</p>
              </div>
              <div className="pointBox">
                <img style={{ marginRight: 10 }} src={RewardCoin} alt="" />
                <div>
                  {child.total_points === null ? 0 : child.total_points}
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>{child.full_name.split(" ")[0]}'s Courses</h2>
              <p onClick={toggleCourseModal} style={{ cursor: "pointer" }}>
                + Add Courses
              </p>
            </div>
            {childCourses.length === 0 ? (
              <EmptyState />
            ) : (
              <CourseList data={childCourses} />
            )}
          </div>
          {scores.length !== 0 && (
            <div className="section">
              <div>
                <h2>Scores</h2>
              </div>
              <div className="scoreList">
                {scores.map((course) => {
                  return (
                    <div
                      className="scoreListElement pointer"
                      onClick={() =>
                        navigate(
                          `/dashboard/${childId}/${course.course_name}/log`
                        )
                      }
                    >
                      <div>
                        <p>{course.course_name}</p>
                      </div>
                      <div className="pointBox">
                        <p>
                          {course.user_score === null ? 0 : course.user_score}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {childBadges.length !== 0 && (
            <div className="section">
              <div>
                <h2>Badges</h2>
              </div>
              {childBadges.map((badge) => {
                return (
                  <div className="element">
                    <div>
                      <p>{badge.course_name}</p>
                    </div>
                    <div>
                      <div
                        style={{
                          height: 50,
                          width: 50,
                        }}
                      >
                        <img
                          src={badge.badge_image_url}
                          height="100%"
                          width="100%"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress size={30} />
      <h3 style={{ marginTop: 20 }}>Loading Profile...</h3>
    </div>
  );
};

export default ViewChild;
const EmptyState = () => {
  return (
    <div className="emptyListWrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div>
          <img src={Mascot} height={40} width={40} alt="" />
        </div>
        <div style={{ marginTop: 10 }}>
          <h3>No Courses</h3>
        </div>
        <div style={{ marginTop: 20 }}>
          <p>No course found. To add,</p>
          <p>tap on "Add Courses" button.</p>
        </div>
      </div>
    </div>
  );
};

const CourseList = (data) => {
  const actionButtonStyles = createStyles({
    button: {
      padding: "0",
      minWidth: "0",
      "&:focus": {
        color: "red",
      },
    },
  });
  const { childId } = useParams();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const handleClick = (event, index) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setSelectedCourse(index);
  };
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };
  const open = Boolean(anchorEl);
  let modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      margin: "auto",
      borderRadius: 15,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      maxHeight: "30%",
      width: "90%",
      justifyContent: "center",
      alignSelf: "center",
      overflow: "none",
    },
  };
  const { auth } = useAuth();
  const deleteCourse = async (index) => {
    let { accessToken } = auth;
    let config = { headers: { Authorization: `Bearer ${accessToken}` } };

    let postData = {
      course_name: data.data[index].course_name,
      child_username: childId,
    };

    let response = await axios.post(
      `${URL.removeChildBookmark}`,
      postData,
      config
    );

    if (response.success === true) {
      toggleModal();
      window.location.reload();
    }
  };
  return (
    <div className="listWrapper">
      {data.data.map((item, index) => {
        return (
          <div
            className="listItem pointer"
            onClick={() => navigate(`/coursedetails/${item.course_name}`)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>{item.course_name}</h3>
              <Button
                aria-describedby={index}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, index);
                }}
                style={actionButtonStyles}
              >
                <MoreVertIcon
                  sx={{
                    color: "#ccc",
                  }}
                  size="small"
                />
              </Button>
              <Popper
                id={index}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
              >
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: "10px 10px 0",
                    bgcolor: "#f5f6fb",
                    borderRadius: "5px",
                  }}
                >
                  <ul className="unstyled childActions">
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        setAnchorEl(!anchorEl);
                        toggleModal();
                      }}
                      className="d-flex align-center pointer"
                    >
                      <img src={DeleteIcon} alt="delete-icon" />
                      Delete
                    </li>
                  </ul>
                </Box>
              </Popper>
            </div>
            <div style={{ marginTop: 10 }}>
              <c>Overall Progress: {item.progress ? item.progress : 0}%</c>
            </div>
            <div>
              <ProgressBar percentage={item.progress ? item.progress : 0} />
            </div>
            <div onClick={(event) => event.stopPropagation()}>
              <ReactModal
                isOpen={modalVisible}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                style={modalStyles}
              >
                <div className="modalInnerStyle">
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleModal();
                    }}
                    className="modalCross"
                  >
                    x
                  </div>
                  <div className="modalText">
                    <h2>Are you sure?</h2>
                    <div className="modalText2">
                      <p>This action cannot be undone.</p>
                    </div>
                  </div>
                  <div className="modalButtons">
                    <div onClick={toggleModal} className="modalButton1">
                      Go Back
                    </div>
                    <div
                      onClick={() => deleteCourse(selectedCourse)}
                      className="modalButton2"
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </ReactModal>
            </div>
          </div>
        );
      })}
    </div>
  );
};
