import React from "react";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./button.css";

const ButtonLoading = ({
  initialText,
  buttonStyles,
  loadingTruth,
  clickEvent,
  submit,
}) => {
  return submit ? (
    <div className="submitButton" onClick={clickEvent}>
      {loadingTruth && <span>Loading</span>}
      {loadingTruth && (
        <RefreshIcon
          className="spinner"
          style={{ marginLeft: "5px", verticalAlign: "middle" }}
        />
      )}
      {!loadingTruth && <span>{initialText}</span>}
    </div>
  ) : (
    <Button style={buttonStyles} variant="contained" onClick={clickEvent}>
      {loadingTruth && <span>Loading</span>}
      {loadingTruth && (
        <RefreshIcon
          className="spinner"
          style={{ marginLeft: "5px", verticalAlign: "middle" }}
        />
      )}
      {!loadingTruth && <span>{initialText}</span>}
    </Button>
  );
};

export default ButtonLoading;
