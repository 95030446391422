import React from 'react';
import { SoundPlayer } from '../assets/images';

const AudioPlay = () => {
    return (
        <audio
            controls
            autoPlay
            style={{
                opacity: 0,
                position: 'absolute',
            }}>
            <source src={SoundPlayer} type='audio/ogg' />
            Your browser does not support the audio element.
        </audio>
    );
};

export default AudioPlay;
