import React, { useEffect, useState } from "react";
import "./videoplayer.css";
import ReactPlayer from "react-player";

function VideoPlayer({ link, onComplete }) {
  const [url, setUrl] = useState({ src: link });

  useEffect(() => {
    setInterval(() => {
      setUrl(link);
    }, 100);
  }, []);

  return (
    <div className="videoPlayer">
      <ReactPlayer
        url={url}
        width="85%"
        style={{ margin: "auto" }}
        height="85%"
        controls={true}
        onEnded={onComplete}
      />
    </div>
  );
}

export default VideoPlayer;
