export const ERROR_MSG = {
    emptyFirstName: "First Name Required",
    emptyLastName: "Last Name Required",
    emptyEmail:"Email Required",
    emptyUser:"Username Required",
    emptyPassword: "Password Cannot be Empty",
    emptyConfirmPassword: "Confirm Password cannot be Empty",
    passwordMatch: "Passwords don't match",
    termsAccepted: "Accept our Terms to continue",
    emptyPin: "Pin Cannot be Empty",
    emptyConfirmPin: "Confirm pin cannot be Empty",
    pinMatch: "Pins don't match"
}