import React, { useState } from "react";
import {
  Email,
  Eye,
  EyeClose,
  Info,
  InfoColored,
  Lock,
  Name,
  Username,
} from "../../assets/textboxicons/TextBoxIcons";
import "./textbox.css";
const TextBox = (props) => {
  const [password, setPassword] = useState(props.protected);
  const [focused, setFocused] = useState(false);

  const getIcon = (icon) => {
    switch (icon) {
      case "mail":
        return <img src={Email} alt="email-icon" />;
      case "name":
        return <img src={Name} alt="user-icon" />;
      case "username":
        return <img src={Username} alt="user-icon" />;
      case "lock":
        return <img src={Lock} alt="lock-icon" />;
      case "eye":
        return <img src={Eye} alt="uncover-icon" />;
      case "eyeclose":
        return <img src={EyeClose} alt="cover-icon" />;
      case "info":
        return <img src={InfoColored} alt="info-icon" />;
      case "infodefault":
        return <img src={Info} alt="info-icon" />;
      default:
        break;
    }
  };

  return (
    <>
      <div
        className={
          focused === false ? "textBoxContainer" : "textBoxContainerFocused"
        }
      >
        <div className="textBoxIconDiv">{getIcon(props.icon)}</div>
        {props.infoIcon === true && props.placeholder === "Username*" && (
          <span className="infoIcon pointer" onClick={props.infoHandler}>
            {getIcon("info")}
          </span>
        )}
        <div className="textBoxRight">
          <>
            <input
              className={
                password === true ? "textBoxStyle password" : "textBoxStyle"
              }
              placeholder={focused === false ? props.placeholder : ""}
              type={
                props.placeholder === "PIN (6 digit no)*" ||
                props.placeholder === "Confirm PIN*"
                  ? "number"
                  : ""
              }
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              {...props}
            />
            {props.protected === true && (
              <div onClick={() => setPassword(!password)}>
                <div style={{ marginRight: 10 }}>
                  {password === false ? getIcon("eye") : getIcon("eyeclose")}
                </div>
              </div>
            )}
            {props.infoIcon === true && props.placeholder === "Password*" && (
              <span
                className="infoIcon passwordInfo pointer"
                onClick={props.infoHandler}
              >
                {getIcon("infodefault")} password must be
              </span>
            )}
          </>
        </div>
      </div>
      {props.error && Object.keys(props.error)?.length !== 0 ? (
        <div
          style={{
            fontSize: 12,
            color: "red",
            marginTop: 5,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {props.error}
        </div>
      ) : (
        <div style={{ marginTop: 20 }}></div>
      )}
    </>
  );
};
export default TextBox;
