import React from "react";

export const Modal = ({ isVisible, className, children, onClose }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <div onClick={onClose} className="overlay"></div>
      <div className={`${className} no-pointer-events`}>{children}</div>
    </div>
  );
};
