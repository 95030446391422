import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axios } from "../../../lib/axios";
import { Back } from "../../../assets/commonicons/CommonIcons";
import TextBox from "../../../components/textBox/TextBox";
import { Logo } from "../../../assets/images";
// css
import "./forgotpass.css";
import { errorHandler, successHandler } from "../../../lib/serverHandler";
import ButtonLoading from "../../../components/button/ButtonLoading";

const ForgotPass = () => {
  useEffect(() => {
    document.title = "Forgot password - Seepalaya";
  }, []);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);

  const handleSubmit = async (e) => {
    setForgotPasswordLoading(true);
    try {
      const response = await axios.post("/account/forgot-password/", {
        email,
      });
      if (response.success === true)
        successHandler("Pasword reset link has been sent to your email inbox.");
      setForgotPasswordLoading(false);
      navigate("/login");
    } catch (error) {
      setForgotPasswordLoading(false);
      errorHandler(error.response.data.message);
      setEmail("");
      navigate("/login/forgot-password");
    }
  };
  const validateForm = () => {
    let valid = true;
    if (email.trim() === "") {
      valid = false;
      setError("Enter a valid email");
    } else {
      setError("");
    }

    return valid;
  };

  return (
    <>
      <div className="forgotContainer">
        <div className="forgotHeader">
          <img onClick={() => navigate(-1)} src={Back} alt="back-icon" />
          <h1 onClick={() => navigate("/")} className="pointer">
            <img src={Logo} alt="Sipalya" />
          </h1>
          <div></div>
        </div>
        <div className="forgotCenter">
          <div className="forgotForm">
            <div className="forgotFormTop">
              <h2 style={{ marginTop: 10 }}>Forgot Password?</h2>
              <p className="forgotTopText">
                Enter an email address to reset your password
              </p>
            </div>
            <div style={{ marginTop: 20 }}>
              <TextBox
                icon="mail"
                placeholder="Email"
                error={error}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <ButtonLoading
                buttonStyles={{ width: "100%", marginTop: 20 }}
                varient="contained"
                clickEvent={() => {
                  if (validateForm()) handleSubmit();
                }}
                initialText="Submit"
                loadingTruth={forgotPasswordLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPass;
