import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  Close,
  Correct,
  Incorrect,
  Mascot,
  TryAgain,
} from "../../../../assets/commonicons/CommonIcons";
import { URL } from "../../../../constants/url";
import { axios } from "../../../../lib/axios";
import Feedback from "../Feedback";
import "./equationquiznew.css";

export default function EquationQuizNew({ lesson, onComplete }) {
  const { chaptername } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState();
  const [checkCorrect, setCheckCorrect] = useState();
  const [loading, setLoading] = useState(true);
  const [showFeedback, setShowFeedback] = useState(false);
  let modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    content: {
      margin: "auto",
      borderRadius: 15,
      height: "90%",
      width: "90%",
      justifyContent: "center",
      alignSelf: "center",
      overflow: "none",
      zIndex: 1000,
    },
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };
  const checkButtonRender = () => {
    if (checkCorrect == true) {
      return (
        <div onClick={handleContinue} className="checkCorrectButton pointer">
          Continue
        </div>
      );
    } else if (selectedOption == null) {
      return <div className="checkButtonGrey pointer">Check</div>;
    } else if (checkCorrect == false) {
      return (
        <div
          onClick={() => {
            setSelectedOption();
            setCheckCorrect();
          }}
          className="checkIncorrectButton pointer"
        >
          <div>
            <img src={TryAgain} />
          </div>
          <div style={{ marginLeft: 10 }}>Try Again</div>
        </div>
      );
    } else if (selectedOption != null) {
      return (
        <div onClick={checkClicked} className="checkButton pointer">
          Check
        </div>
      );
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  const renderSubQuestion = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <div className="subQuestion3">{data[currentIndex].equation}</div>
      </div>
    );
  };
  const toggleFeedback = () => {
    setShowFeedback(!showFeedback);
  };
  const renderFeedback = () => {
    return (
      <Feedback
        selectedAnswer={selectedOption}
        toggleFeedback={toggleFeedback}
        data={data[currentIndex]}
      />
    );
  };
  const getOptionIndex = (index) => {
    switch (index) {
      case 0:
        return "A";
      case 1:
        return "B";
      case 2:
        return "C";
      case 3:
        return "D";
    }
  };
  const optionIndexStyle = (item) => {
    if (item == selectedOption) {
      if (checkCorrect == true) {
        return "optionInde optionIndeCorrect";
      } else if (checkCorrect == false) {
        return "optionInde optionIndeIncorrect";
      } else {
        return "optionInde optionIndeSelected";
      }
    } else {
      return "optionInde";
    }
  };
  const optionStyle = (item) => {
    if (item == selectedOption) {
      if (checkCorrect == true) {
        return "option optionCorrect";
      } else if (checkCorrect == false) {
        return "option optionIncorrect";
      } else {
        return "option optionSelected";
      }
    } else {
      return "option";
    }
  };
  const renderOptions = () => {
    return (
      <>
        {data[currentIndex].options.map((item, index) => {
          return (
            <div
              key={index}
              className={optionStyle(item)}
              onClick={() => {
                if (checkCorrect == null || checkCorrect == false) {
                  setSelectedOption(item);
                  setCheckCorrect();
                }
              }}
            >
              <div className={optionIndexStyle(item, 1)}>
                {getOptionIndex(index)}
              </div>
              <div style={{ width: "85%" }}>{item}</div>
              <div></div>
            </div>
          );
        })}
      </>
    );
  };

  const checkClicked = () => {
    let correct = new Audio("/correct.mp3");
    let incorrect = new Audio("/incorrect.mp3");

    if (selectedOption != null) {
      if (data[currentIndex].correct_answer == selectedOption) {
        correct.play();
        setCheckCorrect(true);
      } else {
        incorrect.play();
        setCheckCorrect(false);
      }
    }
  };
  const renderCorrectModal = () => {
    if (checkCorrect == true) {
      return "correctModal";
    } else if (checkCorrect == false) {
      return "incorrectModal";
    } else {
      return "hideModal";
    }
  };
  const handleContinue = () => {
    if (currentIndex == data.length - 1) {
      onComplete();
    } else {
      selectedOption != null && setCurrentIndex(currentIndex + 1);
      setCheckCorrect();
      setSelectedOption();
    }
  };
  const fetchQuestions = async () => {
    let response = await axios
      .get(
        `${URL.lessonReview}?course_name=${chaptername}&lesson_number=${lesson}`
      )
      .catch((err) => console.log(err));
    response.data.map((item) => {
      item.options = shuffleArray(item.options);
    });
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchQuestions();
  }, []);

  const renderBottom = () => {
    return (
      <div className="bottom">
        <div className="subQuestionContainer">{renderSubQuestion()}</div>
        <div className="options">{renderOptions()}</div>
        <div className="checkButtonContainer">
          <div className={renderCorrectModal()}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={checkCorrect ? Correct : Incorrect} />
              <div style={{ marginLeft: 10 }}>
                {checkCorrect
                  ? "Awesome! Right Answer"
                  : "Good Try! But Wrong Answer"}
              </div>
            </div>
          </div>
          {checkButtonRender()}
        </div>
        <div className="count">
          {currentIndex + 1}/{data.length}
        </div>
        <div
          onClick={() => {
            if (checkCorrect != null) {
              toggleFeedback();
            }
          }}
          className={"feedbackButton pointer"}
        >
          <div
            className={
              checkCorrect == null
                ? ""
                : checkCorrect == true
                ? "pulseCorrect"
                : "pulseIncorrect"
            }
          ></div>
          <img src={Mascot} style={{ height: "100%", width: "100%" }} />
        </div>
        <Modal
          isOpen={showFeedback}
          onRequestClose={toggleFeedback}
          contentLabel="My dialog"
          style={modalStyles}
        >
          {renderFeedback()}
        </Modal>
      </div>
    );
  };

  return (
    loading == false && (
      <>
        <div className="quizContainer">
          <div className="quiz-Header">
            <img onClick={goBack} className="cross pointer" src={Close} />
            <div className="mainQuestion">
              {currentIndex + 1}) {data[currentIndex].question}
            </div>
          </div>
          {renderBottom()}
        </div>
      </>
    )
  );
}
