import React, { useState, useEffect } from "react";
import { Close } from "../../../assets/commonicons/CommonIcons";
import "../feedback.css";

export default function Feedback({
  data,
  selectedAnswer,
  toggleFeedback,
  word,
  variable,
  operator,
  equation,
  equation_lhs,
  correct_answer,
}) {
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState({
    fb1: {
      head: "",
      body1: "",
    },
    fb2: {
      head: "",
      body: "",
      body1: "",
      body2: "",
      body3: "",
      body4: "",
      body5: "",
    },
    fb3: {
      head: "",
      body1: "",
      body2: "",
    },
  });
  useEffect(() => {
    prepareFeedback();
  }, []);

  const prepareFeedback = () => {
    console.log("hehe");
    let feedbacks = { ...feedback };
    let correct;
    let wordCorrect;
    if (data?.correct_answer === selectedAnswer) correct = true;
    else correct = false;
    if (correct_answer == selectedAnswer) wordCorrect = true;
    else wordCorrect = false;

    console.log(correct_answer, selectedAnswer);
    if (word) {
      if (operator === "+") {
        //feedback for lesson 1

        if (wordCorrect === true) {
          feedbacks.fb1.head = "Great Job!";
          feedbacks.fb1.body1 = `Let's confirm that ${variable} = ${correct_answer} is the correct answer.`;

          //second box
          feedbacks.fb2.head = "How to check?";
          feedbacks.fb2.body1 = `When ${variable} = ${selectedAnswer},`;
          feedbacks.fb2.body2 = `  ${equation_lhs} `;
          feedbacks.fb2.body3 = `= ${equation_lhs.replace(
            variable,
            selectedAnswer
          )}`;
          feedbacks.fb2.body4 = `= ${
            parseInt(equation_lhs.split(operator)[0]) + parseInt(selectedAnswer)
          }`;
          //third box
          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `With ${variable} = ${correct_answer}, ${equation}.`;
          feedbacks.fb3.body2 = `So, ${correct_answer} is the correct answer.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `Remember to replace ${variable} with the value.`;
          feedbacks.fb1.body2 = "Then check if you get the right result.";

          //second box
          feedbacks.fb2.head = "How to check?";
          feedbacks.fb2.body1 = `When ${variable} = ${selectedAnswer},`;
          feedbacks.fb2.body2 = `${equation_lhs} `;
          feedbacks.fb2.body3 = `= ${equation_lhs.replace(
            variable,
            selectedAnswer
          )}`;
          feedbacks.fb2.body4 = `= ${
            parseInt(equation_lhs.split(operator)[0]) + parseInt(selectedAnswer)
          }`;
          feedbacks.fb2.body5 = `But ${
            parseInt(equation_lhs.split(operator)[0]) + parseInt(selectedAnswer)
          } is not equal to ${equation.split("=")[1]}.`;
          //third box
          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `What value of ${variable} gives ${equation}?`;
          feedbacks.fb3.body2 = "Try again!";
        }
      }
      if (operator === "-") {
        if (wordCorrect === true) {
          feedbacks.fb1.head = "Great Job!";
          feedbacks.fb1.body1 = `Let's confirm that ${variable} = ${correct_answer} is the correct answer.`;

          //second box
          feedbacks.fb2.head = "How to check?";
          feedbacks.fb2.body1 = `When ${variable} = ${selectedAnswer},`;
          feedbacks.fb2.body2 = `${equation_lhs}`;
          feedbacks.fb2.body3 = ` = ${equation_lhs.replace(
            variable,
            selectedAnswer
          )}`;
          feedbacks.fb2.body4 = `= ${
            parseInt(equation_lhs.split(operator)[0]) - parseInt(selectedAnswer)
          }`;
          //third box
          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `With ${variable} = ${correct_answer}, ${equation}.`;
          feedbacks.fb3.body2 = `So, ${correct_answer} is the correct answer.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `Remember to replace ${variable} with the value.`;
          feedbacks.fb1.body2 = "Then check if you get the right result.";

          //second box
          feedbacks.fb2.head = "How to check?";
          feedbacks.fb2.body1 = `When ${variable} = ${selectedAnswer},`;
          feedbacks.fb2.body2 = `${equation_lhs}`;
          feedbacks.fb2.body3 = `= ${equation_lhs.replace(
            variable,
            selectedAnswer
          )}`;
          feedbacks.fb2.body4 = `= ${
            parseInt(equation_lhs.split(operator)[0]) - parseInt(selectedAnswer)
          }`;
          feedbacks.fb2.body5 = `But ${
            parseInt(equation_lhs.split(operator)[0]) - parseInt(selectedAnswer)
          } is not equal to ${equation.split("=")[1]}.`;
          //third box
          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `What value of ${variable} gives ${equation}?`;
          feedbacks.fb3.body2 = "Try again!";
        }
      }
    }
    if (data.operator === "+") {
      //feedback for lesson 1

      if (correct === true) {
        feedbacks.fb1.head = "Great Job!";
        feedbacks.fb1.body1 = `Let's confirm that ${data.variable} = ${data.correct_answer} is the correct answer.`;

        //second box
        feedbacks.fb2.head = "How to check?";
        feedbacks.fb2.body1 = `When ${data.variable} = ${selectedAnswer},`;
        feedbacks.fb2.body2 = `${data.equation_lhs}`;
        feedbacks.fb2.body3 = `= ${data.equation_lhs.replace(
          data.variable,
          selectedAnswer
        )}`;
        feedbacks.fb2.body4 = `= ${
          parseInt(data.equation_lhs.split(data.operator)[0]) +
          parseInt(selectedAnswer)
        }`;
        //third box
        feedbacks.fb3.head = "You did it!";
        feedbacks.fb3.body1 = `With ${data.variable} = ${data.correct_answer}, ${data.equation}.`;
        feedbacks.fb3.body2 = `So, ${data.correct_answer} is the correct answer.`;
      } else {
        //first box
        feedbacks.fb1.head = "Good Try!";
        feedbacks.fb1.body1 = `Remember to replace ${data.variable} with the value.`;
        feedbacks.fb1.body2 = "Then check if you get the right result.";

        //second box
        feedbacks.fb2.head = "How to check?";
        feedbacks.fb2.body1 = `When ${data.variable} = ${selectedAnswer},`;
        feedbacks.fb2.body2 = `${data.equation_lhs}`;
        feedbacks.fb2.body3 = `= ${data.equation_lhs.replace(
          data.variable,
          selectedAnswer
        )}`;
        feedbacks.fb2.body4 = `= ${
          parseInt(data.equation_lhs.split(data.operator)[0]) +
          parseInt(selectedAnswer)
        }`;
        feedbacks.fb2.body5 = `But ${
          parseInt(data.equation_lhs.split(data.operator)[0]) +
          parseInt(selectedAnswer)
        } is not equal to ${data.equation.split("=")[1]}.`;
        //third box
        feedbacks.fb3.head = "You can do it!";
        feedbacks.fb3.body1 = `What value of ${data.variable} gives ${data.equation}?`;
        feedbacks.fb3.body2 = "Try again!";
      }
    }
    if (data.operator === "-") {
      if (correct === true) {
        feedbacks.fb1.head = "Great Job!";
        feedbacks.fb1.body1 = `Let's confirm that ${data.variable} = ${data.correct_answer} is the correct answer.`;

        //second box
        feedbacks.fb2.head = "How to check?";
        feedbacks.fb2.body1 = `When ${data.variable} = ${selectedAnswer},`;
        feedbacks.fb2.body2 = `${data.equation_lhs}`;
        feedbacks.fb2.body3 = `= ${data.equation_lhs.replace(
          data.variable,
          selectedAnswer
        )}`;
        feedbacks.fb2.body4 = `= ${
          parseInt(data.equation_lhs.split(data.operator)[0]) -
          parseInt(selectedAnswer)
        }`;
        //third box
        feedbacks.fb3.head = "You did it!";
        feedbacks.fb3.body1 = `With ${data.variable} = ${data.correct_answer}, ${data.equation}.`;
        feedbacks.fb3.body2 = `So, ${data.correct_answer} is the correct answer.`;
      } else {
        //first box
        feedbacks.fb1.head = "Good Try!";
        feedbacks.fb1.body1 = `Remember to replace ${data.variable} with the value.`;
        feedbacks.fb1.body2 = "Then check if you get the right result.";

        //second box
        feedbacks.fb2.head = "How to check?";
        feedbacks.fb2.body1 = `When ${data.variable} = ${selectedAnswer},`;
        feedbacks.fb2.body2 = `${data.equation_lhs}`;
        feedbacks.fb2.body3 = `= ${data.equation_lhs.replace(
          data.variable,
          selectedAnswer
        )}`;
        feedbacks.fb2.body4 = `= ${
          parseInt(data.equation_lhs.split(data.operator)[0]) -
          parseInt(selectedAnswer)
        }`;
        feedbacks.fb2.body5 = `But ${
          parseInt(data.equation_lhs.split(data.operator)[0]) -
          parseInt(selectedAnswer)
        } is not equal to ${data.equation.split("=")[1]}.`;
        //third box
        feedbacks.fb3.head = "You can do it!";
        feedbacks.fb3.body1 = `What value of ${data.variable} gives ${data.equation}?`;
        feedbacks.fb3.body2 = "Try again!";
      }
    }

    setFeedback(feedbacks);
    setLoading(false);
  };

  return (
    loading == false && (
      <>
        <div onClick={toggleFeedback} className="quizModalCross">
          <img src={Close} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3
            style={{
              padding: 10,
              backgroundColor: "#69b8e2",
              color: "white",
              borderRadius: 10,
            }}
          >
            Know Why?
          </h3>
        </div>
        <div className="feedbackWrapper">
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb1.head}</div>
            <div className="fbbody">{feedback.fb1.body1}</div>
            <div className="fbbody">{feedback.fb1.body2}</div>
          </div>
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb2.head}</div>
            <div className="fbbody">{feedback.fb2.body1}</div>
            <div style={{ marginLeft: 11 }} className="fbbody">
              {feedback.fb2.body2}
            </div>
            <div className="fbbody">{feedback.fb2.body3}</div>
            <div className="fbbody">{feedback.fb2.body4}</div>
            <div className="fbbody">{feedback.fb2.body5}</div>
          </div>
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb3.head}</div>
            <div className="fbbody">{feedback.fb3.body1}</div>
            <div className="fbbody">{feedback.fb3.body2}</div>
            <div className="fbbody">{feedback.fb3.body3}</div>
          </div>
        </div>
      </>
    )
  );
}
