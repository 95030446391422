import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { Back, Mascot } from "../../assets/commonicons/CommonIcons";
import {
  DocumentIcon,
  GameIcon,
  VideoIcon,
} from "../../assets/coursedetails/CourseDetailsIcons";
import { URL } from "../../constants/url.js";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";
import PageEnd from "../chapterIntro/PageEnd";
import "./chapterintro.css";

const ChapterIntro = () => {
  const { auth } = useAuth();
  const { chaptername } = useParams();
  const navigate = useNavigate();
  const [chapter, setChapter] = useState();
  const [loading, setLoading] = useState(true);
  const [introComplete, setIntroComplete] = useState(false);

  useEffect(() => {
    document.title = `Course Introduction - ${chaptername}`;
  }, []);

  const getLink = async () => {
    let response = await axios.get(
      `${URL.getCourseIntro}?course_name=${chaptername}`
    );
    console.log(response);
    setChapter(response.data);
    setLoading(false);
  };

  const handleContinueButton = async () => {
    navigate(`/coursedetails/${chaptername}/preview/pre-assessment`, {
      replace: "true",
    });
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let data = {
      preview_type: "Course Intro",
      course_name: chaptername,
    };
    console.log(auth);
    if (auth.accessToken) {
      if (auth.userType === "child") {
        let response = await axios.post(`${URL.previewComplete}`, data, config);
        console.log(response);
        setChapter(response.data);
        setLoading(false);
      }
    }
  };

  function changeFlutterOrientation(orientationName) {
    try {
      Print.postMessage(orientationName);
    } catch {
      if (orientationName == "portrait") {
        window.screen.orientation.lock("portrait");
      } else {
        window.screen.orientation.lock("landscape");
      }
    }
  }
  const goBack = () => {
    changeFlutterOrientation("portrait");
    navigate(-1);
  };
  useEffect(() => {
    window.onpopstate = () => {
      changeFlutterOrientation("portrait");
    };
  }, []);
  useEffect(() => {
    changeFlutterOrientation("landScape");
    getLink();
  }, []);

  return (
    loading == false &&
    (introComplete == true ? (
      <PageEnd
        point={0}
        restart={() => setIntroComplete(!introComplete)}
        continueAll={() =>
          navigate(`/coursedetails/${chaptername}/preview/pre-assessment`, {
            replace: true,
          })
        }
        closeEvent={() => setIntroComplete(!introComplete)}
      />
    ) : (
      <div className="intro_container">
        <div className="d-flex content-holder">
          <div className="intro_title">
            {<h1>{chaptername}</h1>}
            <p>Introduction</p>
          </div>
          <div className="intro_body_container">
            <div className="intro_body">
              <div className="intro_body_part intro_body_img">
                <img src={Mascot} alt="girl_image" className="girl_image" />
              </div>
              <div className="intro_body_part intro_body_content">
                <div className="discription">
                  <p>{chapter.description}</p>
                </div>
                <div className="dic_time_points">
                  <p>
                    <span>
                      {chapter.duration == "NA"
                        ? ""
                        : `${chapter.duration} mins`}
                    </span>
                    <span style={{ marginLeft: 4, marginRight: 4 }}> | </span>
                    <span>{chapter.points} points</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="continue_btn_container">
            <div
              onClick={handleContinueButton}
              className="continuebutton blue pointer"
            >
              Continue
            </div>
          </div>
        </div>
        <div onClick={goBack} className="cross_btn pointer">
          <img src={Back} alt="girl_image" className="girl_image" />
        </div>
      </div>
    ))
  );
};

export default ChapterIntro;
