export const URL = {
  //account
  parentLogin: "account/guardian/login/",
  childLogin: "account/child/login/",
  guardianSignup: "account/guardian/signup/",
  childList: "account/child/list/",
  childSignup: "account/child/signup/",
  verify: "account/email/verify/",
  resendOtp: "account/otp/resend/",
  refreshToken: "account/token/refresh/",
  userDetails: "account/user-details/",
  updateUser: "account/user/update/",
  logout: "account/user/login/",
  deleteChild: "account/child/delete/",
  viewChild: "account/guardian/child/details/",

  //assessment

  childAssessmentLogs: "assessment/guardian/child/logs",
  childCourseLogs: "assessment/guardian/child/logs/course",
  childAssessmentLog: "assessment/child/assessment/logs/",
  generateLog: "assessment/generate/report/logs/",

  //lesson review

  lessonReview: "assessment/lesson-review",

  //course
  getAllCourses: "course/all/",
  getCourseCode: "course/code/",
  getCourseLessons: "course/lessons/",
  getUnitLink: "course/unit/link/",
  getNextUnit: "course/unit/next/",
  getPreviousUnit: "course/unit/previous/",
  getCourseIntro: "course/description/",

  //progress

  createChildBookmark: "progress/guardian/bookmark/create/",
  childBadges: "reward/guardian/child/badges/",
  childBookmarks: "course/guardian/child/bookmarks/",
  childRemainingBookmarks: "course/guardian/child/bookmarks/remaining/",
  courseProgress: "progress/course/",
  lessonProgress: "progress/lesson/",
  unitProgress: "progress/unit/",
  unitComplete: "progress/unit/complete/",
  previewComplete: "progress/preview/complete/",
  reviewComplete: "progress/review/complete/",
  removeChildBookmark: "progress/guardian/bookmark/remove/",

  //child dashboard

  getBookmarks: "course/child/bookmarks/",
  getRemainingBookmarks: "course/child/bookmarks/remaining/",
  addBookmark: "progress/child/bookmark/create/",
  getChildDetails: "account/child/details/",
  updateChild: "account/child/update/",
  getAllProfilePictures: "account/picture/all/",
  changeProfilePicture: "account/picture/change/",
  getAssessmentLogs: "assessment/logs/",
  getDetailedLogs: "assessment/logs/course/",
  getBadges: "reward/badges/",
};
