import React, { useState, useEffect } from "react";
import { Close } from "../../../../../assets/commonicons/CommonIcons";
import "../../../feedback.css";

export default function Feedback({ data, selectedAnswer, toggleFeedback }) {
  console.log(data);
  const [loading, setLoading] = useState(true);

  const prepareFeedback = () => {
    let correct;
    if (data.correct_answer === selectedAnswer) correct = true;
    else correct = false;
    //feedback for lesson 1

    if (correct) {
      console.log(data);
      let one = data?.correct_feedback?.one.split("\n");
      let two = data?.correct_feedback?.two.split("\n");
      let three = data?.correct_feedback?.three.split("\n");
      return (
        <div className="feedbackWrapperCircuits">
          <div className="feedbackGreyBox">
            {one.map((item, index) => {
              return (
                <>
                  {index === 0 ? (
                    <div className="fbhead">{item}</div>
                  ) : (
                    <div className="fbbody">{item}</div>
                  )}
                </>
              );
            })}
          </div>
          <div className="feedbackGreyBox1">
            {two.map((item, index) => {
              return (
                <>
                  {index === 0 ? (
                    <div className="fbhead">{item}</div>
                  ) : (
                    <div className="fbbody">{item}</div>
                  )}
                </>
              );
            })}
          </div>
          <div className="feedbackGreyBox">
            {three.map((item, index) => {
              return (
                <>
                  {index === 0 ? (
                    <div className="fbhead">{item}</div>
                  ) : (
                    <div className="fbbody">{item}</div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      );
    } else {
      let one = data.incorrect_feedback.one.split("\n");
      let two = data.incorrect_feedback.two.split("\n");
      let three = data.incorrect_feedback.three.split("\n");
      return (
        <div className="feedbackWrapperCircuits">
          <div className="feedbackGreyBox">
            {one.map((item, index) => {
              return (
                <>
                  {index === 0 ? (
                    <div className="fbhead">{item}</div>
                  ) : (
                    <div className="fbbody">{item}</div>
                  )}
                </>
              );
            })}
          </div>
          <div className="feedbackGreyBox1">
            {two.map((item, index) => {
              return (
                <>
                  {index === 0 ? (
                    <div className="fbhead">{item}</div>
                  ) : (
                    <div className="fbbody">{item}</div>
                  )}
                </>
              );
            })}
          </div>
          <div className="feedbackGreyBox">
            {three.map((item, index) => {
              return (
                <>
                  {index === 0 ? (
                    <div className="fbhead">{item}</div>
                  ) : (
                    <div className="fbbody">{item}</div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div onClick={toggleFeedback} className="quizModalCross">
        <img src={Close} />
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3
          style={{
            padding: 10,
            backgroundColor: "#69b8e2",
            color: "white",
            borderRadius: 10,
          }}
        >
          Know Why?
        </h3>
      </div> */}
      {prepareFeedback()}
    </>
  );
}
