import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactModal from "react-modal";
import { Modal } from "../../components/modal/Modal.jsx";
import { useNavigate, useParams } from "react-router-dom";
import ReactTouchEvents from "react-touch-events";
import {
  Close,
  Info,
  Mascot,
  RewardCoin,
} from "../../assets/commonicons/CommonIcons.js";
import UnitFirstPage from "../../components/commonScreens/UnitFirstPage.jsx";
import Epiframe from "../../components/EP_Iframe/Epiframe.jsx";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import { URL } from "../../constants/url.js";
import useAuth from "../../hooks/useAuth";
import { axios } from "../../lib/axios";
import { addInfoHandler, removeInfoHandler } from "../../lib/formValidation.js";
import LessonReview from "../../pages/LessonreviewQuiz/LessonReview";
import BadgePointPage from "../badges/BadgesPoints.jsx";
import PageEnd from "../chapterIntro/PageEnd";
import "./coursepage.css";
const CoursePage = () => {
  const footerTitle = useRef(null);
  const { chaptername, unitUid } = useParams();
  const navigate = useNavigate();
  const [subUnit, setSubUnit] = useState();
  const [firstPageShow, setFirstPageShow] = useState(true);
  const [lastPageShow, setLastPageShow] = useState(false);
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [postMsgData, setPostMsgData] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [userTotalScore, setUserTotalScore] = useState(0);

  const [isBadge, setisBadge] = useState(false);
  useEffect(() => {
    let modal = document.querySelector(".overlay");
    window.onclick = function (event) {
      if (event.target === modal) {
        setisBadge(false);
        document.body.classList.remove("modal-open");
      }
    };
  }, [isBadge]);

  const badgeOpen = () => {
    toggleModal();
    addInfoHandler(setisBadge);
  };
  const badgeClose = () => {
    toggleModal();
    removeInfoHandler(setisBadge);
  };

  const getLink = async () => {
    let response = await axios.get(`${URL.getUnitLink}?unit_uid=${unitUid}`);
    if (response.data.type === "QZ") {
      document.title = `Lesson review - ${chaptername}`;
    } else {
      document.title = `${response.data.name}`;
    }
    setSubUnit(response.data);
    setLoading(false);

    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let res1 = await axios
      .get(`${URL.getChildDetails}`, config)
      .catch((er) => console.log(er));

    if (res1.success === true) {
      setUserTotalScore(res1.data.total_points);
    }
  };
  const tapdivevent = () => {
    const addClassList = footerTitle.current;
    addClassList.className = "footer_title show";
    setTimeout(() => {
      addClassList.className = "footer_title hide";
    }, 3000);
  };
  const closeFrame = (e) => {
    changeFlutterOrientation("portrait");
    navigate(-1);
  };
  function changeFlutterOrientation(orientationName) {
    try {
      Print.postMessage(orientationName);
    } catch {
      if (orientationName == "portrait") {
        window.screen.orientation.lock("portrait");
      } else {
        window.screen.orientation.lock("landscape");
      }
    }
  }
  useEffect(() => {
    changeFlutterOrientation("landScape");
    getLink();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      const addClassList = footerTitle.current;
      addClassList.className = "footer_title hide";
    }, 3000);
  }, [footerTitle]);

  useEffect(() => {
    window.onpopstate = () => {
      changeFlutterOrientation("portrait");
    };
  }, []);

  const loadSameUnit = async () => {
    // let response = await axios.get(`${URL.getUnitLink}?unit_uid=${unitUid}`);
    navigate(`/coursedetails/${chaptername}/${unitUid}`, {
      replace: true,
    });
    window.location.reload(false);
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const goNextUnit = async () => {
    if (subUnit.type !== "QZ") {
      let response = await axios.get(`${URL.getNextUnit}?unit_uid=${unitUid}`);
      navigate(`/coursedetails/${chaptername}/${response.data.uid}`, {
        replace: true,
      });
      window.location.reload(false);
    } else {
      navigate(-1);
    }
  };
  const goPrevUnit = async () => {
    let response = await axios.get(
      `${URL.getPreviousUnit}?unit_uid=${unitUid}`
    );
    navigate(`/coursedetails/${chaptername}/${response.data.uid}`, {
      replace: true,
    });
    window.location.reload(false);
  };
  const onComplete = async (event) => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let data = {
      unit_uid: unitUid,
    };
    handleLastPage();
    try {
      if (auth.accessToken) {
        let res = await axios.post(`${URL.unitComplete}`, data, config);
      }
    } catch (error) {
      console.log(error);
    }
    setPostMsgData(parseInt(event.data.split(":")[1]));
  };
  const handleLastPage = () => {
    if (subUnit.type === "EP" || subUnit.type === "QZ") {
      setLastPageShow(true);
    }
    if (subUnit.type === "VD" || subUnit.type === "GA") {
      toggleModal();
    }
  };
  const renderFrame = () => {
    if (subUnit.type === "EP" || subUnit.type === "GA") {
      return <Epiframe link={subUnit.link} onReceiveMessage={onComplete} />;
    } else if (subUnit.type === "VD") {
      return <VideoPlayer link={subUnit.link} onComplete={onComplete} />;
    } else if (subUnit.type === "QZ") {
      return (
        <LessonReview
          flag={subUnit.code}
          uid={unitUid}
          onComplete={onComplete}
        />
      );
    }
  };
  let modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    content: {
      margin: "auto",
      borderRadius: 15,
      height: "80%",
      width: "80%",
      maxWidth: 700,
      justifyContent: "center",
      alignSelf: "center",
      overflow: "none",
      zIndex: 1000,
    },
  };

  return (
    loading == false &&
    (firstPageShow == true ? (
      <UnitFirstPage
        LoadChapter={() => setFirstPageShow(!firstPageShow)}
        type={subUnit.type}
        title={subUnit.name}
        description={subUnit.description}
        point={subUnit.points}
        duration={subUnit.duration}
        goBack={closeFrame}
      />
    ) : lastPageShow == true ? (
      subUnit.type == "EP" ? (
        <PageEnd
          totalPoints={userTotalScore}
          point={subUnit.points}
          restart={loadSameUnit}
          continueAll={goNextUnit}
          closeEvent={closeFrame}
        />
      ) : (
        <PageEnd
          totalPoints={userTotalScore}
          point={subUnit.points}
          restart={loadSameUnit}
          continueAll={closeFrame}
          closeEvent={closeFrame}
          text={"You have completed the Lesson Review."}
        />
      )
    ) : (
      <>
        <ReactTouchEvents onTap={tapdivevent}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="courseContainer">
              {subUnit.type !== "QZ" && (
                <div className="epaathclose">
                  <Button onClick={closeFrame}>
                    <CloseIcon
                      sx={{
                        borderRadius: "14px",
                        border: "2px solid grey",
                      }}
                    />
                  </Button>
                </div>
              )}
              {renderFrame()}
            </div>
            <ReactModal
              isOpen={modalVisible}
              onRequestClose={toggleModal}
              contentLabel="My dialog"
              style={modalStyles}
            >
              <div>
                <div className="close_icon pointer" onClick={toggleModal}>
                  <img className="closeicon_img" src={Close} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {auth.accessToken && auth.userType === "child" && (
                    <>
                      <div className="points_part">
                        <img className="point" src={RewardCoin} />
                        <p>{userTotalScore}</p>
                      </div>
                      <div className="point_description pointer">
                        <p>
                          <img className="info" src={Info} />
                          <a onClick={badgeOpen}>
                            Learn about badges and points
                          </a>
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <img src={Mascot} alt="" />
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h2 style={{ marginTop: 5 }}>
                      Good Job! You have completed this unit.
                    </h2>
                    {auth.accessToken && auth.userType === "child" && (
                      <>
                        <div className="points_part">
                          <img className="point" src={RewardCoin} />
                          <p>{subUnit.points}</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="lastpg_btn_container"
                  style={{
                    marginTop: auth.accessToken
                      ? auth.userType === "child"
                        ? 0
                        : 50
                      : 50,
                  }}
                >
                  <div
                    onClick={loadSameUnit}
                    className="lstpg_continuebutton restart_btn pointer"
                  >
                    Restart
                  </div>
                  <div
                    onClick={goNextUnit}
                    className="lstpg_continuebutton countinue_btn pointer"
                  >
                    Continue
                  </div>
                </div>
              </div>
            </ReactModal>
            {subUnit.type !== "QZ" && (
              <div className="footer_title" ref={footerTitle}>
                <div className="control_btn">
                  <div className={`btn`} onClick={goPrevUnit}>
                    <ArrowBackIcon />
                    <span>Previous</span>
                  </div>
                </div>
                <div className="title_head">
                  <h3 className="eptitle">{subUnit.name}</h3>
                </div>
                <div className="control_btn">
                  <div className={`btn`} onClick={goNextUnit}>
                    <span>Next</span>
                    <ArrowBackIcon
                      sx={{
                        transform: "rotate(180deg)",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ReactTouchEvents>
        <Modal isVisible={isBadge} className="custom-modal badge">
          <div className="modal_Holder">
            <BadgePointPage clickHandleFun={badgeClose} />
            <div className="button_Holder">
              <button className="modal_Close" onClick={badgeClose}>
                Ok
              </button>
            </div>
          </div>
        </Modal>
      </>
    ))
  );
};

export default CoursePage;
