import { Checkbox, Radio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLoading from '../../../components/button/ButtonLoading';
import TextBox from '../../../components/textBox/TextBox';
import { URL } from '../../../constants/url';
import useAuth from '../../../hooks/useAuth';
import useUserDetails from '../../../hooks/useUserDetails';
import { axios } from '../../../lib/axios';
import { errorHandler, successHandler } from '../../../lib/serverHandler';

export default function ParentLogin({ toggle }) {
    const navigate = useNavigate();
    const updateUserDetails = useUserDetails();
    const { auth, setAuth, persist, setPersist } = useAuth();
    const [parentLoginLoading, setParentLoginLoading] = useState(false);

    const [error, setError] = useState({
        username_or_email: '',
        password: '',
        remember: '',
    });

    const [formData, setFormData] = useState({
        username_or_email: '',
        password: '',
        remember: false,
    });

    const handleSubmit = async (e) => {
        setParentLoginLoading(true);
        try {
            const response = await axios.post(URL.parentLogin, formData);

            if (response.success === true) {
                setAuth({
                    username: response?.data?.username,
                    fullName: response?.data?.full_name,
                    email: response?.data?.user_email,
                    isVerified: response?.data?.is_verified,
                    userType: response?.data?.user_type,
                    accessToken: response?.data?.access_token,
                    profile_picture: response?.data?.profile,
                });
                auth && updateUserDetails();
                console.log(response);
            }
            let config = {
                headers: { Authorization: `Bearer ${response.data.access_token}` },
            };
            if (response.data.is_verified === 'False') {
                await axios.post(URL.resendOtp, null, config);
                navigate('/otp');
                setParentLoginLoading(false);
            } else {
                successHandler('Logged in successfully!');
                navigate('/');
                setParentLoginLoading(false);
            }
        } catch (err) {
            setParentLoginLoading(false);
            errorHandler(err?.response?.data?.message);
            setParentLoginLoading(false);
        }
        //newu93824f@outlook.com
        //9841809411a@
    };
    const validateForm = () => {
        let valid = true;
        let form = {
            username_or_email: '',
            password: '',
            remember: '',
        };

        if (formData.username_or_email.trim() === '') {
            valid = false;
            form.username_or_email = 'Username or Email address Required';
        } else {
            form.username_or_email = '';
        }
        if (formData.password.trim() === '') {
            valid = false;
            form.password = 'Password Cannot be Empty';
        } else {
            setError({ ...error, password: '' });
        }
        setError(form);
        return valid;
    };

    return (
        <div className='loginForm'>
            <div className='loginFormTop'>
                <h2 style={{ marginTop: 10 }}>Hi There!</h2>
                <p className='loginTopText'>Welcome to Seepalaya. Login to continue.</p>
                <p className='loginTopTextSmall'>* items marked with an asterisk are mandatory</p>
            </div>
            <div className='selection'>
                <div className='selected pointer'>
                    <div>
                        <Radio
                            checked={true}
                            sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                    color: 'white',
                                },
                            }}
                        />
                    </div>
                    <div>Guardian</div>
                </div>
                <div className='select pointer' onClick={toggle}>
                    <div>
                        <Radio />
                    </div>
                    <div>Child</div>
                </div>
            </div>
            <div style={{ marginTop: 20 }}>
                <TextBox
                    icon='username'
                    placeholder='Username or email*'
                    error={error.username_or_email}
                    value={formData.username_or_email}
                    onChange={(e) => setFormData({ ...formData, username_or_email: e.target.value })}
                />
                <TextBox
                    icon='lock'
                    placeholder='Password*'
                    protected
                    error={error.password}
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
                <div style={{ width: 'fit-content', float: 'right' }}>
                    <div onClick={() => navigate('forgot-password')} className='loginForgot pointer'>
                        Forgot Password
                    </div>
                </div>
            </div>

            {/* <div className="confirmationDiv">
        <div>
          <Checkbox
            checked={formData.remember}
            onChange={() =>
              setFormData({
                ...formData,
                remember: !formData.remember,
              })
            }
          />
        </div>
        <div style={{ marginBottom: 2 }}>
          <p>Remember me</p>
        </div>
      </div>
      {error.remember.length !== 0 && (
        <div
          style={{
            fontSize: 12,
            color: "red",
            marginTop: 5,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {error.remember}
        </div>
      )} */}
            <div>
                <ButtonLoading
                    buttonStyles={{ width: '100%', marginTop: 20 }}
                    varient='contained'
                    clickEvent={() => {
                        if (validateForm()) handleSubmit();
                    }}
                    initialText='Login'
                    loadingTruth={parentLoginLoading}
                />
            </div>

            <div style={{ marginTop: 20, textAlign: 'center' }}>
                Don't have an Account?{' '}
                <span className='pointer' style={{ color: 'blue' }} onClick={() => navigate('/register')}>
                    Sign Up
                </span>
            </div>
        </div>
    );
}
