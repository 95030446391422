import React, { useState, useEffect } from "react";
import { Close } from "../../../assets/commonicons/CommonIcons";
import TapeGenerator from "../../../components/fractionsHelper/Tape";
import "../feedback.css";

export default function Feedback({ data, selectedAnswer, toggleFeedback }) {
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState({
    fb1: {
      head: "",
      body1: "",
    },
    fb2: {
      head: "",
      dia: null,
      dia1: null,
      body: "",
      body1: "",
      body2: "",
    },
    fb3: {
      head: "",
      body1: "",
      body2: "",
    },
  });
  useEffect(() => {
    prepareFeedback();
  }, []);
  const getComparator = (item) => {
    if (item === ">") {
      return "is greater than";
    }
    if (item === "<") {
      return "is less than";
    }
    if (item === "=") {
      return "is equal to";
    }
    if (item === "+") {
      return "plus";
    }
  };
  const getLeftOrRight = (item) => {
    if (item === ">") {
      return "first";
    } else {
      return "second";
    }
  };
  const getWord = (number, word) => {
    if (number == 1) {
      return word;
    } else {
      return word + "s";
    }
  };
  const getIsAre = (number) => {
    if (number == 1) {
      return "is";
    } else {
      return "are";
    }
  };
  const prepareFeedback = () => {
    let feedbacks = { ...feedback };
    let correct;
    if (data.correct_answer == selectedAnswer) correct = true;
    else correct = false;
    //feedback for lesson 1

    if (data.lesson === 1) {
      //lesson 1 feedback
      if (data.level === 1) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 =
            "You found the correct answer for the given diagram.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `There are ${data.denominator[0]} ${getWord(
            data.denominator[0],
            "part"
          )} in the diagram (denominator).`;
          feedbacks.fb2.body2 = `Only ${data.numerator[0]} ${getWord(
            data.numerator[0],
            "part"
          )} ${getIsAre(data.numerator[0])} shaded (numerator).`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `So, ${data.numerator[0]} out of ${
            data.denominator[0]
          } parts ${getIsAre(data.numerator[0])} shaded.`;
          feedbacks.fb3.body2 = `The correct fraction is ${data.correct_answer}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 =
            "Remember to count the number of total parts (denominator).";
          feedbacks.fb1.body2 =
            "Then check how many parts are shaded (numerator).";

          //second box

          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `There are a total of ${
            data.denominator[0]
          } ${getWord(data.denominator[0], "part")} in the diagram.`;
          feedbacks.fb2.body2 = `Only ${data.numerator[0]} ${getWord(
            data.numerator[0],
            "part"
          )} ${getIsAre(data.numerator[0])} shaded.`;

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Count the shaded parts for the numerator.`;
          feedbacks.fb3.body2 = `Count the total parts for the denominator.`;
          feedbacks.fb3.body3 = `Then try again.`;
        }
      }
      if (data.level === 2) {
        if (data.question.charAt(0) === "?") {
          if (correct === true) {
            //first box
            feedbacks.fb1.head = "Good Job!";
            feedbacks.fb1.body1 =
              "You found the correct numerator for the given fraction.";
            feedbacks.fb1.body2 =
              "The numerator is just the number of shaded parts in the diagram.";
            //second box
            feedbacks.fb2.head = "How to Check?";
            feedbacks.fb2.dia = (
              <TapeGenerator
                fb
                numerator={data.numerator}
                denominator={data.denominator}
              />
            );
            feedbacks.fb2.body1 = `There ${getIsAre(data.numerator[0])} ${
              data.numerator[0]
            } shaded ${getWord(
              data.numerator[0],
              "part"
            )} in the diagram (numerator).`;

            //third box

            feedbacks.fb3.head = "You did it!";
            feedbacks.fb3.body2 = `The numerator is ${data.numerator[0]}.`;
          } else {
            //first box
            feedbacks.fb1.head = "Good Try!";
            feedbacks.fb1.body1 =
              "Remember that numerator is the number of shaded parts in the diagram.";

            //second box

            feedbacks.fb2.head = "How to Check?";
            feedbacks.fb2.dia = (
              <TapeGenerator
                small
                fb
                numerator={data.numerator}
                denominator={data.denominator}
              />
            );
            feedbacks.fb2.body1 = `Here, there are ${data.denominator} total parts in the diagram.`;
            feedbacks.fb2.body2 = `So, ${data.denominator[0]} is the denominator.`;

            //third box

            feedbacks.fb3.head = "You can do it!";
            feedbacks.fb3.body1 = `Count the total number of shaded parts in the diagram. `;
            feedbacks.fb3.body3 = `Then try again.`;
          }
        } else {
          if (correct === true) {
            //first box
            feedbacks.fb1.head = "Good Job!";
            feedbacks.fb1.body1 =
              "You found the correct denominator for the given fraction.";
            feedbacks.fb1.body2 =
              "The denominator is just the number of total parts in the diagram.";
            //second box
            feedbacks.fb2.head = "How to Check?";
            feedbacks.fb2.dia = (
              <TapeGenerator
                fb
                numerator={data.numerator}
                denominator={data.denominator}
              />
            );
            feedbacks.fb2.body1 = `There are ${data.denominator[0]} total parts in the diagram (denominator).`;

            //third box

            feedbacks.fb3.head = "You did it!";
            feedbacks.fb3.body2 = `The denominator is ${data.denominator[0]}.`;
          } else {
            //first box
            feedbacks.fb1.head = "Good Try!";
            feedbacks.fb1.body1 =
              "Remember that denominator is the total number of parts in the diagram.";

            //second box

            feedbacks.fb2.head = "How to Check?";
            feedbacks.fb2.dia = (
              <TapeGenerator
                small
                fb
                numerator={data.numerator}
                denominator={data.denominator}
              />
            );
            feedbacks.fb2.body1 = `Here, ${data.numerator[0]} out of ${
              data.denominator
            } total ${getWord(data.numerator[0], "part")} ${getIsAre(
              data.numerator[0]
            )} shaded.`;
            feedbacks.fb2.body2 = `So, ${data.numerator[0]} is the numerator.`;

            //third box

            feedbacks.fb3.head = "You can do it!";
            feedbacks.fb3.body1 = `Count the shaded parts in the diagram.`;
            feedbacks.fb3.body3 = `Then try again.`;
          }
        }
      }
      if (data.level === 3) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator (${data.denominator[0]} total parts in the diagram).`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${
            data.numerator[0]
          } ${getWord(data.numerator[0], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${
            data.numerator[1]
          } ${getWord(data.numerator[1], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} ${
            data.correct_answer
          } ${data.numerator[1]}, ${data.numerator[0]}/${
            data.denominator[0]
          } ${getComparator(data.correct_answer)} ${data.numerator[1]}/${
            data.denominator[0]
          }`;
          feedbacks.fb3.body2 = `Or, ${data.numerator[0]}/${data.denominator[0]} ${data.correct_answer} ${data.numerator[1]}/${data.denominator[0]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator (${data.denominator[0]} total parts in the diagram).`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${
            data.numerator[0]
          } ${getWord(data.numerator[0], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${
            data.numerator[1]
          } ${getWord(data.numerator[1], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Which numerator is smaller?`;
          feedbacks.fb3.body2 = `Or, just compare the given diagrams visually.`;
        }
      }
      if (data.level === 4) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator (${data.denominator[0]} total parts in the diagram).`;
          feedbacks.fb1.body2 =
            "So, you can just add the numerators (shaded parts in the diagram).";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${
            data.numerator[0]
          } ${getWord(data.numerator[0], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${
            data.numerator[1]
          } ${getWord(data.numerator[1], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} + ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) + parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } + ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) + parseInt(data.numerator[1])
          }/${data.denominator[0]}`;
          feedbacks.fb3.body3 = `Or, you can just count the shaded parts and add them up.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator (${data.denominator[0]} total parts in the diagram).`;
          feedbacks.fb1.body2 =
            "So, you can just add the numerators (shaded parts in the diagram).";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${
            data.numerator[0]
          } ${getWord(data.numerator[0], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${
            data.numerator[1]
          } ${getWord(data.numerator[1], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Add the numerators correctly`;
          feedbacks.fb3.body2 = `Or, just count the shaded parts and add them up.`;
        }
      }
      if (data.level === 5) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator (${data.denominator[0]} total parts in the diagram).`;
          feedbacks.fb1.body2 =
            "So, you can just subtract the numerators (shaded parts in the diagram).";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${
            data.numerator[0]
          } ${getWord(data.numerator[0], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${
            data.numerator[1]
          } ${getWord(data.numerator[1], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} - ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) - parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } - ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) - parseInt(data.numerator[1])
          }/${data.denominator[0]}.`;
          feedbacks.fb3.body3 = `Or, you can just count the shaded parts and subtract them.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator (${data.denominator[0]} total parts in the diagram).`;
          feedbacks.fb1.body2 =
            "So, you can just compare the numerators (shaded parts in the diagram).";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${
            data.numerator[0]
          } ${getWord(data.numerator[0], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${
            data.numerator[1]
          } ${getWord(data.numerator[1], "part")} shaded out of ${
            data.denominator[0]
          }.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Subtract the numerators correctly.`;
          feedbacks.fb3.body2 = `Or, just count the shaded parts and subtract them.`;
        }
      }
    }
    if (data.lesson === 2) {
      if (data.level === 1) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} ${
            data.correct_answer
          } ${data.numerator[1]}, ${data.numerator[0]}/${
            data.denominator[0]
          } ${getComparator(data.correct_answer)} ${data.numerator[1]}/${
            data.denominator[0]
          }.`;
          feedbacks.fb3.body2 = `Or, ${data.numerator[0]}/${data.denominator[0]} ${data.correct_answer} ${data.numerator[1]}/${data.denominator[0]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Which numerator is smaller?`;
        }
      }
      if (data.level === 2) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} + ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) + parseInt(data.numerator[1])},.`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } + ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) + parseInt(data.numerator[1])
          }/${data.denominator[0]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just add the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Add the numerators correctly.`;
        }
      }
      if (data.level === 3) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just subtract the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.dia = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[0]}
              denominator={data.denominator}
            />
          );
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          feedbacks.fb2.dia1 = (
            <TapeGenerator
              small
              fb
              numerator={data.numerator[1]}
              denominator={data.denominator}
            />
          );

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `Since ${data.numerator[0]} - ${
            data.numerator[1]
          } = ${parseInt(data.numerator[0]) - parseInt(data.numerator[1])},`;

          feedbacks.fb3.body2 = `${data.numerator[0]}/${
            data.denominator[0]
          } - ${data.numerator[1]}/${data.denominator[0]} = ${
            parseInt(data.numerator[0]) - parseInt(data.numerator[1])
          }/${data.denominator[0]}`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have the same denominator, which is ${data.denominator[0]}.`;
          feedbacks.fb1.body2 = "So, you can just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `The first fraction has ${data.numerator[0]} as the numerator.`;
          feedbacks.fb2.body1 = `The second fraction has ${data.numerator[1]} as the numerator.`;
          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Subtract the numerators correctly.`;
        }
      }
      if (data.level === 4) {
        if (correct === true) {
          //first box
          feedbacks.fb1.head = "Good Job!";
          feedbacks.fb1.body1 = `The fractions have different denominators.`;
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `Both diagrams are the same size but are cut into different number of parts.`;
          feedbacks.fb2.body1 = `So, we can compare the fractions visually.`;

          //third box

          feedbacks.fb3.head = "You did it!";
          feedbacks.fb3.body1 = `The ${getLeftOrRight(
            data.correct_answer
          )} diagram has shades covering more area than the first diagram.`;

          feedbacks.fb3.body2 = `So, ${data.numerator[0]}/${data.denominator[0]} ${data.correct_answer} ${data.numerator[1]}/${data.denominator[1]}.`;
        } else {
          //first box
          feedbacks.fb1.head = "Good Try!";
          feedbacks.fb1.body1 = `The fractions have different denominators.`;
          feedbacks.fb1.body2 = "So, you cannot just compare the numerators.";
          //second box
          feedbacks.fb2.head = "How to Check?";
          feedbacks.fb2.body = `Both diagrams are the same size but are cut into different number of parts.`;
          feedbacks.fb2.body1 = `So, we can compare the fractions visually.`;
          //third box

          feedbacks.fb3.head = "You can do it!";
          feedbacks.fb3.body1 = `Which diagram has shades covering the most area?`;
        }
      }
    }
    setFeedback(feedbacks);
    setLoading(false);
  };

  return (
    loading == false && (
      <>
        <div onClick={toggleFeedback} className="quizModalCross">
          <img src={Close} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3
            style={{
              padding: 10,
              backgroundColor: "#69b8e2",
              color: "white",
              borderRadius: 10,
            }}
          >
            Know Why?
          </h3>
        </div>
        <div className="feedbackWrapper">
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb1.head}</div>
            <div className="fbbody">{feedback.fb1.body1}</div>
            <div className="fbbody">{feedback.fb1.body2}</div>
          </div>
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb2.head}</div>
            <div className="fbbody">{feedback.fb2.body}</div>
            <div className="fbBody">{feedback.fb2.dia}</div>
            <div className="fbbody">{feedback.fb2.body1}</div>
            <div className="fbBody">{feedback.fb2.dia1}</div>
            <div className="fbbody">{feedback.fb2.body2}</div>
          </div>
          <div className="feedbackGreyBox">
            <div className="fbhead">{feedback.fb3.head}</div>
            <div className="fbbody">{feedback.fb3.body1}</div>
            <div className="fbbody">{feedback.fb3.body2}</div>
            <div className="fbbody">{feedback.fb3.body3}</div>
          </div>
        </div>
      </>
    )
  );
}
