import React, { useState, useEffect } from "react";
import TapeGenerator from "../../../components/fractionsHelper/Tape";
export default function Fractions1SubQuestion({ currentQuestion }) {
  const renderQuestion = () => {
    if (currentQuestion.lesson == 1) {
      if (currentQuestion.level == 1) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
              <TapeGenerator
                numerator={currentQuestion.numerator[0]}
                denominator={currentQuestion.denominator[0]}
              />
            </div>
            <div className="comparisionBox">?</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[1]}
              </div>
              <TapeGenerator
                numerator={currentQuestion.numerator[1]}
                denominator={currentQuestion.denominator[1]}
              />
            </div>
          </div>
        );
      }
      if (currentQuestion.level == 2) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
              <TapeGenerator
                numerator={currentQuestion.numerator[0]}
                denominator={currentQuestion.denominator[0]}
              />
            </div>
            <div className="comparisionBox">?</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[1]}
              </div>
              <TapeGenerator
                numerator={currentQuestion.numerator[1]}
                denominator={currentQuestion.denominator[1]}
              />
            </div>
          </div>
        );
      }
      if (currentQuestion.level == 3) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
            </div>
            <div className="comparisionBox">+</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[0]}
              </div>
            </div>
          </div>
        );
      }
      if (currentQuestion.level == 4) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
            </div>
            <div className="comparisionBox">-</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[0]}
              </div>
            </div>
          </div>
        );
      }
    } else if (currentQuestion.lesson == 2) {
      if (currentQuestion.level == 1) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
            </div>
            <div className="comparisionBox">?</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[1]}
              </div>
            </div>
          </div>
        );
      }
      if (currentQuestion.level == 2) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
            </div>
            <div className="comparisionBox">?</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[1]}
              </div>
            </div>
          </div>
        );
      }
      if (currentQuestion.level == 3) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
            </div>
            <div className="comparisionBox">+</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[0]}
              </div>
            </div>
          </div>
        );
      }
      if (currentQuestion.level == 4) {
        return (
          <div className="multiBox">
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[0]}/{currentQuestion.denominator[0]}
              </div>
            </div>
            <div className="comparisionBox">-</div>
            <div className="singleBox">
              <div>
                {currentQuestion.numerator[1]}/{currentQuestion.denominator[0]}
              </div>
            </div>
          </div>
        );
      }
    } else if (currentQuestion.lesson == 3) {
      return (
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {currentQuestion.question}
        </div>
      );
    }
  };
  return renderQuestion();
}
