import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { URL } from "../../constants/url";
import { axios } from "../../lib/axios";
import Algebra1 from "./Algebra1/Algebra";
import Algebra2 from "./Algebra2/Algebra";
import Circuit from "./Circuit/Circuit";
import Fractions1 from "./Fractions1/Fractions";
import Fractions2 from "./Fractions2/Fractions";

export default function ReviewQuiz() {
  const { chaptername } = useParams();
  const [flag, setFlag] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Course Assessment - ${chaptername}`;
  }, []);

  function changeFlutterOrientation(orientationName) {
    try {
      Print.postMessage(orientationName);
    } catch {
      if (orientationName == "portrait") {
        window.screen.orientation.lock("portrait");
      } else {
        window.screen.orientation.lock("landscape");
      }
    }
  }

  useEffect(() => {
    window.onpopstate = () => {
      changeFlutterOrientation("portrait");
    };
  }, []);
  useEffect(() => {
    changeFlutterOrientation("landScape");
    getFlag();
  }, []);
  const getFlag = async () => {
    let res = await axios
      .get(`${URL.getCourseCode}?course_name=${chaptername}`)
      .catch((err) => {
        console.log(err);
      });
    setFlag(res.data.code);
    setLoading(false);
  };

  const renderQuiz = () => {
    switch (flag) {
      case "FRC001":
        return <Fractions1 />;
      case "FRC002":
        return <Fractions2 />;
      case "ALG001":
        return <Algebra1 />;
      case "ALG002":
        return <Algebra2 />;
      case "ECS001":
        return <Circuit />;
      case "ECS002":
        return <Circuit />;
      default:
        return (
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Coming Soon {":>"}
          </div>
        );
    }
  };
  return loading == false && renderQuiz();
}
