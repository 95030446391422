import React, { useEffect, useState } from "react";
import { Mascot } from "../../../assets/commonicons/CommonIcons";
import ProgressBar from "../../../components/progressbar/ProgressBar";
import Header from "../../../components/topbar/header/Header";
import "./mycourses.css";
// import Modal from "react-modal";
import { Button, Checkbox, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../components/modal/Modal";
import { URL } from "../../../constants/url";
import useAuth from "../../../hooks/useAuth";
import { axios } from "../../../lib/axios";

function MyCourses() {
  const { auth } = useAuth();

  useEffect(() => {
    document.title = `My Courses - ${auth.username}`;
  }, []);
  const navigate = useNavigate();
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [remainingCourses, setRemainingCourses] = useState([]);

  let modalStyles = {
    overlay: {
      position: "fixed",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      height: "80%",
      width: "100%",
      borderRadius: 15,
      top: "22%",
      justifyContent: "center",
      backgroundColor: "#f5f7fa",
      overflow: "none",
      zIndex: 1000,
    },
  };

  useEffect(() => {
    getMyCourses();
    getRemainingCourses();
  }, []);

  const handleCheckboxChange = (index) => {
    let temp = remainingCourses;
    temp[index].selected = !temp[index].selected;
    setRemainingCourses([...temp]);
  };

  const getMyCourses = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response = await axios
      .get(`${URL.getBookmarks}`, config)
      .catch((er) => console.log(er));
    if (response.success === true) {
      setCourseList(response.data);
      setLoading(false);
    }
  };
  const getRemainingCourses = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let response = await axios
      .get(`${URL.getRemainingBookmarks}`, config)
      .catch((er) => console.log(er));
    if (response.success === true) {
      response.data.map((item) => {
        item.selected = false;
      });
      setRemainingCourses(response.data);
    }
  };

  const toggleModal = () => {
    setModalVisible((modalVisible) => !modalVisible);
  };
  const addButtonHandler = async () => {
    let token = auth.accessToken;
    let config = { headers: { Authorization: `Bearer ${token}` } };
    let temp = [];
    remainingCourses.map((item) => {
      if (item.selected === true) {
        temp.push(item.name);
      }
    });
    let data = { course_names: temp };
    if (temp.length !== 0) {
      let response = await axios
        .post(`${URL.addBookmark}`, data, config)
        .catch((er) => console.log(er));
      if (response.success === true) {
        setLoading(true);
        getMyCourses();
        getRemainingCourses();
        toggleModal();
      }
    }
  };

  return loading === false ? (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <div className="coursesWrapper">
        <div className="screenCenter">
          <div className="head">
            <div>
              <h3>My Courses</h3>
            </div>
            <div className="addText" onClick={toggleModal}>
              <span>+ Add Courses</span>
            </div>
          </div>
          <div>
            {courseList.length === 0 ? (
              <EmptyState />
            ) : (
              <CourseList data={courseList} />
            )}
          </div>
        </div>
      </div>
      <Modal
        isVisible={modalVisible}
        onClose={toggleModal}
        className="custom-modal"

        // contentLabel="Courses Select Modal"
        // style={modalStyles}
      >
        <div className="modalContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div style={{ textAlign: "center", marginBottom: 20 }}>
              <h2>Add Courses</h2>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div className="head">
                <div>
                  <h3>Courses</h3>
                </div>
                <div className="addText" onClick={toggleModal}>
                  <span>Select All</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ maxHeight: 400 }} className="scrollableThingy noScroll">
            <div className="listWrapper">
              {remainingCourses.map((item, index) => {
                return (
                  <div className="listItemSmall">
                    <div>
                      <h3>{item.name}</h3>
                    </div>
                    <div>
                      <Checkbox
                        value={item.selected}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ position: "relative" }} className="head">
            <Button variant="outlined" onClick={toggleModal}>
              Cancel
            </Button>
            <Button variant="contained" onClick={addButtonHandler}>
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress size={30} />
      <h3 style={{ marginTop: 20 }}>Loading List...</h3>
    </div>
  );
}

export default MyCourses;

const EmptyState = () => {
  return (
    <div className="emptyListWrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div>
          <img src={Mascot} height={40} width={40} alt="" />
        </div>
        <div style={{ marginTop: 10 }}>
          <h3>No Courses</h3>
        </div>
        <div style={{ marginTop: 20 }}>
          <p>No course found. To add,</p>
          <p>tap on "Add Courses" button.</p>
        </div>
      </div>
    </div>
  );
};

const CourseList = (data) => {
  const navigate = useNavigate();
  return (
    <div className="listWrapper">
      {data.data.map((item) => {
        return (
          <div
            className="listItem pointer"
            onClick={() => navigate(`/coursedetails/${item.course_name}`)}
          >
            <div>
              <h3>{item.course_name}</h3>
            </div>
            <div style={{ marginTop: 10 }}>
              <c>Overall Progress: {item.progress ? item.progress : 0}%</c>
            </div>
            <div>
              <ProgressBar percentage={item.progress ? item.progress : 0} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
