import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  Close,
  Correct,
  Incorrect,
  Mascot,
  TryAgain,
} from "../../../../assets/commonicons/CommonIcons";
import FirstPage from "../../../../components/commonScreens/FirstPage";
import { URL } from "../../../../constants/url";
import { axios } from "../../../../lib/axios";
import Feedback from "../Feedback";
import "./wordproblemquiznew.css";

export default function WordproblemQuizNew({ lesson, onComplete }) {
  const { chaptername } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [subQuestionIndex, setSubQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState();
  const [checkCorrect, setCheckCorrect] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedVariable, setSelectedVariable] = useState();
  const [showFeedback, setShowFeedback] = useState();

  const toggleFeedback = () => {
    setShowFeedback(!showFeedback);
  };
  const renderFeedback = () => {
    let d = data[questionIndex];
    let variable = selectedVariable;
    let equation = d.equation.correct_option.replace("?", variable);
    let operator;
    if (d.equation.correct_option.includes("+")) {
      operator = "+";
    } else operator = "-";
    let equation_lhs = equation.split("=")[0];

    return (
      <Feedback
        selectedAnswer={selectedOption}
        toggleFeedback={toggleFeedback}
        data={data[questionIndex]}
        variable={variable}
        equation={equation}
        operator={operator}
        equation_lhs={equation_lhs}
        correct_answer={d.answer.correct_answer}
        word
      />
    );
  };
  const shuffleArray = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };
  const renderSubQuestion = () => {
    let d = data[questionIndex];
    if (subQuestionIndex == 0) {
      return (
        <>
          <div className="subQuestion1">
            a) Click on the right equation for the given problem.
          </div>
          <div style={{ marginTop: 20 }}>
            {d.simplified_question.map((item, index) => {
              return (
                <div key={index} className="subQuestion3">
                  {item}
                </div>
              );
            })}
          </div>
        </>
      );
    }
    if (subQuestionIndex == 1) {
      return (
        <>
          <div className="subQuestion1">
            b) Select a Variable for the given equation.
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="subQuestion2">{d.equation.correct_option}</div>
          </div>
        </>
      );
    }
    if (subQuestionIndex == 2) {
      return (
        <>
          <div className="subQuestion1">
            c) Click on the right value of {selectedVariable}.
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="subQuestion2">
              {d.equation.correct_option.replace("?", selectedVariable)}
            </div>
          </div>
        </>
      );
    }
    if (subQuestionIndex == 3) {
      return (
        <>
          <div className="subQuestion1">
            d) Click on the Correct Word Problem.
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="subQuestion2">
              With {selectedVariable} = {d.answer.correct_answer}
            </div>
            <div className="subQuestion2">
              {
                d.equation.correct_option
                  .replace("?", selectedVariable)
                  .split("=")[0]
              }{" "}
              = {d.equation.correct_equation}
            </div>
            <div className="subQuestion2">
              So, {d.answer.correct_answer} is the correct answer.
            </div>
          </div>
        </>
      );
    }
  };
  const getOptionIndex = (index) => {
    switch (index) {
      case 0:
        return "A";
      case 1:
        return "B";
      case 2:
        return "C";
      case 3:
        return "D";
    }
  };
  let modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    content: {
      margin: "auto",
      borderRadius: 15,
      height: "70%",
      width: "80%",
      justifyContent: "center",
      alignSelf: "center",
      overflow: "none",
      zIndex: 1000,
    },
  };
  const checkButtonRender = () => {
    if (subQuestionIndex == 1) {
      if (selectedOption == null) {
        return <div className={"checkButtonGrey pointer"}>Check</div>;
      } else {
        return (
          <div
            onClick={handleContinue}
            className={"checkCorrectButton pointer"}
          >
            Continue
          </div>
        );
      }
    } else {
      if (checkCorrect == true) {
        return (
          <div onClick={handleContinue} className="checkCorrectButton pointer">
            Continue
          </div>
        );
      } else if (selectedOption == null) {
        return <div className="checkButtonGrey pointer">Check</div>;
      } else if (checkCorrect == false) {
        return (
          <div
            onClick={() => {
              setSelectedOption();
              setCheckCorrect();
            }}
            className="checkIncorrectButton pointer"
          >
            <div>
              <img src={TryAgain} />
            </div>
            <div style={{ marginLeft: 10 }}>Try Again</div>
          </div>
        );
      } else if (selectedOption != null) {
        return (
          <div onClick={checkClicked} className="checkButton pointer">
            Check
          </div>
        );
      }
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  const optionIndexStyle = (item) => {
    if (item == selectedOption) {
      if (checkCorrect == true) {
        return "optionIndex optionIndexCorrect";
      } else if (checkCorrect == false) {
        return "optionIndex optionIndexIncorrect";
      } else {
        return "optionIndex optionIndexSelected";
      }
    } else {
      return "optionIndex";
    }
  };
  const optionStyle = (item) => {
    if (item == selectedOption) {
      if (checkCorrect == true) {
        return "option optionCorrect";
      } else if (checkCorrect == false) {
        return "option optionIncorrect";
      } else {
        return "option optionSelected";
      }
    } else {
      return "option";
    }
  };
  const renderOptions = () => {
    let d = data[questionIndex];
    let variables = ["a", "x", "y", "t"];

    if (subQuestionIndex == 0) {
      return (
        <>
          {d.equation.options.map((item, index) => {
            return (
              <div
                key={index}
                className={optionStyle(item)}
                onClick={() => {
                  if (checkCorrect == null || checkCorrect == false) {
                    setSelectedOption(item);
                    setCheckCorrect();
                  }
                }}
              >
                <div className={optionIndexStyle(item, 1)}>
                  {getOptionIndex(index)}
                </div>
                <div style={{ width: "85%" }}>{item}</div>
                <div></div>
              </div>
            );
          })}
        </>
      );
    }
    if (subQuestionIndex == 1) {
      return (
        <>
          {variables.map((item, index) => {
            return (
              <div
                key={index}
                className={optionStyle(item)}
                onClick={() => {
                  if (checkCorrect == null || checkCorrect == false) {
                    setSelectedOption(item);
                    setCheckCorrect();
                  }
                }}
              >
                <div className={optionIndexStyle(item, 1)}>
                  {getOptionIndex(index)}
                </div>
                <div style={{ width: "85%" }}>{item}</div>
                <div></div>
              </div>
            );
          })}
        </>
      );
    }
    if (subQuestionIndex == 2) {
      return (
        <>
          {d.answer.options.map((item, index) => {
            return (
              <div
                key={index}
                className={optionStyle(item)}
                onClick={() => {
                  if (checkCorrect == null || checkCorrect == false) {
                    setSelectedOption(item);
                    setCheckCorrect();
                  }
                }}
              >
                <div className={optionIndexStyle(item, 1)}>
                  {getOptionIndex(index)}
                </div>
                <div style={{ width: "85%" }}>{item}</div>
                <div></div>
              </div>
            );
          })}
        </>
      );
    }
    if (subQuestionIndex == 3) {
      return (
        <>
          {d.word_answer.options.map((item, index) => {
            return (
              <div
                key={index}
                className={optionStyle(item)}
                onClick={() => {
                  if (checkCorrect == null || checkCorrect == false) {
                    setSelectedOption(item);
                    setCheckCorrect();
                  }
                }}
              >
                <div className={optionIndexStyle(item, 1)}>
                  {getOptionIndex(index)}
                </div>
                <div style={{ width: "85%" }}>{item}</div>
                <div></div>
              </div>
            );
          })}
        </>
      );
    }
  };

  const checkClicked = () => {
    let d = data[questionIndex];
    let correct = new Audio("/correct.mp3");
    let incorrect = new Audio("/incorrect.mp3");
    if (selectedOption == null) {
      return;
    }
    if (subQuestionIndex == 0) {
      let correctAnswer = d.equation.correct_option;
      if (correctAnswer == selectedOption) {
        correct.play();
        setCheckCorrect(true);
      } else {
        incorrect.play();
        setCheckCorrect(false);
      }
    }
    if (subQuestionIndex == 2) {
      let correctAnswer = d.answer.correct_answer;
      if (correctAnswer == selectedOption) {
        correct.play();
        setCheckCorrect(true);
      } else {
        incorrect.play();
        setCheckCorrect(false);
      }
    }
    if (subQuestionIndex == 3) {
      let correctAnswer = d.word_answer.correct_answer;
      if (correctAnswer == selectedOption) {
        correct.play();
        setCheckCorrect(true);
      } else {
        incorrect.play();
        setCheckCorrect(false);
      }
    }
  };
  const renderCorrectModal = () => {
    if (checkCorrect == true) {
      return "correctModal";
    } else if (checkCorrect == false) {
      return "incorrectModal";
    } else {
      return "hideModal";
    }
  };
  const handleContinue = () => {
    if (questionIndex == data.length - 1 && subQuestionIndex == 3) {
      onComplete();
    }
    if (subQuestionIndex == 1) {
      selectedOption != null && setSelectedVariable(selectedOption);
      selectedOption != null && setSubQuestionIndex(subQuestionIndex + 1);
      setCheckCorrect();
      setSelectedOption();
    }
    if (subQuestionIndex < 3) {
      selectedOption != null && setSubQuestionIndex(subQuestionIndex + 1);
      setCheckCorrect();
      setSelectedOption();
    }
    if (subQuestionIndex == 3) {
      selectedOption != null && setSubQuestionIndex(0);
      setCheckCorrect();
      setSelectedOption();
      setQuestionIndex(questionIndex + 1);
    }
  };
  const fetchQuestions = async () => {
    let response = await axios
      .get(
        `${URL.lessonReview}?course_name=${chaptername}&lesson_number=${lesson}`
      )
      .catch((err) => console.log(err));

    response.data.map((item) => {
      item.equation.options = shuffleArray(item.equation.options);
      item.answer.options = shuffleArray(item.answer.options);
      item.word_answer.options = shuffleArray(item.word_answer.options);
    });
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchQuestions();
  }, []);

  const renderBottom = () => {
    return (
      <div className="bottom">
        <div className="subQuestionContainer">{renderSubQuestion()}</div>
        <div className="options">{renderOptions()}</div>
        <div className="checkButtonContainer">
          <div className={renderCorrectModal()}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={checkCorrect ? Correct : Incorrect} />
              <div style={{ marginLeft: 10 }}>
                {checkCorrect
                  ? "Awesome! Right Answer"
                  : "Good Try! But Wrong Answer"}
              </div>
            </div>
          </div>
          {checkButtonRender()}
        </div>
        <div className="count">
          {questionIndex + 1}/{data.length}
        </div>
        {subQuestionIndex == 2 && (
          <div
            onClick={() => {
              if (checkCorrect != null) {
                toggleFeedback();
              }
            }}
            className={"feedbackButton pointer"}
          >
            <div
              className={
                checkCorrect == null
                  ? ""
                  : checkCorrect == true
                  ? "pulseCorrect"
                  : "pulseIncorrect"
              }
            ></div>
            <img src={Mascot} style={{ height: "100%", width: "100%" }} />
          </div>
        )}
        <Modal
          isOpen={showFeedback}
          onRequestClose={toggleFeedback}
          contentLabel="My dialog"
          style={modalStyles}
        >
          {renderFeedback()}
        </Modal>
      </div>
    );
  };

  return (
    loading == false && (
      <>
        <div className="quizContainer">
          <div className="quiz-Header">
            <img onClick={goBack} className="cross pointer" src={Close} />

            <div className="mainQuestion">
              {questionIndex + 1}) {data[questionIndex].question}
            </div>
          </div>
          {renderBottom()}
        </div>
      </>
    )
  );
}
