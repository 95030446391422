import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { BadgeOne, BadgeThree, BadgeTwo, CoinIcon } from '../../assets/commonicons/CommonIcons';
import { GirlImage, IllustrationImage } from '../../assets/images';
import './exploreslider.css';

function ExploreSlider() {
    const navigate = useNavigate();
    const settings = {
        arrows: true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    initialSlide: 0,
                },
            },
        ],
    };
    let list = [
        {
            head: 'Track your Progress',
            body: 'Sign up to create learners’ profiles. \n Add courses to profiles and track the progress.',
            sliderImage: IllustrationImage,
        },
        {
            head: 'Collect points and badges',
            body: 'Sign up to complete the course and earn points and badges.',
            sliderImage: {
                slide1: CoinIcon,
                slide2: BadgeOne,
                slide3: BadgeTwo,
                slide4: BadgeThree,
            },
        },
    ];

    return (
        <div className='explorediv'>
            <div className='screenCenter'>
                <div style={{ marginBottom: 30 }}>
                    <h1>Explore</h1>
                </div>
                <Slider style={{ marginLeft: 10, marginRight: 10 }} {...settings}>
                    {list.map((a) => {
                        const TextBody = () => {
                            const textSelect = a.body;
                            const text = textSelect.replace(/\n/g, '<br/>');

                            return <div dangerouslySetInnerHTML={{ __html: text }} />;
                        };

                        return (
                            <div className='d-flex'>
                                <div
                                    className='exploreCard'
                                    style={{
                                        width: '50%',
                                    }}>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <h2>{a.head}</h2>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            margin: '20px auto auto',
                                            width: '80%',
                                        }}>
                                        <p>
                                            <TextBody />
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className='imageCard'
                                    style={{
                                        width: '50%',
                                    }}>
                                    <img className='slide' src={a.sliderImage} alt='' />

                                    <div className='badge-groups'>
                                        <div className='flex-holder'>
                                            <img className='slide1' src={a.sliderImage?.slide1} alt='' />
                                        </div>
                                        <div className='flex-holder'>
                                            <img className='slide2' src={a.sliderImage?.slide2} alt='' />
                                        </div>
                                        <div className='flex-holder'>
                                            <img className='slide3' src={a.sliderImage?.slide3} alt='' />
                                        </div>
                                        <div className='flex-holder'>
                                            <img className='slide4' src={a.sliderImage?.slide4} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
                <div className='exploreButtons'>
                    <div>
                        <Button onClick={() => navigate(`/login`)} variant='contained'>
                            Login
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => navigate(`/register`)} variant='outlined'>
                            Sign Up
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExploreSlider;
