import React from 'react';
import { BadgeOne, BadgeThree, BadgeTwo, Mascot } from '../../assets/commonicons/CommonIcons';
import Confetti from '../../components/Confettie';
import useAuth from '../../hooks/useAuth';

const ConditionalBadgesText = ({ score }) => {
    const { correct, total } = score;
    const scoreCheck = correct / total;

    const { auth } = useAuth();

    const { userType } = auth;

    const isChild = userType === 'child';

    const ConditionalConfetti = () => {
        switch (true) {
            case scoreCheck >= 0.3:
                return <Confetti />;
            default:
                return false;
        }
    };

    const ConditionalBadges = () => {
        if (!isChild) {
            return <img src={Mascot} alt='mascot' />;
        }
        switch (true) {
            case scoreCheck >= 0.8:
                return <img style={{ width: '130px' }} src={BadgeOne} alt='badgeSuperior' />;
            case scoreCheck >= 0.6:
                return <img style={{ width: '130px' }} src={BadgeTwo} alt='badgeSilver' />;
            case scoreCheck >= 0.3:
                return <img style={{ width: '130px' }} src={BadgeThree} alt='badgeBronze' />;
            default:
                return <img src={Mascot} alt='mascot' />;
        }
    };

    const ConditionalFinalTextNormalUser = () => {
        if (isChild) return false;
        switch (true) {
            case scoreCheck >= 0.8:
                return (
                    <p style={{ textAlign: 'center' }}>Great job trying out this Course Assessment! Keep up the excellent effort!</p>
                );
            case scoreCheck >= 0.6:
                return (
                    <p style={{ textAlign: 'center' }}>
                        Great job trying out this Course Assessment! Keep it up and you'll achieve higher scores in no time.
                    </p>
                );
            case scoreCheck >= 0.3:
                return (
                    <p style={{ textAlign: 'center' }}>
                        Great job trying out this Course Assessment! Keep it up and you'll achieve higher scores in no time.
                    </p>
                );
            default:
                return (
                    <p style={{ textAlign: 'center' }}>
                        Great job trying out this Course Assessment! Keep working hard to score higher.
                    </p>
                );
        }
    };

    const ConditionalFinalText = () => {
        if (!isChild) return false;
        switch (true) {
            case scoreCheck >= 0.8:
                return (
                    <p style={{ textAlign: 'center' }}>
                        Congratulations! <br /> You've earned a Snow Leopard badge.
                    </p>
                );
            case scoreCheck >= 0.6:
                return (
                    <p style={{ textAlign: 'center' }}>
                        Congratulations! <br /> You've earned a Red Panda badge.
                    </p>
                );
            case scoreCheck >= 0.3:
                return (
                    <p style={{ textAlign: 'center' }}>
                        Congratulations! <br /> You've earned a One-horned Rhino badge.
                    </p>
                );
            default:
                return (
                    <p style={{ textAlign: 'center' }}>
                        Great job trying out this Course Assessment! <br /> Keep working hard to earn badges by achieving higher scores
                    </p>
                );
        }
    };
    return { ConditionalConfetti, ConditionalBadges, ConditionalFinalText, ConditionalFinalTextNormalUser };
};

export default ConditionalBadgesText;
