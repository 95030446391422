import React from "react";

export default function Progress({ progress }) {
  let actualWidth = (progress / 100) * 307 + 36;
  let car = actualWidth;
  let blueLine = progress != 100 ? car - 36 : car;
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="32.999"
        viewBox="0 0 361.079 32.999"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.974"
            y1="0.5"
            x2="0.582"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#048bff" />
            <stop offset="0.473" stop-color="#3495e9" />
            <stop offset="1" stop-color="#0069c4" />
          </linearGradient>
        </defs>
        <g
          id="Group_1815"
          data-name="Group 1815"
          transform="translate(-36 -370.001)"
        >
          <g id="Group_1808" data-name="Group 1808" transform="translate(-2 8)">
            <rect
              id="Rectangle_893"
              data-name="Rectangle 893"
              width="343"
              height="11"
              rx="5.5"
              transform="translate(38 384)"
              fill="#bbbbbf"
            />
            <rect
              id="Rectangle_894"
              data-name="Rectangle 894"
              width={blueLine}
              height="11"
              rx="4"
              transform="translate(38 384)"
              fill="url(#linear-gradient)"
            />
            <g
              id="Group_1777"
              data-name="Group 1777"
              transform="translate(-17.542 -30.885)"
            >
              <path
                id="Path_2520"
                data-name="Path 2520"
                d="M0,0H12"
                transform="translate(116.494 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_65"
                data-name="Line 65"
                x2="12"
                transform="translate(144.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_66"
                data-name="Line 66"
                x2="12"
                transform="translate(172.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_67"
                data-name="Line 67"
                x2="12"
                transform="translate(201.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_68"
                data-name="Line 68"
                x2="12"
                transform="translate(229.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_69"
                data-name="Line 69"
                x2="12"
                transform="translate(257.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_71"
                data-name="Line 71"
                x2="12"
                transform="translate(285.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_72"
                data-name="Line 72"
                x2="12"
                transform="translate(314.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_81"
                data-name="Line 81"
                x2="12"
                transform="translate(60.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_73"
                data-name="Line 73"
                x2="12"
                transform="translate(343.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_82"
                data-name="Line 82"
                x2="12"
                transform="translate(87.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_74"
                data-name="Line 74"
                x2="12"
                transform="translate(370.542 420.885)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
            </g>
          </g>
          <g
            id="Group_1803"
            data-name="Group 1803"
            transform="translate(-0.079 4.625)"
          >
            <circle
              id="Ellipse_198"
              data-name="Ellipse 198"
              cx="5"
              cy="5"
              r="5"
              transform="translate(369 386.659)"
              fill="#fceb00"
            />
            <path
              id="Icon_awesome-flag-checkered"
              data-name="Icon awesome-flag-checkered"
              d="M13.239,10.234V13.9c1.407.318,2.657.841,3.967,1.2V11.431c-1.4-.313-2.662-.835-3.967-1.2ZM25.274,3.6a15.738,15.738,0,0,1-6.306,1.719c-2.883,0-5.271-1.876-8.9-1.876A10.479,10.479,0,0,0,6.4,4.1a3.019,3.019,0,1,0-4.543,1.4V26.3A1.29,1.29,0,0,0,3.15,27.594h.862A1.29,1.29,0,0,0,5.305,26.3V21.213a15.027,15.027,0,0,1,6.166-1.191c2.889,0,5.271,1.876,8.9,1.876a11.262,11.262,0,0,0,6.6-2.2,1.719,1.719,0,0,0,.744-1.423V5.168A1.722,1.722,0,0,0,25.274,3.6Zm-16,13.938a16.979,16.979,0,0,0-3.967.895v-3.8A15.419,15.419,0,0,1,9.272,13.7Zm15.867-7.249a17.192,17.192,0,0,1-3.967,1.288v3.832a10.017,10.017,0,0,0,3.967-1.4v3.8a8.709,8.709,0,0,1-3.967,1.461V15.413a9.116,9.116,0,0,1-3.967-.3v3.633A31.446,31.446,0,0,0,13.239,17.6V13.9a11.98,11.98,0,0,0-3.967-.2V9.927a19.019,19.019,0,0,0-3.967,1.126v-3.8A15.434,15.434,0,0,1,9.272,6.068V9.927a9.167,9.167,0,0,1,3.967.307V6.6a30.682,30.682,0,0,0,3.967,1.148v3.686a10.267,10.267,0,0,0,3.967.146V7.706a19.069,19.069,0,0,0,3.967-1.213Z"
              transform="translate(369.438 365.379)"
              fill="#102436"
            />
          </g>
          <path
            id="Icon_awesome-car-side"
            data-name="Icon awesome-car-side"
            d="M20.846,8.646h-.613L16.064,3.21a2.417,2.417,0,0,0-1.915-.96h-8.2A2.455,2.455,0,0,0,3.675,3.858L1.839,8.736A2.535,2.535,0,0,0,0,11.2v4.477a.627.627,0,0,0,.613.64H2.452a3.76,3.76,0,0,0,3.679,3.837A3.76,3.76,0,0,0,9.81,16.32h4.9a3.682,3.682,0,1,0,7.357,0h1.839a.627.627,0,0,0,.613-.64v-3.2A3.76,3.76,0,0,0,20.846,8.646ZM6.131,18.239a1.92,1.92,0,0,1,0-3.837,1.92,1.92,0,0,1,0,3.837ZM8.89,8.646H4.481L5.952,4.808H8.89Zm1.839,0V4.808h3.42l2.943,3.837Zm7.664,9.593a1.92,1.92,0,1,1,1.839-1.919A1.882,1.882,0,0,1,18.393,18.239Z"
            transform={`translate(${car} 382.842)`}
            fill="#102436"
          />
        </g>
      </svg>
    </div>
  );
}
