import EyeClose from "./hidden_icon.svg";
import Eye from "./open_eye_icon.svg";
import Lock from "./lock_icon.svg";
import Name from "./name_icon.svg";
import Username from "./username_icon.svg";
import Email from "./email_icon.svg";
import InfoColored from "./info_colored.svg"
import Info from "./info_icon_small.svg"

export { EyeClose, Eye, Lock, Name, Username, Email, Info, InfoColored };
