import React from 'react';
import { Modal } from './Modal';

const PasswordModal = ({ isPasswordInfo, onPasswordInfoClose }) => {
    return (
        <Modal isVisible={isPasswordInfo} className='custom-modal'>
            <div className='modal_Holder'>
                <strong>Password Criteria</strong>
                <ul className='list-dashed'>
                    <li>Password should be of minimum 8 characters</li>
                    <li>Password should have at least one digit</li>
                    <li>Password should have at lease one character</li>
                    <li>Password should have at least one special character</li>
                </ul>
                <div className='button_Holder'>
                    <button className='modal_Close' onClick={onPasswordInfoClose}>
                        Ok
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default PasswordModal;
