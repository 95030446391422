import Swal from "sweetalert2";

export const successHandler = (successText) => {
    Swal.fire({
        title: "Success!",
        text: successText,
        icon: "success",
        confirmButtonText: "Ok",
      });
}

export const errorHandler = (errorText) => {
    Swal.fire({
        title: "Error!",
        text: errorText,
        icon: "error",
        confirmButtonText: "Ok",
      });
}
