import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./chapterintro.css";
// import girlimage from "../../assets/images/OLELOGO.png";
import { useEffect, useState } from "react";
import { Back, Mascot } from "../../assets/commonicons/CommonIcons";
import {
  DocumentIcon,
  GameIcon,
  VideoIcon,
} from "../../assets/coursedetails/CourseDetailsIcons";

const UnitFirstPage = ({
  LoadChapter,
  type,
  title,
  description,
  point,
  duration,
  goBack,
}) => {
  return (
    <div className="intro_container">
      <div className="content-holder d-flex">
        <div className="intro_title">{<h1>{title}</h1>}</div>
        <div className="intro_body_container">
          <div className="intro_body">
            <div className="intro_body_part intro_body_img">
              <img src={Mascot} alt="girl_image" className="girl_image" />
            </div>
            <div className="intro_body_part intro_body_content">
              <div className="discription">
                <p>{description}</p>
              </div>
              <div className="dic_time_points">
                <p>
                  {type == "EP" ? (
                    <img
                      src={DocumentIcon}
                      alt="chapter_icon"
                      className="reading_icons"
                    />
                  ) : type == "GA" ? (
                    <img
                      src={GameIcon}
                      alt="chapter_icon"
                      className="reading_icons"
                    />
                  ) : type == "VD" ? (
                    <img
                      src={VideoIcon}
                      alt="chapter_icon"
                      className="reading_icons"
                    />
                  ) : (
                    ""
                  )}
                  <span>{duration == "NA" ? "" : `${duration} mins`}</span> |{" "}
                  <span>
                    {type == "EP"
                      ? "E-Paath |"
                      : type == "GA"
                      ? "Game |"
                      : type == "VD"
                      ? "Video |"
                      : ""}
                  </span>{" "}
                  <span>{point} points</span>
                </p>
              </div>

              {type == "VD" && (
                <div style={{ fontSize: 14, marginTop: 20 }}>
                  Note: All Khan Academy content is available for free at{" "}
                  <span
                    onClick={() =>
                      window.open(`https://www.khanacademy.org`, "_blank")
                    }
                  >
                    (www.khanacademy.org)
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="continue_btn_container">
          <div onClick={LoadChapter} className="continuebutton blue pointer">
            Continue
          </div>
        </div>
      </div>
      <div onClick={goBack} className="cross_btn">
        <img src={Back} alt="girl_image" className="girl_image pointer" />
      </div>
    </div>
  );
};

export default UnitFirstPage;
