import { axios } from "../../lib/axios";
import { useState, useEffect } from "react";
import AL2E from "./LessonreviewMath/EquationQuiz/EquationQuizNew";
import AL2W from "./LessonreviewMath/WordproblemQuiz/WordproblemQuizNew";
import FL1 from "./Fractions/Lesson1/FractionsLesson1";
import FL2 from "./Fractions/Lesson2/FractionsLesson2";
import F2L1 from "./Fractions2/Lesson1/Fractions2Lesson1";
import F2L2 from "./Fractions2/Lesson2/Fractions2Lesson2";
import F2L3 from "./Fractions2/Lesson3/Fractions2Lesson3";
import CC1 from "./Circuits/Course1/Lesson1/CircuitsCourse1";

export default function LessonReview({ flag, uid, onComplete }) {
  const [loading, setLoading] = useState(true);
  const [lesson, setLesson] = useState();

  useEffect(() => {
    getLessonNumber();
  }, []);

  const getLessonNumber = async () => {
    let response = await axios.get(`course/lesson-number?unit_uid=${uid}`);
    setLesson(response.data.lesson_number);
    setLoading(false);
  };
  const renderQuiz = () => {
    switch (flag) {
      case "ALG2_WP_2":
        return <AL2W lesson={lesson} onComplete={onComplete} />;
      case "ALG2_WP_1":
        return <AL2W lesson={lesson} onComplete={onComplete} />;
      case "ALG2_EQ_1":
        return <AL2E lesson={lesson} onComplete={onComplete} />;
      case "ALG2_EQ_2":
        return <AL2E lesson={lesson} onComplete={onComplete} />;
      case "FRC001_LR1":
        return <FL1 lesson={lesson} onComplete={onComplete} />;
      case "FRC001_LR2":
        return <FL2 lesson={lesson} onComplete={onComplete} />;
      case "FRC002_LR1":
        return <F2L1 lesson={lesson} onComplete={onComplete} />;
      case "FRC002_LR2":
        return <F2L2 lesson={lesson} onComplete={onComplete} />;
      case "FRC002_LR3":
        return <F2L3 lesson={lesson} onComplete={onComplete} />;
      case "ECS001_LR1":
        return <CC1 lesson={lesson} onComplete={onComplete} />;
      case "ECS001_LR2":
        return <CC1 lesson={lesson} onComplete={onComplete} />;
      case "ECS002_LR1":
        return <CC1 lesson={lesson} onComplete={onComplete} />;
      case "ECS002_LR2":
        return <CC1 lesson={lesson} onComplete={onComplete} />;
      default:
        return (
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Coming Soon {":>"}
          </div>
        );
    }
  };
  return loading == false && renderQuiz();
}
