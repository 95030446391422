import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./chapterintro.css";
// import girlimage from "../../assets/images/OLELOGO.png";
import { useEffect, useState } from "react";
import {
  Close,
  Info,
  Mascot,
  RewardCoin,
} from "../../assets/commonicons/CommonIcons";
import AudioPlay from "../../components/AudioPlay";
import Confetti from "../../components/Confettie";
import { Modal } from "../../components/modal/Modal";
import useAuth from "../../hooks/useAuth";
import { addInfoHandler, removeInfoHandler } from "../../lib/formValidation";
import BadgePointPage from "../badges/BadgesPoints";

const PageEnd = ({
  point,
  restart,
  continueAll,
  closeEvent,
  totalPoints,
  text,
}) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [isBadge, setisBadge] = useState(false);
  useEffect(() => {
    let modal = document.querySelector(".overlay");
    window.onclick = function (event) {
      if (event.target === modal) {
        setisBadge(false);
        document.body.classList.remove("modal-open");
      }
    };
  }, [isBadge]);

  const badgeOpen = () => addInfoHandler(setisBadge);
  const badgeClose = () => removeInfoHandler(setisBadge);
  return (
    <>
      <div className="intro_container">
        <div className="page_body_container">
          <div style={{ maxWidth: 700 }} className="page_body">
            <div className="top_part">
              {auth.accessToken && auth.userType === "child" && (
                <>
                  <div className="points_part">
                    <img className="point" src={RewardCoin} />
                    <p>{totalPoints}</p>
                  </div>
                  <div className="point_description pointer">
                    <p>
                      <img className="info" src={Info} />
                      <a onClick={badgeOpen}>Learn about badges and points</a>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="close_icon pointer" onClick={closeEvent}>
              <img className="closeicon_img" src={Close} />
            </div>
            <div className="lastpage_body_part intro_body_img">
              <img src={Mascot} alt="girl_image" className="girl_image" />
            </div>
            <div className="lastpage_body_part lastpage_body_content">
              <div className="lstpg_discription">
                <h1>Good job!</h1>
                {text ? (
                  <h2>{text}</h2>
                ) : (
                  <h2>You have completed this unit.</h2>
                )}
                {auth.accessToken && auth.userType === "child" && (
                  <>
                    <div className="points_part">
                      <img className="point" src={RewardCoin} />
                      <p>{point}</p>
                    </div>
                  </>
                )}
              </div>
              <div className="lastpg_btn_container">
                <div
                  onClick={restart}
                  className="lstpg_continuebutton restart_btn pointer"
                >
                  Restart
                </div>
                <div
                  onClick={continueAll}
                  className="lstpg_continuebutton countinue_btn pointer"
                >
                  Continue
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="continue_btn_container">
          <div onClick="" className="continuebutton blue">Continue</div>
        </div>
        <div onClick={goBack} className="cross_btn">
          <img src={Back} alt="girl_image" className="girl_image" />
        </div> */}
      </div>
      <Modal isVisible={isBadge} className="custom-modal badge">
        <div className="modal_Holder">
          <BadgePointPage clickHandleFun={badgeClose} />
          <div className="button_Holder">
            <button className="modal_Close" onClick={badgeClose}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PageEnd;
