import { GirlStart } from "../../assets/commonicons/CommonIcons";
import React, { useEffect, useState } from "react";
import "./lastpage.css";

const FirstPage = ({ startQuiz, type }) => {
  const [texts, setTexts] = useState({});

  useEffect(() => {
    setText();
  }, []);

  const setText = () => {
    let text = { head: "", p1: "", p2: "" };
    if (type == "lesson") {
      text.head = "Ready for Lesson Review?";
      text.p1 = "Complete the Lesson Review and earn points.";
      text.p2 =
        "Take the Lesson Review and assess your understanding of the contents covered in the lesson.";
    } else if (type == "post") {
      text.head = "How much have I learnt?";
      text.p1 =
        "Put everything you have learned to the test and show what you know.";
      text.p2 = "10 questions | 15-20 minutes | 20 points per question";
    } else if (type == "pre") {
      text.head = "How much do I already know?";
      text.p1 =
        "Before you start this course, check how much you already know on this topic. ";
      text.p2 = "10 questions | 15-20 minutes | 20 points per question";
    }
    setTexts(text);
  };

  return (
    <>
      <div className="First_pg_container">
        <div className="text_container">
          <h2>{texts.head}</h2>
          <p>{texts.p1}</p>
          <p>{texts.p2}</p>
        </div>
        <div className="image_container">
          <img alt="girl_images" src={GirlStart} />
        </div>
        <div className="Quiz_start_btn" onClick={startQuiz}>
          I am ready!
        </div>
      </div>
    </>
  );
};

export default FirstPage;
