import React, { useState, useEffect } from "react";
import ButtonLoading from "../../components/button/ButtonLoading";
import TextBox from "../../components/textBox/TextBox";
import { ERROR_MSG } from "../../constants/ErrorMessage";
import { axios } from "../../lib/axios";
import { URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";
import { CircularProgress } from "@mui/material";
import { errorHandler } from "../../lib/serverHandler";

const ChildAccountEdit = ({
  error,
  formData,
  nonFormData,
  setNonFormData,
  setFormData,
  changeDataHandler,
  validateForm,
  handleEditSuccess,
  child,
}) => {
  const [childUpdateLoading, setChildUpdateLoading] = useState(false);
  const [validTruth, setValidTruth] = useState(false);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();

  useEffect(() => {
    console.log(child);
    getChildInfo();
  }, []);

  const getChildInfo = async () => {
    let { accessToken } = auth;
    let config = { headers: { Authorization: `Bearer ${accessToken}` } };
    let response = await axios.get(
      `${URL.viewChild}?child_username=${child.username}`,
      config
    );
    if (response.success === true) {
      console.log(response.data);

      let firstName = response.data.full_name.split(" ")[0];
      let lastName = response.data.full_name.split(" ")[1];
      let username = response.data.username;
      let pin = response.data.pin;

      setFormData({
        ...formData,
        username: username,
        pin: pin,
        confirm_pin: pin,
      });
      setNonFormData({
        ...nonFormData,
        firstName: firstName,
        lastName: lastName,
      });

      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setChildUpdateLoading(true);
    try {
      const response = await axios.post(
        URL.updateChild,
        {
          ...formData,
          first_name: nonFormData.firstName,
          last_name: nonFormData.lastName,
          child_username: formData.username,
          pin: formData.pin,
          confirm_pin: formData.confirm_pin,
        },
        {
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      );
      console.log(response);
      setChildUpdateLoading(false);
      handleEditSuccess();
    } catch (err) {
      errorHandler(err?.response?.data?.message);
      setChildUpdateLoading(false);
    }
  };
  return (
    <>
      {loading === false ? (
        <form className="commonForm">
          <div className="commonFormTop">
            <h2 style={{ marginTop: 10 }}>Account Information</h2>
          </div>
          <div style={{ marginTop: 20 }}>
            <TextBox
              icon="name"
              placeholder="First Name*"
              error={error.firstName}
              value={nonFormData.firstName}
              onChange={(e) => {
                changeDataHandler(
                  "firstName",
                  e.target.value,
                  "First Name required"
                );
                setNonFormData({
                  ...nonFormData,
                  firstName: e.target.value,
                });
              }}
            />
            <TextBox
              icon="name"
              placeholder="Last Name*"
              error={error.lastName}
              value={nonFormData.lastName}
              onChange={(e) => {
                changeDataHandler(
                  "lastName",
                  e.target.value,
                  ERROR_MSG.emptyLastName
                );
                setNonFormData({
                  ...nonFormData,
                  lastName: e.target.value,
                });
              }}
            />
            <TextBox
              icon="username"
              placeholder="Username*"
              error={error.username}
              disabled
              style={{ color: "grey" }}
              value={formData.username}
              onChange={(e) => {
                changeDataHandler(
                  "username",
                  e.target.value,
                  ERROR_MSG.emptyUser
                );
                setFormData({ ...formData, username: e.target.value });
              }}
            />
            <TextBox
              icon="lock"
              placeholder="PIN (6 digit no)*"
              protected
              error={error.pin}
              value={formData.pin}
              onChange={(e) => {
                changeDataHandler("pin", e.target.value, ERROR_MSG.emptyPin);
                setFormData({ ...formData, pin: e.target.value });
              }}
            />
            <TextBox
              icon="lock"
              placeholder="Confirm PIN*"
              protected
              error={error.confirm_pin}
              value={formData.confirm_pin}
              onChange={(e) => {
                changeDataHandler(
                  "confirm_pin",
                  e.target.value,
                  ERROR_MSG.emptyConfirmPin
                );
                setFormData({
                  ...formData,
                  confirm_pin: e.target.value,
                });
              }}
            />
          </div>

          <div>
            <ButtonLoading
              varient="contained"
              clickEvent={() => {
                if (validateForm()) handleSubmit();
              }}
              initialText="Update"
              loadingTruth={childUpdateLoading}
            />
          </div>
        </form>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress size={30} />
          <h3 style={{ marginTop: 20 }}>Loading Form...</h3>
        </div>
      )}
    </>
  );
};

export default ChildAccountEdit;
